import { useContext, useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-feather";
import { getUsageLogs } from "../../../common/apis/billing";
import EventType from "./event-menu";
import WhatsAppChart from "./whatsapp";
import SMSChart from "./sms";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/en_GB";
const { RangePicker } = DatePicker;
import LogsList from "./logs-list/logs-list";
import { importModule } from "../utils/importModule";
import { useNavigate, useParams } from "react-router-dom";
import { TenantContext } from "../../home";
import { setShowCommonLoader } from "../../../redux-loader/loader-slice/loaderSlice";
import { useDispatch } from "react-redux";
import AllEventsChart from "./all-events";
import * as Sentry from "@sentry/react";
import PaginationComp from "../../../../../engagements/src/common/components/pagination";

const EngagementsApp = importModule("@tg/engagements", "EngagementsMain");

const getDefaultStartDate = () => {
  let sDate = new Date();
  sDate.setDate(sDate.getDate() - 7);
  sDate.setHours(0, 0, 0, 0);
  return sDate.getTime().toString();
};

const getDefaultEndDate = () => {
  let sDate = new Date();
  sDate.setHours(0, 0, 0, 0);
  return sDate.getTime().toString();
};

const Logs = (props) => {
  const [logsError, setLogsError] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Logs");
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [templateType, setTemplateType] = useState("");
  const [WabaName, setWabaName] = useState("");
  const [showEventMenu, setShowEventMenu] = useState(false);
  const [eventType, setEventType] = useState("allevents");
  const [usageLogs, setUsageLogs] = useState([]);
  const [hideFilters, setHideFilters] = useState(false);
  const [dateKey, setDateKey] = useState("");
  const [prevDateKey, setPrevDateKey] = useState("");
  const [totalListLength, setTotalListLength] = useState(0);
  const [resetPagination, setResetPagination] = useState(false);

  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    name: "",
    channel: "",
    wabaDetials: { verifiedName: "" },
    audience: { segmentId: "" },
    budget: 0,
    schedule: {
      immediate: false,
      timeline: { start: "", end: "" },
    },
    spend: 0,
    analaytics: {
      delivered: 0,
      read: 0,
      sent: 0,
      undelivered: 0,
      clicks: 0,
    },
    status: "",
    senderName: "",
  });

  let { id } = useParams();
  const navigate = useNavigate();
  const tenantContext: any = useContext(TenantContext);
  const country = tenantContext?.country || "";
  const currency = tenantContext?.currency || "";

  const eventMenu = useRef(null);

  useEffect(() => {
    const params = {
      eventType: eventType === "allevents" ? "" : eventType,
      dateKey,
      startDate,
      endDate,
      templateType,
      WabaName,
    };
    getLogs(params);
    if (eventType === "sms") {
      setHideFilters(true);
    } else {
      setHideFilters(false);
    }
  }, [eventType, WabaName, templateType, startDate, endDate, dateKey]);

  const getLogs = (params = {}) => {
    dispatch(setShowCommonLoader(true));
    try {
      getUsageLogs(params)
        .then((res) => {
          const { error, response = {} } = res;

          if (!error) {
            setUsageLogs(res?.data);
            setTotalListLength(res?.totalCount);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          setLogsError(true);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      dispatch(setShowCommonLoader(false));
    }
  };

  const closeOpenMenus = (e) => {
    if (
      eventMenu.current &&
      showEventMenu &&
      !eventMenu.current.contains(e.target)
    ) {
      setShowEventMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const handleDateRangeChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setStartDate(sDate.getTime().toString());
    setEndDate(eDate.getTime().toString());
  };

  useEffect(() => {
    const params = {
      eventType: eventType === "allevents" ? "" : eventType,
      dateKey,
      startDate,
      endDate,
      templateType,
      WabaName,
    };
    getLogs(params);
  }, [eventType, WabaName, templateType, startDate, endDate, dateKey]);

  return (
    <div>
      <div className="flex justify-between items-center mb-10">
        <div>
          <span className="text-gray-700 text-xl font-semibold py-2">
            Events count
          </span>
          <p className="text-gray-500 py-2">View detailed event consumption</p>
        </div>

        <div className="flex gap-4">
          <div className="relative">
            <button
              className="inline-flex gap-2 items-center px-6 py-3 text-sm font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100"
              onClick={() => setShowEventMenu(true)}
            >
              {eventType === "allevents" && "All events"}
              {eventType === "whatsapp" && "WhatsApp"}
              {eventType === "sms" && "SMS"}
              <ChevronDown className="text-xl mt-0.5" size="14" />
            </button>

            {/* Event Type dropdown menu */}
            {showEventMenu && (
              <EventType eventMenu={eventMenu} setEventType={setEventType} />
            )}
          </div>
          <div className="w-72 mx-auto">
            <RangePicker
              className="outline-none border-none h-10 gap-3 py-3 px-5 rounded-[47px] bg-gray-10 text-gray-600 shadow-none focus:{outline-none border-none bg-gray-10 shadow-none text-gray-600} placeholder:{text-gray-600}"
              onChange={handleDateRangeChange}
              locale={locale}
              showNow={false}
              allowClear={false}
              cellRender={(current, info) => {
                if (info.type !== "date") return info.originNode;
                const style: React.CSSProperties = {};
                style.borderRadius = "50%";
                style.width = "30px";
                style.height = "30px";
                style.lineHeight = "30px";
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
            />
          </div>

          <button
            disabled
            className="inline-flex gap-2 items-center px-6 text-gray-700 font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500 disabled:border-none"
          >
            Download report <ChevronDown className="text-xl" size="16" />
          </button>
        </div>
      </div>
      <div className="mb-10">
        {eventType === "whatsapp" && (
          <WhatsAppChart
            setWabaName={setWabaName}
            WabaName={WabaName}
            setTemplateType={setTemplateType}
            templateType={templateType}
            response={response}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        {eventType === "sms" && (
          <SMSChart
            response={response}
            startDate={startDate}
            endDate={endDate}
          />
        )}

        {/* TODO: Uncomment when all events is to be shown */}
        {eventType === "allevents" && (
          <AllEventsChart
            response={response}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </div>

      <div>
        <span className="text-gray-700 text-xl font-semibold py-2">
          Events logs
        </span>
        <p className="text-gray-500 py-2">
          Event logs help you get a detailed overview of all events by usage.
        </p>

        <div className="flex gap-8 mt-8">
          {["Logs"].map((option, index) => (
            <div
              className={`${
                selectedTab === option
                  ? "text-gray-700 font-semibold border-b-2 border-black hover:text-gray-800 cursor-default"
                  : "font-medium text-gray-500 hover:text-green-75 cursor-pointer"
              }`}
              key={index}
              onClick={() => setSelectedTab(option)}
            >
              <div className="mb-2.5">{option}</div>
            </div>
          ))}
        </div>

        <div className="py-3 mt-4">
          {selectedTab === "Logs" && (
            <>
              {eventType === "sms" ? (
                <EngagementsApp
                  id={id}
                  navigate={navigate}
                  country={country}
                  currency={currency}
                  defaultChannel="sms"
                  hideFilters={hideFilters}
                  dispatch={dispatch}
                  setShowCommonLoader={setShowCommonLoader}
                />
              ) : (
                <div>
                  <LogsList
                    navigate={navigate}
                    logsError={logsError}
                    usageLogs={usageLogs}
                    dateKey={dateKey}
                    setDateKey={setDateKey}
                    prevDateKey={prevDateKey}
                    setPrevDateKey={setPrevDateKey}
                  />
                  <div className="mt-8">
                    <PaginationComp
                      onPageChange={(from, size) => {
                        if (resetPagination) setResetPagination(false);
                        getLogs({ from, size });
                      }}
                      reset={resetPagination}
                      totalCount={totalListLength}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Logs;
