const MessagesStatusMenu = (props) => {
  const {
    showStatusMenu,
    statusMenu,
    setStatus,
    status,
    setPageSize,
    setPageNum,
    setFrom,
    startDate,
    endDate,
  } = props;

  const handleSetStatus = (e: any) => {
    setPageNum(0);
    setPageSize(10);
    setFrom(0);
    setStatus(e.target.value);
    if (status === e.target.value) {
      setStatus("");
    }
  };

  return (
    showStatusMenu && (
      <div
        ref={statusMenu}
        className="absolute mt-1 z-10 bg-white rounded-lg shadow w-52"
      >
        <ul className="p-2 text-sm text-gray-700">
          <li>
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={status === "delivered"}
                id="delivered"
                onChange={handleSetStatus}
                type="checkbox"
                value="delivered"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="delivered"
                className="w-full ml-3 text-[14px] leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Delivered
              </label>
            </div>
          </li>
          <li>
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={status === "read"}
                id="read"
                onChange={handleSetStatus}
                type="checkbox"
                value="read"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="read"
                className="w-full ml-3 text-[14px] leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Read
              </label>
            </div>
          </li>

          <li>
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={status === "undelivered"}
                id="undelivered"
                onChange={handleSetStatus}
                type="checkbox"
                value="undelivered"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="undelivered"
                className="w-full ml-3 text-[14px] leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Undelivered
              </label>
            </div>
          </li>

          <li>
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={status === "sent"}
                id="sent"
                onChange={handleSetStatus}
                type="checkbox"
                value="sent"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="sent"
                className="w-full ml-3 text-[14px] leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Sent
              </label>
            </div>
          </li>

          <li>
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={status === "dnd"}
                id="dnd"
                onChange={handleSetStatus}
                type="checkbox"
                value="dnd"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="dnd"
                className="w-full ml-3 text-[14px] leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                DND
              </label>
            </div>
          </li>
          <li>
            <div className="flex items-center p-3 rounded hover:bg-gray-100">
              <input
                checked={status === "unsent"}
                id="unsent"
                onChange={handleSetStatus}
                type="checkbox"
                value="unsent"
                className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="unsent"
                className="w-full ml-3 text-[14px] leading-6 font-medium text-gray-700 rounded cursor-pointer"
              >
                Unsent
              </label>
            </div>
          </li>
        </ul>
      </div>
    )
  );
};

export default MessagesStatusMenu;
