import {
  EmailArchive,
  EmailBack,
  EmailDelete,
  EmailDots,
  EmailReply,
  MegaphoneSVG,
  Phone,
} from "../.././assets/icons/icons";

const EmailPreview = ({ data }) => {
  return (
    <div className="flex justify-center w-full bg-green-708 relative overflow-hidden h-[600px] rounded-lg border border-gray-100">
      <div>
        <div className="flex justify-center mt-12">
          <div className="h-[105px] w-[105px] bg-green-707 rounded-full flex justify-center items-center p-4">
            <img src={MegaphoneSVG} alt="" />
          </div>
        </div>
        <div className="absolute left-0 right-0 ml-auto mr-auto w-[280px] mt-2 z-20">
          <img src={Phone} alt="" />
          <div className="absolute text-xs top-[45px] p-0 m-0 left-[20px] w-[240px] bg-white">
            <div className="flex justify-between mt-6 px-3">
              <div>
                <img src={EmailBack} alt="" />
              </div>
              <div className="flex gap-4 items-center">
                <img src={EmailArchive} alt="" />{" "}
                <img src={EmailDelete} alt="" /> <img src={EmailDots} alt="" />
              </div>
            </div>
            <div className="mt-6 text-xs font-semibold leading-3 px-3 text-[#292929]">
              {data.subject}
            </div>
            <div className="flex px-3 mt-4 justify-between">
              <div className="flex gap-3 items-center">
                <div className="w-[22px] h-[22px] bg-[#A0887E] rounded-full flex items-center justify-center font-semibold text-white text-sm">
                  {data.businessName.substring(0, 1)}
                </div>
                <div>
                  <div className="flex gap-2">
                    <div
                      style={{
                        fontSize: "8px",
                        color: "#292929",
                        fontWeight: "600",
                      }}
                    >
                      {data.businessName}
                    </div>
                    <div style={{ fontSize: "6px", color: "#5D5C5D" }}>
                      9:40 AM
                    </div>
                  </div>
                  <div style={{ fontSize: "8px", color: "#5D5C5D" }}>to me</div>
                </div>
              </div>
              <div className="flex items-center">
                <img src={EmailReply} alt="" />
              </div>
            </div>
            <div className="relative w-full h-[250px]">
              <div
                className="absolute top-[-100px] left-[-106px] right-0 bottom-0 tranform scale-[0.38]"
                dangerouslySetInnerHTML={{
                  __html: data.content.htmlContent,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="absolute h-[821px] w-[821px] bg-green-707 rounded-full right-[-38%] top-[36%] z-0 opacity-40"></div>
        <div className="absolute h-[731px] w-[731px] bg-green-709 opacity-80 rounded-full top-[50%] right-[-1%] z-10"></div>
      </div>
    </div>
  );
};

export default EmailPreview;
