import { useEffect, useState } from "react";
import { ConfigProvider, DatePicker } from "antd";
const { RangePicker } = DatePicker;
import locale from "antd/es/date-picker/locale/en_GB";
import DownArrow from "../../../common/assets/icons/DownArrow-lite";
import CurrencySymbol from "../../../common/components/currency-symbol/currency-symbol";
import Wallet from "../../../common/assets/icons/Walltet";
import ReactECharts from "echarts-for-react";
import { useDispatch } from "react-redux";
import { setShowCommonLoader } from "../../../redux-loader/loader-slice/loaderSlice";
import { getApiReport } from "../apis/apiusage";
import Dropdown from "../../settingsComponents/Engagements/whatsappTemplateTab/dropdown-component/dropdownComponent";
import RewardReport from "./reward-report";
import MessageReport from "./message-report";
import { MESSAGE } from "../../../common/constants/AccessControlMessage";

const ApiDashboard = ({
  walletBalance,
  showTopupModal,
  country,
  tenantContext,
}) => {
  const [selectedChannel, setSelectedChannel] = useState("messaging");
  const [selectedType, setSelectedType] = useState("Chat");
  const [report, setReport]: any = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [plotData, setPlotData] = useState({
    xAxis: [],
    successCalls: [],
    failedCalls: [],
  });

  const dispatch = useDispatch();

  const selectChannel = (value) => {
    setSelectedChannel(value);
    if (value === "reward") {
      setSelectedType("airtime");
    } else if (value === "messaging") {
      setSelectedType("Chat");
    }
  };

  const selectType = (value) => {
    setSelectedType(value);
  };

  useEffect(() => {
    getApiUsageReport();
  }, [dateRange, selectedChannel, selectedType]);

  const getHeaderItems = () => {
    return selectedChannel === "messaging"
      ? [
          { label: "Total Sent Messages", value: report.sent },
          { label: "Delivered", value: report.delivered },
          { label: "Undelivered", value: report.undelivered },
          { label: "Rejected", value: report.rejected },
          {
            label: "Amount Spent",
            value: <CurrencySymbol value={report.amountSpend} />,
          },
        ]
      : [
          { label: "Total API calls", value: report.totalCalls },
          { label: "Successful API calls", value: report.successCalls },
          { label: "Failed API calls", value: report.failureCalls },
          {
            label: "Amount spent",
            value: <CurrencySymbol value={report.amountSpend} />,
          },
        ];
  };

  const getApiUsageReport = () => {
    let channel = `${
      selectedChannel === "messaging" ? "sms" : "reward"?.toLowerCase()
    }-${selectedType?.toLowerCase()}`;
    setReport({});

    // if (selectedType === "Chat" || selectedType === "OTP") {
    //   channel = `sms-${selectedType?.toLowerCase()}`;
    // } else channel = selectedType?.toLowerCase();

    dispatch(setShowCommonLoader(true));
    try {
      getApiReport(channel, dateRange)
        .then((res) => {
          dispatch(setShowCommonLoader(false));
          const { error, response = {} } = res;
          if (!error || error === "false") {
            setReport(response);
          }
        })
        .catch((err) => {
          setShowCommonLoader(false);
        });
    } catch (err) {
      console.log(err);
      setShowCommonLoader(false);
    }
  };

  useEffect(() => {
    filterReport();
  }, [report]);

  const filterReport = () => {
    let xAxis = [];
    let successCalls = [];
    let failedCalls = [];

    report?.callsTimeline?.forEach((el) => {
      xAxis.push(`${new Date(el.timestamp)}`.substring(3, 10));
      successCalls.push(el.callCountSuccess);
      failedCalls.push(el.callCountFailed);
    });

    setPlotData({
      ...plotData,
      xAxis: xAxis,
      successCalls: successCalls,
      failedCalls: failedCalls,
    });
  };

  let option = {
    title: { show: true },
    color: ["#00A1E1", "#EE46BC"],
    tooltip: { trigger: "axis" },
    grid: {
      height: "80%",
      bottom: "15%",
      width: "90%",
      left: "40px",
      top: "40px",
    },
    legend: {
      top: "top",
      icon: "circle",
      textStyle: { color: "#475569", fontFamily: "BR Firma" },
      data: ["Successful API calls", "Failed API calls"],
      left: 0,
    },
    xAxis: {
      type: "category",
      axisLabel: {
        show: true,
      },
      data: plotData.xAxis,
      axisLine: {
        show: true,
        lineStyle: { type: [1, 5] },
      },

      axisTick: {
        show: false,
      },
    },
    yAxis: {
      name: "API calls",
      nameLocation: "middle",
      nameTextStyle: {
        padding: 15,
        fontFamily: "BR Firma",
        color: "##475467",
        fontSize: "10px",
        fontWeight: "600",
      },
      type: "value",
      left: 0,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        name: "Successful API calls",
        data: plotData.successCalls,
        type: "line",
        showSymbol: false,
      },
      {
        name: "Failed API calls",
        data: plotData.failedCalls,
        type: "line",
        showSymbol: false,
      },
    ],
  };

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);

    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setDateRange({
      startDate: sDate.getTime().toString(),
      endDate: eDate.getTime().toString(),
    });
  };

  const engagementChannelList = [
    { label: "Rewards API", value: "reward" },
    { label: "Messaging API", value: "messaging" },
  ];

  const engagementTypeList = () => {
    return selectedChannel === "messaging"
      ? [
          { label: "Chat", value: "Chat" },
          { label: "OTP", value: "OTP" },
        ]
      : [
          { label: "Airtime", value: "airtime" },
          { label: "Data", value: "data" },
        ];
  };
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <span className="text-xl text-gray-700 font-semibold">
            API usage and transaction data
          </span>
          <div className="text-gray-500 text-sm leading-6 mt-1">
            Track API calls for airtime and data transactions
          </div>
        </div>
        <div className="flex gap-3 mr-8">
          <Dropdown
            options={engagementChannelList}
            value={selectedChannel}
            handleChange={selectChannel}
            placeholder={"select channel"}
            className={`w-[120px] rounded-full px-5 py-3 text-sm font-semibold text-gray-700 bg-gray-10`}
          />

          <Dropdown
            options={engagementTypeList()}
            value={selectedType}
            handleChange={selectType}
            placeholder={"select channel"}
            className={`w-[120px] rounded-full px-5 py-3 text-sm font-semibold text-gray-700 bg-gray-10`}
          />

          <div
            className={`h-[40px]  ${
              dateRange.startDate ? "w-[300px]" : "w-[70px]"
            }
             py-3 px-5 bg-[#F6F9FC] text-[#475467] text-sm leading-4 font-semibold flex rounded-full items-center cursor-pointer relative`}
          >
            {!dateRange.startDate && (
              // <>
              //   <span className="cursor-pointer text-sm leading-4 font-semibold text-gray-70">
              //     Date
              //   </span>
              //   <DownArrow />
              // </>
              <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-3 justify-center pointer-events-none">
                <span className="cursor-pointer text-sm leading-4 font-semibold text-gray-70">
                  Date
                </span>
                <DownArrow />
              </div>
            )}
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    colorIcon: "#98A2B3",
                    colorBorder: "#D0D5DD",
                    colorPrimary: "#008138",
                    colorPrimaryHover: "#008138",
                    colorText: "#344054",
                    colorTextHeading: "#344054",
                    colorTextDisabled: "#D0D5DD",
                    colorTextLightSolid: "var(--main-color)",
                    colorTextPlaceholder: "#98A2B3",
                    controlItemBgActive: "#DAEEE1",
                    controlOutline: "#FFFFFF",
                    colorBgTextActive: "FFFFFF",
                    colorTextLabel: "#FFFFFF",
                  },
                }}
              >
                <RangePicker
                  className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                  onChange={onRangeSelectionChange}
                  format={(value) => {
                    return `${value["$d"].toString().substring(4, 16)}`;
                  }}
                  locale={locale}
                  showNow={false}
                  allowClear={false}
                  cellRender={(current, info) => {
                    if (info.type !== "date") return info.originNode;
                    const style: React.CSSProperties = {};
                    style.borderRadius = "50%";
                    style.width = "30px";
                    style.height = "30px";
                    style.lineHeight = "30px";
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-start mt-10">
        {getHeaderItems().map((el, index) => (
          <div
            key={index}
            className={`${
              index !== 4 && "border-r border-gray-400 "
            } text-center text-xs text-gray-600 px-5 my-5 grow `}
          >
            <div> {el.label}</div>
            <div className="text-xl text-gray-700 font-semibold">
              {el.value}
            </div>
          </div>
        ))}
      </div>
      {/* <div className="w-full mt-5">
        <ReactECharts
          style={{ width: "full", margin: "0px", padding: "0px" }}
          option={option}
        />
      </div> */}
      {/* <div className="flex justify-between mt-16">
        <div>
          <span className="text-xl text-gray-700 font-semibold">
            Operator breakdown
          </span>
          <div className="text-gray-500 text-sm leading-6 mt-1">
            View operator specific data for API calls
          </div>
        </div>
        <div className="flex gap-3">
          <Dropdown
            options={engagementChannelList}
            value={selectedChannel}
            handleChange={selectChannel}
            placeholder={"select channel"}
            className={`w-full rounded-full px-5 py-3 text-sm font-semibold text-gray-700 bg-gray-10`}
          />
          <Dropdown
            options={engagementTypeList}
            value={selectedType}
            handleChange={selectType}
            placeholder={"select channel"}
            className={`w-full rounded-full px-5 py-3 text-sm font-semibold text-gray-700 bg-gray-10`}
          />
        </div>
      </div> 
      <div className="flex justify-start items-start mt-10">
        {["Successful purchase calls", "Failed purchase calls"].map(
          (el, index) => (
            <div
              key={index}
              className={`${
                index !== 3 && "border-r border-gray-400 "
              } text-xs text-gray-600 p-3 text-center`}
            >
              {el}
            </div>
          )
        )}
      </div>*/}
      <div className="mt-9">
        {selectedChannel === "messaging" ? (
          <MessageReport dateRange={dateRange} selectedType={selectedType} />
        ) : (
          <RewardReport
            country={country}
            dateRange={dateRange}
            selectedType={selectedType}
          />
        )}
      </div>
      <div className="mt-12">
        <>
          <span className="text-xl text-gray-700 font-semibold">
            Wallet status
          </span>
          <div className="text-gray-500 text-sm leading-6 mt-1">
            Track and topup your existing wallet balance and view API
            transaction history.
          </div>
        </>
        <div className="py-7 px-6 flex items-start justify-between border border-[#E5EFFF] my-8">
          <div className="flex items-start gap-6">
            <Wallet />
            <div>
              <div className="text-gray-500 rounded text-sm leading-6 pb-1 font-semibold">
                Wallet Balance
              </div>

              <div className="text-3xl font-semibold">
                <CurrencySymbol value={walletBalance} />
              </div>
              {/* <div className="text-gray-700 text-sm font-semibold pt-6 cursor-pointer">
                View transactions
              </div> */}
            </div>
          </div>
          <div className="group">
            <button
              className={`py-4 px-12 text-white font-semibold border rounded-lg bg-blue-75 ${
                tenantContext.productList["wallet_credit_policy"] &&
                "hover:bg-green-75"
              }  cursor-pointer relative`}
              onClick={showTopupModal}
              disabled={!tenantContext.productList["wallet_credit_policy"]}
            >
              Top up
              {!tenantContext.productList["wallet_credit_policy"] && (
                <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[136px] top-[-10px] z-20">
                  {MESSAGE}
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDashboard;
