import { useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import StatusMenu from "../../common/components/status-menu";
import AudienceMenu from "../../common/components/audience-menu";
import PaginationCommon from "../../common/components/pagination-common";
import ThreeDotsMenu from "../../common/components/three-dots-menu";
import { colorCode } from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import ChannelMenuSurvey from "../../common/components/channel-menu-survey";
import { Search } from "react-feather";
import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/en_GB";
import {
  addGAEventListener,
  getPageName,
} from "../../../../marketing-cloud-container/src/ga-events";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { approveCampaign } from "../../common/services/message-services";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import {
  DownArrow,
  EditCampaign,
  Pause,
  Resume,
  SurveyListIcon,
} from "../../assets/icons/icons";

const { RangePicker } = DatePicker;

const SurveyList = (props: any) => {
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [showChannelMenu, setShowChannelMenu] = useState(false);
  const [showAudienceMenu, setShowAudienceMenu] = useState(false);
  const [showDateMenu, setShowDateMenu] = useState(false);
  const [menuKey, setMenuKey] = useState(null);
  const [showSideMenu, setShowSideMenu] = useState(false);

  const statusMenu = useRef(null);
  const audienceMenu = useRef(null);
  const channelMenu = useRef(null);
  const sideMenu = useRef(null);
  const datepicker = useRef(null);
  const {
    surveyList = [],
    totalSurveyListLength,
    navigateToSurvey,
    navigate,
    setFilter,
    filter,
    setSearchSurveys,
    searchSurveys,
    setPageNumber,
    pageNumber,
    size,
    setSize,
    setFrom,
    channel,
    setChannel,
    audience,
    setAudience,
    status,
    setStatus,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    hasSuperAdminAccess = false,
    tenantContext,
  } = props;

  const [surveys, setSurveys] = useState(surveyList);

  useEffect(() => {
    addGAEventListener();
  }, []);

  useEffect(() => {
    setSurveys(surveyList);
  }, [surveyList]);

  const closeOpenMenus = (e) => {
    if (
      channelMenu.current &&
      showChannelMenu &&
      !channelMenu.current.contains(e.target)
    ) {
      setShowChannelMenu(false);
    }

    if (
      statusMenu.current &&
      showStatusMenu &&
      !statusMenu.current.contains(e.target)
    ) {
      setShowStatusMenu(false);
    }

    if (
      audienceMenu.current &&
      showAudienceMenu &&
      !audienceMenu.current.contains(e.target)
    ) {
      setShowAudienceMenu(false);
    }

    if (
      datepicker.current &&
      showDateMenu &&
      !datepicker.current.contains(e.target)
    ) {
      setShowDateMenu(false);
    }

    if (
      sideMenu.current &&
      showSideMenu &&
      !sideMenu.current.contains(e.target)
    ) {
      setShowSideMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const dateTimeFormatter = (timestamp) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const navigateToDetail = (item) => {
    let id = item?.id;
    id = id.replaceAll("/", "~");
    navigate(`/engagements/surveys/${id}`);
  };

  const modifyCampaignStatus = (
    approvalStatus: String,
    campaignType = "SURVEY",
    campaignId: String,
    index: number
  ) => {
    const payload = {
      approvalStatus,
      campaignType,
      campaignId,
    };
    try {
      setShowSideMenu(!showSideMenu);

      approveCampaign({ payload })
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            const updatedSurveyList = surveys.map((survey, idx) => {
              if (idx === index) {
                return {
                  ...survey,
                  status: approvalStatus,
                };
              } else {
                return survey;
              }
            });
            setSurveys(updatedSurveyList);

            Notify({
              type: "success",
              heading: `Campaign ${approvalStatus}`,
              message: response,
            });
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const handleClick = (item, status: string) => {
    if (hasSuperAdminAccess) {
      navigateToDetail(item);
      return;
    }

    if (
      [
        "active",
        "running",
        "not started",
        "paused",
        "completed",
        "pending approval",
      ].includes(status.toLowerCase())
    ) {
      navigateToDetail(item);
    }
    if (["draft", "rejected"].includes(status.toLowerCase())) {
      navigate(`/engagements/create-survey/${item?.id}`);
    }
  };

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setStartDate(sDate.getTime().toString());
    setEndDate(eDate.getTime().toString());
  };

  return (
    <div className="my-11 mx-10">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <div className="relative">
            <button
              className="h-[40px] gap-2 inline-flex items-center px-4 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 triggerGA"
              data-event-category={`${getPageName()}-list-page`}
              data-event-label={`${getPageName()}-list_channel-filter_click`}
              data-event-action="filter_click"
              type="button"
              onClick={() => {
                setShowChannelMenu(!showChannelMenu);
              }}
            >
              Channel
              <img src={DownArrow} alt="" />
            </button>

            <ChannelMenuSurvey
              showChannelMenu={showChannelMenu}
              channelMenu={channelMenu}
              setChannel={setChannel}
              channel={channel}
              setFilter={setFilter}
              setPageNum={setPageNumber}
              setPageSize={setSize}
              setFrom={setFrom}
              audience={audience}
              status={status}
            />
          </div>
          <div className="relative">
            <button
              className="h-[40px] gap-2 inline-flex items-center px-4 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 triggerGA"
              data-event-category={`${getPageName()}-list-page`}
              data-event-label={`${getPageName()}-list_audience-filter_click`}
              data-event-action="filter_click"
              type="button"
              onClick={() => {
                setShowAudienceMenu(!showAudienceMenu);
              }}
            >
              Audience
              <img src={DownArrow} alt="" />
            </button>
            <AudienceMenu
              showAudienceMenu={showAudienceMenu}
              audienceMenu={audienceMenu}
              setAudience={setAudience}
              audience={audience}
              channel={channel}
              status={status}
              setFilter={setFilter}
              setPageNum={setPageNumber}
              setPageSize={setSize}
              setFrom={setFrom}
            />
          </div>
          <div className="relative">
            <button
              className="h-[40px] gap-2 inline-flex items-center px-4 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 triggerGA"
              data-event-category={`${getPageName()}-list-page`}
              data-event-label={`${getPageName()}-list_status-filter_click`}
              data-event-action="filter_click"
              type="button"
              onClick={() => {
                setShowStatusMenu(!showStatusMenu);
              }}
            >
              Status <img src={DownArrow} alt="" />
            </button>
            <StatusMenu
              showStatusMenu={showStatusMenu}
              statusMenu={statusMenu}
              setStatus={setStatus}
              status={status}
              channel={channel}
              audience={audience}
              setFilter={setFilter}
              setPageNum={setPageNumber}
              setPageSize={setSize}
              setFrom={setFrom}
            />
          </div>

          <div
            className="h-[40px] w-[100px] py-3 px-5 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative triggerGA"
            data-event-category={`${getPageName()}-list-page`}
            data-event-label={`${getPageName()}-list_date-filter_click`}
            data-event-action="filter_click"
          >
            <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-3 justify-center pointer-events-none">
              <span className="cursor-pointer">Date</span>
              <img src={DownArrow} alt="" />
            </div>
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    colorIcon: "#98A2B3",
                    colorBorder: "#D0D5DD",
                    colorPrimary: "#008138",
                    colorPrimaryHover: "#008138",
                    colorText: "#344054",
                    colorTextHeading: "#344054",
                    colorTextDisabled: "#D0D5DD",
                    colorTextLightSolid: "var(--main-color)",
                    colorTextPlaceholder: "#98A2B3",
                    controlItemBgActive: "#DAEEE1",
                    controlOutline: "#FFFFFF",
                    colorBgTextActive: "FFFFFF",
                    colorTextLabel: "#FFFFFF",
                  },
                }}
              >
                <RangePicker
                  className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                  onChange={onRangeSelectionChange}
                  locale={locale}
                  showNow={false}
                  allowClear={false}
                  cellRender={(current, info) => {
                    if (info.type !== "date") return info.originNode;
                    const style: React.CSSProperties = {};
                    style.borderRadius = "50%";
                    style.width = "30px";
                    style.height = "30px";
                    style.lineHeight = "30px";
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex border border-gray-300 rounded-lg ">
            <div className="w-10 h-10 text-gray-400">
              <Search className="text-xl m-3" size="16" />
            </div>
            <input
              type="text"
              value={searchSurveys}
              placeholder="Search Surveys"
              className="w-full h-10 pt-1 pr-5 mr-2 focus:border-none font-normal focus:outline-none "
              onChange={(e: any) => {
                setSearchSurveys(e.target.value);
                setFilter(true);
                // TODO: To setFilter(false) when input box is empty and true when it's not empty
              }}
            />
          </div>
          <div>
            <div className="group">
              <button
                className={`px-[20px] py-[12px] bg-blue-75 text-white text-[14px] font-semibold leading-4 rounded-lg cursor-pointer ${
                  tenantContext.productList["create_survey_policy"] &&
                  "hover:bg-green-75"
                } relative`}
                onClick={navigateToSurvey}
                disabled={!tenantContext.productList["create_survey_policy"]}
              >
                Create survey
                {!tenantContext.productList["create_survey_policy"] && (
                  <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[146px] top-[-12px] z-20">
                    {MESSAGE}
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-11 relative">
        <table className="min-w-full">
          <thead>
            <tr>
              {[
                "Name",
                "Audience",
                "Responses",
                "Date Created",
                "Status",
                "",
              ].map((item, index) => (
                <th
                  key={index}
                  className={
                    " py-3 border-b border-gray-100 text-left -text text-gray-700"
                  }
                >
                  <div
                    className={` ${index === 0 ? "w-44" : ""} ${
                      index === 1 ? "w-28" : ""
                    }`}
                  >
                    {item}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          {(filter || pageNumber > 0) && surveys?.length === 0 ? (
            <div className="w-full p-24 absolute">
              <div className="flex flex-col w-full bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                <div className="mt-2">No Data to Show.</div>
              </div>
            </div>
          ) : (
            <tbody>
              {surveys?.map((survey, index) => {
                const item = survey._source || survey;
                const showMenu = menuKey === index;

                return (
                  <tr
                    key={item.id}
                    className="border-b border-gray-100 text-gray-70 cursor-pointer"
                  >
                    <td
                      className="py-6 text-base leading-6 cursor-pointer"
                      onClick={() => handleClick(item, item.status)}
                    >
                      <div className="flex items-center gap-2">
                        <img src={SurveyListIcon} alt="" />
                        <div className="flex justify-center items-center pl-2">
                          {item.name}
                        </div>
                      </div>
                    </td>

                    <td
                      className="first-letter:capitalize text-base leading-6 cursor-pointer"
                      onClick={() => handleClick(item, item.status)}
                    >
                      {item.audienceType}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() => handleClick(item, item.status)}
                    >
                      {item.surveyType}
                    </td>
                    <td
                      className="text-base leading-6 cursor-pointer"
                      onClick={() => handleClick(item, item.status)}
                    >
                      {dateTimeFormatter(item.updatedAt)}
                    </td>
                    <td
                      className="text-base leading-6 cursor-pointer"
                      onClick={() => handleClick(item, item.status)}
                    >
                      <button
                        className={`first-letter:capitalize ${colorCode(
                          item.status
                        )} font-medium text-[12px] leading-5 py-0.5 px-2 rounded-2xl`}
                      >
                        <div>
                          {item.status.toLowerCase() === "running"
                            ? "active"
                            : item.status.toLowerCase()}
                        </div>
                      </button>
                    </td>
                    {((!hasSuperAdminAccess &&
                      ["draft", "rejected"].includes(
                        item.status.toLowerCase()
                      )) ||
                      hasSuperAdminAccess) && (
                      <td
                        className="relative py-6"
                        onClick={() => {
                          setMenuKey(index);
                          setShowSideMenu(!showSideMenu);
                        }}
                      >
                        <button className="flex justify-end items-center pr-6">
                          <ThreeDotsMenu />
                        </button>
                        {showMenu && showSideMenu && (
                          <div
                            className="absolute z-10 top-16 right-0 bg-white rounded-lg shadow min-w-fit"
                            ref={sideMenu}
                          >
                            <ul className="p-0.5 text-gray-700">
                              {["draft", "rejected"].includes(
                                item.status?.toLowerCase()
                              ) && (
                                <li>
                                  <div className="group">
                                    <button
                                      className={`flex gap-2.5 items-center p-3 rounded ${
                                        tenantContext.productList[
                                          "edit_survey_policy"
                                        ] && "hover:bg-gray-100"
                                      } relative`}
                                      onClick={() => {
                                        navigate(
                                          `/engagements/create-survey/${survey.id}`
                                        );
                                      }}
                                      disabled={
                                        !tenantContext.productList[
                                          "edit_survey_policy"
                                        ]
                                      }
                                    >
                                      <img
                                        className="-mt-1"
                                        src={EditCampaign}
                                        alt=""
                                      />
                                      <div className="w-full font-medium rounded cursor-pointer">
                                        Edit
                                      </div>
                                      {!tenantContext.productList[
                                        "edit_survey_policy"
                                      ] && (
                                        <div className="absolute hidden group-hover:flex bg-black p-3 text-xs text-white rounded-lg w-[300px] right-[76px] top-[-12px] z-20">
                                          {MESSAGE}
                                        </div>
                                      )}
                                    </button>
                                  </div>
                                </li>
                              )}

                              {hasSuperAdminAccess &&
                                [
                                  "active",
                                  "running",
                                  "resume",
                                  "resumed",
                                ].includes(item.status?.toLowerCase()) && (
                                  <li>
                                    <div
                                      className="flex gap-2.5 items-center p-3 rounded hover:bg-gray-100"
                                      onClick={() =>
                                        modifyCampaignStatus(
                                          "Paused",
                                          item.campaignType,
                                          item.id,
                                          index
                                        )
                                      }
                                    >
                                      <div>
                                        <img src={Pause} alt="" />
                                      </div>
                                      <div className="w-full font-medium rounded cursor-pointer">
                                        Pause
                                      </div>
                                    </div>
                                  </li>
                                )}

                              {hasSuperAdminAccess &&
                                ["pause", "paused"].includes(
                                  item.status?.toLowerCase()
                                ) && (
                                  <li>
                                    <div
                                      className="flex gap-2.5 items-center p-3 rounded hover:bg-gray-100"
                                      onClick={() =>
                                        modifyCampaignStatus(
                                          "Resumed",
                                          item.campaignType,
                                          item.id,
                                          index
                                        )
                                      }
                                    >
                                      <div>
                                        <img src={Resume} alt="" />
                                      </div>
                                      <div className="w-full font-medium rounded cursor-pointer">
                                        Resume
                                      </div>
                                    </div>
                                  </li>
                                )}
                            </ul>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      {pageNumber === 0 && surveyList?.length === 0 ? null : (
        <div className="pt-6 flex gap-5 text-sm items-center">
          <PaginationCommon
            totalListLength={totalSurveyListLength}
            setPageNum={setPageNumber}
            pageNum={pageNumber}
            setPageSize={setSize}
            setFrom={setFrom}
            pageSize={size}
            filter={filter}
            ListLength={surveyList?.length}
          />
        </div>
      )}
    </div>
  );
};

export default SurveyList;
