import { useEffect, useRef, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { Search } from "react-feather";
import { ConfigProvider, DatePicker } from "antd";
const { RangePicker } = DatePicker;
import locale from "antd/es/date-picker/locale/en_GB";
import { getNameInitials } from "../../utils/UserInterfaceUtils";
import { getPageName } from "../../../../../marketing-cloud-container/src/ga-events";
import { DownArrow } from "../../assets/icons/icons";
import AuditLogDetailsModal from "../../../pages/business/audit-log-details-modal";
import PaginationComp from "../../../../../engagements/src/common/components/pagination";

const AuditLogsList = (props: any) => {
  const {
    logsList = [],
    roles = [],
    auditEvents = [],
    setAuditLogsStartDate,
    setAuditLogsEndDate,
    setAuditLogsEventName,
    setAuditLogsRole,
    setAuditLogsFrom,
    setAuditLogsSize,
    totalAuditLogs,
  } = props;

  const [showLogDetailsModal, setShowLogDetailsModal] = useState(false);
  const [selectedLogData, setSelectedLogData] = useState(false);
  const [events, setEvents] = useState(auditEvents);
  const [showEventMenu, setShowEventMenu] = useState(false);
  const [eventName, setEventName] = useState("");
  const [role, setRole] = useState("");
  const [showRoleMenu, setShowRoleMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rangeValue, setRangeValue] = useState(null);
  const [isDateRangeActive, setIsDateRangeActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isRoleActive, setIsRoleActive] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const roleMenu = useRef(null);
  const eventMenu = useRef(null);
  const datepicker = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      roleMenu.current &&
      showRoleMenu &&
      !roleMenu.current.contains(e.target)
    ) {
      setShowRoleMenu(false);
    }
    if (
      eventMenu.current &&
      showEventMenu &&
      !eventMenu.current.contains(e.target)
    ) {
      setShowEventMenu(false);
    }
    if (
      datepicker.current &&
      visible &&
      !datepicker.current.contains(e.target)
    ) {
      setVisible(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const onRangeSelectionChange = (dates) => {
    if (!dates) {
      setRangeValue(null);
      return;
    }
    let sDate = new Date(dates[0].$d);
    let eDate = new Date(dates[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setRangeValue(dates);
    setAuditLogsStartDate(sDate.getTime().toString());
    setAuditLogsEndDate(eDate.getTime().toString());
    setIsDateRangeActive(true);
  };

  const resetDateRange = () => {
    setIsDateRangeActive(false);
    setRangeValue(null);
    setAuditLogsFrom(0);
    setAuditLogsStartDate("");
    setAuditLogsEndDate("");
    setResetPagination(true);
  };

  const resetSearch = () => {
    setIsSearchActive(false);
    setEventName("");
    setAuditLogsEventName("");
    setAuditLogsFrom(0);
    setResetPagination(true);
  };

  const resetRole = () => {
    setIsRoleActive(false);
    setRole("");
    setAuditLogsRole("");
    setAuditLogsFrom(0);
    setResetPagination(true);
  };

  const handleEventNameChange = (event) => {
    const { value } = event.target;
    if (value === "") {
      setAuditLogsEventName("");
      setIsSearchActive(false);
    }
    const searchTerm = value.toLowerCase();
    setEventName(value);
    setEvents(
      auditEvents.filter((eventName) =>
        eventName.toLowerCase().includes(searchTerm)
      )
    );
    setAuditLogsFrom(0);
    setResetPagination(true);
  };

  const handleEventNameSelection = (event) => {
    setIsSearchActive(true);
    setAuditLogsFrom(0);
    setResetPagination(true);
    setAuditLogsEventName(event?.replace(/ /g, "+"));
    setEventName(event);
    setShowEventMenu(false);
  };

  const handleSetRole = (role) => {
    setIsRoleActive(true);
    setRole(role?.name?.replace(/_/g, " "));
    setAuditLogsRole(role?.name);
    setShowRoleMenu(false);
  };

  const handleClick = (logData: any) => {
    setSelectedLogData(logData);
    setShowLogDetailsModal(true);
  };

  return (
    <div className="flex align-start flex-col gap-6 ">
      <div className="pt-2 flex justify-between items-center">
        <div className="relative">
          <div className="flex gap-0.5 items-center">
            <div className="flex items-center border border-gray-300 rounded-lg">
              <Search className="text-xl m-3 text-gray-400" size="18" />
              <input
                type="text"
                className="w-full h-10 pt-0.5 mr-3 focus:border-none font-normal focus:outline-none triggerGA"
                data-event-category={`${getPageName()}-list-page`}
                data-event-label={`${getPageName()}-list_search-filter_click`}
                data-event-action="search-input_click"
                value={eventName}
                onClick={() => setShowEventMenu(!showEventMenu)}
                onChange={handleEventNameChange}
                placeholder="Search by event name"
              />
            </div>

            {isSearchActive && (
              <div
                className="font-black text-gray-600 cursor-pointer"
                onClick={resetSearch}
              >
                <IoMdCloseCircle size={16} />
              </div>
            )}
          </div>
          {showEventMenu && (
            <div
              ref={eventMenu}
              className="absolute mt-1 z-10 bg-white rounded-lg shadow w-56"
            >
              <ul className="p-2 text-sm text-gray-700">
                {events.map((event, index) => (
                  <li
                    key={index}
                    className="triggerGA"
                    data-event-category={`${getPageName()}-list-page`}
                    data-event-label={`${getPageName()}-list_role-option_click`}
                    data-event-action="filter_click"
                    data-event-value={event}
                  >
                    <div className="flex items-center px-0.5 py-2 rounded hover:bg-gray-100">
                      <div
                        onClick={() => handleEventNameSelection(event)}
                        className="w-full ml-3 text-sm leading-6 font-medium text-gray-700 rounded capitalize cursor-pointer"
                      >
                        {event}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="flex gap-4 items-center">
          <div className="relative">
            <div className="flex gap-0.5 items-center">
              <button
                className={`h-[40px] gap-2 inline-flex items-center px-4 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 ${
                  isRoleActive ? "bg-green-709" : "bg-[#F6F9FC]"
                } rounded-full capitalize hover:bg-gray-100 triggerGA`}
                data-event-category={`${getPageName()}-list-page`}
                data-event-label={`${getPageName()}-list_role-filter_click`}
                data-event-action="filter_click"
                type="button"
                onClick={() => setShowRoleMenu(!showRoleMenu)}
              >
                {role || "Role"} <img src={DownArrow} alt="" />
              </button>

              {isRoleActive && (
                <div
                  className="font-black text-gray-600 cursor-pointer"
                  onClick={resetRole}
                >
                  <IoMdCloseCircle size={16} />
                </div>
              )}
            </div>

            {showRoleMenu && (
              <div
                ref={roleMenu}
                className="absolute mt-1 z-10 bg-white rounded-lg shadow w-48"
              >
                <ul className="p-2 text-sm text-gray-700">
                  {roles.map((role, index) => (
                    <li
                      key={index}
                      className="triggerGA"
                      data-event-category={`${getPageName()}-list-page`}
                      data-event-label={`${getPageName()}-list_role-option_click`}
                      data-event-action="filter_click"
                      data-event-value={role?.name}
                    >
                      <div className="flex items-center px-0.5 py-2 rounded hover:bg-gray-100">
                        <div
                          onClick={() => handleSetRole(role)}
                          className="w-full ml-3 text-sm leading-6 font-medium text-gray-700 rounded capitalize cursor-pointer"
                        >
                          {role?.name?.replace(/_/g, " ")}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="flex gap-0.5 items-center">
            <div
              className="h-[40px] w-[100px] py-3 px-5 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative triggerGA"
              data-event-category={`${getPageName()}-list-page`}
              data-event-label={`${getPageName()}-list_date-filter_click`}
              data-event-action="filter_click"
            >
              <div
                className={`absolute left-0 top-0 h-[40px] w-[100px] z-10 ${
                  isDateRangeActive ? "bg-green-709" : "bg-[#F6F9FC]"
                } rounded-full flex items-center cursor-pointer gap-3 justify-center pointer-events-none`}
              >
                <span className="cursor-pointer">Date</span>
                <img src={DownArrow} alt="" />
              </div>
              <div className="flex gap-0.5 items-center">
                <ConfigProvider
                  theme={{
                    token: {
                      colorIcon: "#98A2B3",
                      colorBorder: "#D0D5DD",
                      colorPrimary: "#008138",
                      colorPrimaryHover: "#008138",
                      colorText: "#344054",
                      colorTextHeading: "#344054",
                      colorTextDisabled: "#D0D5DD",
                      colorTextLightSolid: "var(--main-color)",
                      colorTextPlaceholder: "#98A2B3",
                      controlItemBgActive: "#DAEEE1",
                      controlOutline: "#FFFFFF",
                      colorBgTextActive: "FFFFFF",
                      colorTextLabel: "#FFFFFF",
                    },
                  }}
                >
                  <RangePicker
                    className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                    onChange={onRangeSelectionChange}
                    locale={locale}
                    showNow={false}
                    allowClear={true}
                    value={rangeValue}
                    cellRender={(current, info) => {
                      if (info.type !== "date") return info.originNode;
                      const style: React.CSSProperties = {};
                      style.borderRadius = "50%";
                      style.width = "30px";
                      style.height = "30px";
                      style.lineHeight = "30px";
                      return (
                        <div className="ant-picker-cell-inner" style={style}>
                          {current.date()}
                        </div>
                      );
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>
            {isDateRangeActive && (
              <div
                className="font-black text-gray-600 cursor-pointer"
                onClick={resetDateRange}
              >
                <IoMdCloseCircle size={16} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative">
        <table className="min-w-full">
          <thead>
            <tr>
              {["Name", "Role", "Event", "Details", "Timestamp"].map(
                (item, index) => (
                  <th
                    key={index}
                    className={
                      " py-3 border-b border-gray-100 text-left -text text-gray-700"
                    }
                  >
                    <div
                      className={` ${index === 0 ? "w-44" : ""} ${
                        index === 1 ? "w-28" : ""
                      }`}
                    >
                      {item}
                    </div>
                  </th>
                )
              )}
            </tr>
          </thead>

          {
            // (filter || pageNumber > 0) &&
            logsList?.length === 0 ? (
              <div className="w-full p-24 absolute">
                <div className="flex w-full flex-col bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                  <div className="mt-2">No data available.</div>
                </div>
              </div>
            ) : (
              <tbody>
                {logsList?.map((item, index) => {
                  const actorDetails = JSON.parse(item?.actorDetails);
                  const role = item?.role
                    .replace(/_/g, " ")
                    .replace(/-/g, " ")
                    .replace(/"/g, "")
                    .replace(/\[|\]/g, "")
                    .replace(/,/g, ", ");

                  return (
                    <tr
                      key={index}
                      className="border-b border-gray-100 text-gray-70 cursor-pointer"
                      onClick={() => handleClick(item)}
                    >
                      <td className="py-6 text-base leading-6 capitalize">
                        <div className="flex gap-2 items-center">
                          <div
                            className={`flex justify-center items-center text-base font-semibold uppercase flex-shrink-0 rounded-full w-10 h-10 ${
                              // Math.random() < 0.5
                              false
                                ? "text-[#410081] bg-[#F5F6FE]"
                                : "text-[#810000] bg-[#FEF5F5]"
                            }
                `}
                          >
                            {getNameInitials(actorDetails?.name)}
                          </div>

                          <div className="flex justify-center items-center pl-2">
                            {actorDetails?.name}
                          </div>
                        </div>
                      </td>

                      <td className="text-base leading-6  capitalize">
                        {role}
                      </td>
                      <td className="text-base leading-6  first-letter:capitalize">
                        {item?.operationType?.toLowerCase() +
                          " " +
                          item?.resourceType?.toLowerCase()}
                      </td>
                      <td className="text-base leading-6  first-letter:capitalize">
                        {item?.actionDescription}
                      </td>

                      <td className="text-base leading-6">
                        {new Date(item?.time).toLocaleDateString("en-GB")}
                        {",  "}
                        {new Date(item?.time).toLocaleTimeString("en-US")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )
          }
        </table>
      </div>

      <div className="mt-8">
        <PaginationComp
          onPageChange={(from, size) => {
            if (resetPagination) setResetPagination(false);
            setAuditLogsFrom(from);
            setAuditLogsSize(size);
          }}
          reset={resetPagination}
          totalCount={totalAuditLogs}
        />
      </div>

      {showLogDetailsModal && (
        <AuditLogDetailsModal
          closeModal={() => setShowLogDetailsModal(false)}
          logData={selectedLogData}
        />
      )}
    </div>
  );
};

export default AuditLogsList;
