import { useEffect, useRef, useState } from "react";
import {
  getAccountStat,
  getSubscriptionPlans,
  getTopupPlans,
} from "../../../../../marketing-cloud-main/src/common/apis/billing";
import CurrencySymbol from "../../../../../marketing-cloud-main/src/common/components/currency-symbol/currency-symbol";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import {
  getAudienceById,
  getSegments,
  postAudienceFromCampaign,
  uploadSegmentCSV,
} from "../../../../../marketing-cloud-main/src/common/apis/audience";
import { isMobileNumberDuplicate } from "../../../common/utils/mobileNumberValidation";
import { SURVEY_URL } from "../../../../../marketing-cloud-main/src/common/constants/baseUrl";
import { formatNumbers } from "../../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import { X } from "react-feather";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { SubscriptionRedirectModal } from "../../../common/components/subscription-redirect-modal/Subscription-redirect-modal";
import * as Sentry from "@sentry/react";
import AudienceApp from "../../../../../audience/src/pages/audience/index";
import {
  ArrowRight,
  Chevron,
  DropDownArrow,
  People,
  SelectedFile,
  File,
  Close,
  Peopleshadow,
  FileUploadAudience,
  ChartAudience,
} from "../../../assets/icons/icons";

const targetText = {
  general: "Based on your budget, this is your potential target audience.",
  manual:
    "Based on the numbers inputted, this is your potential target audience.",
  segment:
    "Based on your segment selection, this is your potential target audience.",
};

const SurveyStepThree = (props: any) => {
  const [audienceType, setAudienceType] = useState("general");
  const [manualType, setManualType] = useState("general");
  const [isConsentReceived, setIsConsentReceived] = useState(false);
  const [mobileNumbersList, setMobileNUmbersList] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [controlNumberList, setControlNumberList] = useState([]);
  const [controlNumber, setControlNumber] = useState("");
  const [controlNumberValidation, setControlNumberValidation] = useState({
    error: false,
    message: "",
  });
  //const [fileName, setFileName] = useState("");
  const [profiles, setProfiles] = useState(props?.data?.profiles || 0);
  const [budget, setBudget] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dataStatus, setDataStatus] = useState(0);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [fileValidation, setFileValidation] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [numberListError, setNumberListError] = useState("");
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [showSegmentSelector, setShowSegmentSelector] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [segmentId, setSegmentId] = useState();
  const [unitPrice, setUnitPrice] = useState(0);
  const [smsPrice, setSmsPrice] = useState(0);
  const [senderIDs, setSenderIDs] = useState([]);
  const [senderID, setSenderID] = useState("");
  const [showSenderIDMenu, setShowSenderIDMenu] = useState(false);
  const [senderIdError, setSenderIdError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [budgetError, setBudgetError] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [showTooltip, setShowTooltip] = useState(null);
  const [targetProfiles, setTargetProfiles] = useState(0);
  const [rewardPerProfile, setRewardPerProfile] = useState(0);
  const [rewardBudget, setRewardBudget] = useState(0);
  const [lastUpdatedSegmentListLength, setLastUpdatedSegmentListLength] =
    useState(-1);
  const segmentListInnerRef = useRef(null);
  const [manualTypeError, setManualTypeError] = useState(false);
  const [showManualOptions, setShowManualOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const manualOptions = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploadSpinnerStatus, setFileUploadSpinnerStatus] = useState(false);
  const [tierId, setTierId] = useState(null);
  const [subscriptionRedirectModalStatus, setSubscriptionRedirectModalStatus] =
    useState(false);

  const [customAudiencePayload, setCustomAudiencePayload] = useState({});
  const [formData, setFormData] = useState({ multipleSubmissions: true });
  const [submit, setSubmit] = useState(false);
  const [audienceSource, setAudienceSource] = useState("");
  const [fileSizeValidator, setFileSizeValidator] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [audienceDescription, setAudienceDescription] = useState(
    "Use a survey link that can be easily shared with individuals within your network, allowing you to conveniently collect and analyze their responses."
  );
  const [showAudienceDescription, setShowAudienceDescription] = useState(true);
  const [audienceImg, setAudienceImg] = useState(Peopleshadow);

  const handleChangeFormData = (data, type) => {
    setFormData({ ...formData, [type]: data });
  };

  useEffect(() => {
    getTopupPlanss();
    getAccountStat()
      .then((response) => {
        let tierId = JSON.parse(response?.subscriptions[0])[0]?.tier_id;
        setTierId(tierId);
        getSubscribedPlans(tierId);
      })
      .catch((error) => {
        setTierId(0);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getSegmentList(0, 20);
  }, [audienceSource]);

  useEffect(() => {
    reInitializeForm();
  }, [props.data]);

  const getTopupPlanss = () => {
    try {
      getTopupPlans()
        .then((response) => {
          if (!response.error) {
            let surveyPlan =
              response[
                response.findIndex((ele) => {
                  return ele.product_id === 1;
                })
              ];
            if (surveyPlan) {
              setUnitPrice(surveyPlan.billing_scheme.tiers[0].price);
            }
            let smsPlan =
              response[
                response.findIndex((item) => {
                  return item.product_id === 8;
                })
              ];
            if (smsPlan) {
              setSmsPrice(smsPlan?.billing_scheme?.tiers[0]?.price);
            }
          } else {
            Sentry.captureMessage("Error", response);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const getSubscribedPlans = (tierId) => {
    try {
      getSubscriptionPlans()
        .then((response) => {
          let tier =
            response[0].tiers[
              response[0].tiers.findIndex((x) => {
                return x.tier_no === tierId;
              })
            ];
          setSubscriptionPlan(tier.tier_name);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const reInitializeForm = () => {
    if (props?.data?.budget) setBudget(props.data.budget);
    if (props?.data?.file) {
      //setFileName(props.data.fileName);
      setSelectedFile(props.data.file);
      setSegmentId(props.data.audience.segmentId);
      setFileUploadStatus(true);
      setAudienceType("manual");
    }
    if (props?.data?.profiles) setProfiles(props.data.profiles);
    if (props?.data?.invitationMessageDetails?.senderID)
      setSenderID(props.data.invitationMessageDetails.senderID);
    if (props?.data?.invitationMessageDetails?.content?.text)
      setMessage(props.data.invitationMessageDetails.content.text);
    if (props?.data?.message) setMessage(props.data.message);
    if (props?.data?.audience?.phoneNumbers?.length > 0) {
      setMobileNUmbersList(props.data.audience.phoneNumbers);
    }
    if (
      props?.data?.audience?.segmentId
      // && !props?.data?.fileName
    ) {
      getAudienceDetails(props.data.audience.segmentId);
    }
    if (props?.data?.manualType) {
      setManualType(props.data.manualType);
    }

    if (props?.data?.audienceQuery)
      setCustomAudiencePayload(props?.data?.audienceQuery);

    if (props?.data?.multipleSubmissions) {
      handleChangeFormData(
        props?.data?.multipleSubmissions,
        "multipleSubmissions"
      );
    }

    if (props?.data?.typeOfAudience || props?.data?.audienceType) {
      setAudienceType(props?.data?.typeOfAudience || props.data.audienceType);
    }
    if (props?.data?.controlNumbers)
      setControlNumberList(props.data.controlNumbers);
    if (props?.data?.audience?.segmentId) {
      setSegmentForId(props.data.audience.segmentId);
    }

    // if (props.data?.segment) {
    //   setSelectedSegment(props.data.segment);
    // }
    if (props.data?.senderID) {
      setSenderID(props.data.senderID);
    }
    if (props.data?.isConsentReceived) {
      setIsConsentReceived(props.data.isConsentReceived);
    }
    if (props.data?.targetProfiles) {
      setTargetProfiles(props.data.targetProfiles);
    }
    if (props.data?.rewardBudget) {
      setRewardBudget(Number(props.data.rewardBudget));
    }
    if (props.data?.rewardPerProfile) {
      setRewardPerProfile(Number(props.data.rewardPerProfile));
    }
  };

  const senderIDMenu = useRef(null);
  const tooltip = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      segmentListInnerRef.current &&
      showSegmentSelector &&
      !segmentListInnerRef.current.contains(e.target)
    ) {
      setShowSegmentSelector(false);
    }
    if (
      senderIDMenu.current &&
      showSenderIDMenu &&
      !senderIDMenu.current.contains(e.target)
    ) {
      setShowSenderIDMenu(false);
    }

    if (
      manualOptions.current &&
      showManualOptions &&
      !manualOptions.current.contains(e.target)
    ) {
      setShowManualOptions(false);
    }

    if (tooltip.current && showTooltip && !tooltip.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  const getAudienceDetails = (id: string) => {
    try {
      setLoading(true);
      getAudienceById(id)
        .then((response) => {
          setLoading(false);
          const { error, data = [] } = response;
          if (error === false) {
            const segment = data[0];
            setSelectedSegment(segment);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  const setSegmentForId = (id) => {
    if (segmentList) {
    }
    let interval = setInterval(() => {
      if (segmentList.length !== 0) {
        setSelectedSegment(
          segmentList[
            segmentList.findIndex((x) => {
              x.sement_id === id;
            })
          ]
        );
        clearInterval(interval);
      }
    }, 500);
  };

  const getSegmentList = (from, pageSize) => {
    try {
      setLoading(true);
      getSegments({ from, pageSize })
        .then((response) => {
          setLoading(false);
          let list: any[] = [];
          response.data.map((element) => {
            if (element.audience_id) list.push(element);
          });
          setSegmentList(list);
          // if (lastUpdatedSegmentListLength === -1) setSelectedSegment(list[0]);
          let previousList = [...segmentList];
          let newList = previousList.concat(list);
          setSegmentList(newList);
          setLastUpdatedSegmentListLength(list.length);
          if (audienceType === "segment") {
            setProfiles(list[0]?.total_profiles);
            setBudget(list[0]?.total_profiles);
          }
        })
        .catch((err) => {
          setLoading(false);
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  // const manualTypeOnChange = (event) => {
  //   setProfiles(0);
  //   setBudget(0);
  //   setManualType(event.target.value);
  // };

  const consentRecievedOnChange = (event) => {
    if (event.target.checked) setCheckValidation(false);
    setIsConsentReceived(event.target.checked);
  };

  const onInputChange = (event) => {
    if (event.code == "Enter") {
      if (isMobileNumberDuplicate(mobileNumber, mobileNumbersList)) {
        Notify({
          type: "error",
          heading: "Duplicate Mobile Number",
          message: `${mobileNumber} is already added. Add a different number.`,
        });
      } else {
        mobileNumbersList.push(mobileNumber);
        setMobileNumber("");
        let profiles: any = getNumberOfValidphones();
        setProfiles(profiles);
        setBudget(profiles);
        if (numberValidation) {
          if (isNumberListValid(mobileNumbersList)) setNumberValidation(false);
        }
      }
    }
  };

  // const onControlNumberInputChange = (e) => {
  //   if (e.code === "Enter") {
  //     controlNumberList.push(controlNumber);
  //     setControlNumber("");
  //   }
  // };

  const onInputMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  // const onControlNumberChange = (e) => {
  //   setControlNumber(e.target.value);
  // };

  const popItemFromMobileList = (item) => {
    let list = [...mobileNumbersList];
    let index = list.lastIndexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
    setMobileNUmbersList(list);
    let profiles: any = getNumberOfValidphones();
    setProfiles(profiles);
    setBudget(profiles);
  };

  // function removeControlNumber(element) {
  //   let list = [...controlNumberList];
  //   let index = list.lastIndexOf(element);
  //   if (index > -1) {
  //     list.splice(index, 1);
  //   }
  //   setControlNumberList(list);
  // }

  const isPhoneNumberValid = (number: any) => {
    if (number[0] === 0 || number.length !== 10) {
      return false;
    }
    return true;
  };

  const onFileSelected = (event) => {
    setFileSizeValidator(false);
    if (event.target.files && event.target.files[0]) {
      if (
        parseFloat(
          (event.target.files[0].size / Math.pow(1000, 2)).toFixed(2)
        ) > 50
      ) {
        setFileSizeValidator(true);
        return;
      }
      //setFileName("Uploading...");
      setFileUploadSpinnerStatus(true);
      const file = event.target.files[0];
      setFileValidation(false);

      try {
        uploadSegmentCSV(file)
          .then((res) => {
            const { data, response = {} } = res;
            if (data && !data?.error) {
              //TODO add api call to create an audience with segment Id
              const {
                total_profiles,
                duplicate,
                uploaded_profiles,
                invalid_profiles,
              } = data.data;
              const breakdownMessage = `Uploaded: ${uploaded_profiles}, Duplicate: ${duplicate}, Invalid: ${invalid_profiles}, Total: ${total_profiles}`;
              postAudienceFromCampaign({
                segment: data.data.segment,
                name: file.name.split(".")[0],
                size: data.data.uploaded_profiles,
                description: "Campaign",
              })
                .then((audience: any) => {
                  setFileUploadSpinnerStatus(false);
                  //setFileName(file.name);
                  processCsv(file);
                  setFileUploadStatus(true);
                  let f = {
                    name: file.name,
                    size: file.size,
                    count: uploaded_profiles,
                  };
                  setSelectedFile(f);
                  processCsv(f);
                  setSegmentId(audience.data.audience_id);
                  Notify({
                    type: "success",
                    heading: "Import successful",
                    message: `${breakdownMessage}`,
                  });
                })
                .catch((err) => {
                  err.response
                    ? Sentry.captureMessage("Error", err.response)
                    : Sentry.captureMessage("Error", err);
                  setFileUploadSpinnerStatus(false);
                  unselectFile();
                  console.log(err);
                });
            } else {
              Sentry.captureMessage("Error", res);
              setFileUploadSpinnerStatus(false);
              unselectFile();
            }
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            setFileUploadSpinnerStatus(false);
            unselectFile();
            console.log("File upload error", err);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        setFileUploadSpinnerStatus(false);
        unselectFile();
        console.log("File upload error", err);
      }
    } else {
      setFileUploadSpinnerStatus(false);
      unselectFile();
    }
  };

  const processCsv = (file) => {
    setProfiles(file.count);
    setBudget(file.count);
    //setProfilesAdded(file.count);
  };

  const unselectFile = () => {
    setSelectedFile(null);
    //setFileName("");
    setProfiles(0);
    setBudget(0);
  };

  // const budgetOnChange = (event) => {
  //   if (event.target.value === "") {
  //     setBudget(0);
  //     setProfiles(0);
  //   } else {
  //     let value =
  //       event.target.value[0] == 0
  //         ? event.target.value.substring(1)
  //         : event.target.value;
  //     setBudget(value);
  //     setProfiles(Math.floor(value / unitPrice));
  //   }
  // };

  // const profileOnChange = (event) => {
  //   if (event.target.value === "") {
  //     setProfiles(0);
  //     setBudget(0);
  //   } else {
  //     let value =
  //       event.target.value[0] == 0
  //         ? event.target.value.substring(1)
  //         : event.target.value;
  //     setProfiles(value);
  //     setBudget(value);
  //     setBudgetError(false);
  //   }
  // };

  // const updateSurveyCreated = (data, id) => {
  //   try {
  //     updateSurvey(data, id)
  //       .then((response) => {
  //         setLoading(false);
  //         props.setData(data);
  //         props.setStep(2);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         err.response
  //           ? Sentry.captureMessage("Error", err.response)
  //           : Sentry.captureMessage("Error", err);
  //       });
  //   } catch (err) {
  //     setLoading(false);
  //     err.response
  //       ? Sentry.captureMessage("Error", err.response)
  //       : Sentry.captureMessage("Error", err);
  //     console.log(err);
  //   }
  // };

  // const targetProfileOnChange = (event) => {
  //   if (event.target.value === "") {
  //     setTargetProfiles(0);
  //     setBudget(0);
  //   } else {
  //     let value = Number(event.target.value);
  //     setTargetProfiles(value);
  //     if (audienceType === "general") {
  //       setProfiles(value);
  //     }
  //     setBudget(value);
  //     setBudgetError(false);
  //   }
  // };

  const continueToNext = (event, draft) => {
    setSubmit(true);
    setShowErrors(true);
    const data: any = props.data;
    let id = data.surveyId?.replaceAll("/", "%2F");

    data.profiles = Number(profiles);

    data.targetProfiles = Number(budget);
    data.budget = Number(budget);
    data.unitPrice = unitPrice;
    data.segment = selectedSegment;
    data.isConsentReceived = isConsentReceived;
    data.controlNumbers = controlNumberList;
    data.surveyInvite = audienceType === "manual" || audienceType === "segment";
    data.senderID = senderID;
    data.manualType = manualType;
    data.multipleSubmissions = formData.multipleSubmissions;
    data.subscribedPlan = subscriptionPlan;
    // data.invitationMessageDetails = {
    //   conversationId: "",
    //   channel: "SMS",
    //   content: {
    //     type: "TEXT",
    //     text: message,
    //   },
    // };
    data.totalPrice = getTotalPricing();
    data.audienceType = audienceType;
    data.typeOfAudience = audienceType;

    if (!budget || budget <= 0 || budget > profiles) {
      setSubmit(false);
      return;
    }

    if (audienceType == "general") {
      // if (!budget) {
      //   setBudgetError(true);
      //   setLoading(false);
      //   return;
      // }

      data.profiles = Number(profiles);
      data.isConsentReceived = isConsentReceived;
      data.segment = selectedSegment;
      data.totalPrice = getTotalPricing();
      data.audience = { phoneNumbers: [] };

      props.setData(data);
      props.setStep(2);
    }

    if (audienceType === "manual") {
      // data.invitationMessageDetails = {
      //   conversationId: "",
      //   channel: "SMS",
      //   content: {
      //     type: "TEXT",
      //     text: message,
      //   },
      //   phonenumberId: "",
      //   messageType: "PUSH",
      //   chatChannel: "",
      //   senderName: senderID,
      //   landingPagesURL: "",
      //   senderID: senderID,
      //   smsPages: 1,
      //   timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // };

      if (manualType === "Upload a file") {
        if (!fileUploadStatus) {
          setFileValidation(true);
          setLoading(false);
          return;
        }

        data.audience = { phoneNumbers: [], segmentId: segmentId };
        data.file = selectedFile;
        data.manualType = manualType;

        // try {
        //   updateSurvey(data, id)
        //     .then((response) => {
        //       setLoading(false);
        //       props.setData(data);
        //       draft
        //         ? Notify({
        //             type: "success",
        //             heading: "Success",
        //             message: "Survey saved successfully",
        //           })
        //         : props.setStep(2);
        //     })
        //     .catch((err) => {
        //       setLoading(false);
        //       err.response
        //         ? Sentry.captureMessage("Error", err.response)
        //         : Sentry.captureMessage("Error", err);
        //     });
        // } catch (error) {
        //   setLoading(false);
        //   error.response
        //     ? Sentry.captureMessage("Error", error.response)
        //     : Sentry.captureMessage("Error", error);
        //   console.log(error);
        // }
        props.setData(data);
        props.setStep(2);
        setFileValidation(false);
      } else {
        if (mobileNumbersList.length === 0) {
          setNumberValidation(true);
          setNumberListError("Phone number list cannot be empty");
          setLoading(false);
          setSubmit(false);
          return;
        }
        if (!isNumberListValid(mobileNumbersList)) {
          setNumberValidation(true);
          setNumberListError(
            "One or more of the inputted number is in a wrong format. Please ensure the phone number doesn’t include 0 or country code eg. 8066603063."
          );
          setLoading(false);
          setSubmit(false);
          return;
        }

        if (!isNumberListValid(controlNumberList)) {
          setControlNumberValidation((controlNumberValidation) => ({
            ...controlNumberValidation,
            error: true,
            message:
              "one or more of the inputted number is in a wrong format. Please ensure that the phone number doesn't include 0 or country code ",
          }));
          setLoading(false);
          setSubmit(false);
          return;
        }
        if (!isConsentReceived) {
          setCheckValidation(true);
          setLoading(false);
          setSubmit(false);
          return;
        }

        // if (!senderID) {
        //   setSenderIdError(true);
        //   setLoading(false);
        //   return;
        // }

        // if (!message) {
        //   setMessageError(true);
        //   setLoading(false);
        //   return;
        // }

        // data.audienceType = "custom";
        // data.typeOfAudience = audienceType;
        data.audience = { phoneNumbers: mobileNumbersList, segmentId: "" };
        data.manualType = manualType;

        // try {
        //   updateSurvey(data, id)
        //     .then((response) => {
        //       setLoading(false);
        //       props.setData(data);
        //       draft
        //         ? Notify({
        //             type: "success",
        //             heading: "Success",
        //             message: "Survey saved successfully",
        //           })
        //         : props.setStep(2);
        //     })
        //     .catch((err) => {
        //       setLoading(false);
        //       err.response
        //         ? Sentry.captureMessage("Error", err.response)
        //         : Sentry.captureMessage("Error", err);
        //     });
        // } catch (error) {
        //   setLoading(false);
        //   error.response
        //     ? Sentry.captureMessage("Error", error.response)
        //     : Sentry.captureMessage("Error", error);
        //   console.log(error);
        // }
        setNumberValidation(false);
        setControlNumberValidation((controlNumberValidation) => ({
          ...controlNumberValidation,
          error: false,
        }));
        props.setData(data);
        props.setStep(2);
      }
    } else if (audienceType === "segment") {
      if (manualType === "audience") {
        if (!selectedSegment) {
          Notify({
            type: "error",
            heading: "Failed",
            message: "Please select Audience",
          });
          setLoading(false);
          setSubmit(false);
          return;
        }

        // if (!senderID) {
        //   setSenderIdError(true);
        //   setLoading(false);
        //   return;
        // }

        // if (!message) {
        //   setMessageError(true);
        //   setLoading(false);
        //   return;
        // }

        // data.audienceType = "segment";
        // data.typeOfAudience = audienceType;
        data.audience = {
          segmentId: selectedSegment.audience_id,
          phoneNumbers: [],
        };
        // data.invitationMessageDetails = {
        //   conversationId: "",
        //   channel: "SMS",
        //   content: {
        //     type: "TEXT",
        //     text: message,
        //   },
        //   phonenumberId: "",
        //   messageType: "PUSH",
        //   chatChannel: "",
        //   senderName: senderID,
        //   landingPagesURL: "",
        //   senderID: senderID,
        //   smsPages: 1,
        //   timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        props.setData(data);
        props.setStep(2);
        // };
      } else if (manualType === "customAudience") {
        if (!customAudiencePayload) {
          Notify({
            type: "error",
            heading: "Failed",
            message: "Please create a custom audience",
          });
          setLoading(false);
          setSubmit(false);
          return;
        }
        // let payload = {
        //   audience_type: "static",
        //   audience_name: `${data.name}-custom audience`, // required
        //   audience_description: `audience created for survey ${data.name}`,
        //   client_id: "data_team",
        //   audience_size: profiles,
        //   immediate: false,
        //   ...customAudiencePayload,
        // };
        // postAudience(payload)
        //   .then((audience: any) => {
        //     audience_id = audience.data.audience_id;

        //     data.audience = {
        //       segmentId: audience_id,
        //     };
        //     props.setData(data);
        //     props.setStep(2);
        //   })
        //   .catch((err) => {
        //     err.response
        //       ? Sentry.captureMessage("Error", err.response)
        //       : Sentry.captureMessage("Error", err);
        //   });

        data.audienceType = "segment";
        data.typeOfAudience = audienceType;
        data.audienceQuery = customAudiencePayload;

        props.setData(data);
        props.setStep(2);
      }

      // try {
      //   updateSurvey(data, id)
      //     .then((response) => {
      //       setLoading(false);
      //       props.setData(data);
      //       draft
      //         ? Notify({
      //             type: "success",
      //             heading: "Success",
      //             message: "Survey saved successfully",
      //           })
      //         : props.setStep(2);
      //     })
      //     .catch((err) => {
      //       setLoading(false);
      //       err.response
      //         ? Sentry.captureMessage("Error", err.response)
      //         : Sentry.captureMessage("Error", err);
      //     });
      // } catch (error) {
      //   setLoading(false);
      //   error.response
      //     ? Sentry.captureMessage("Error", error.response)
      //     : Sentry.captureMessage("Error", error);
      //   console.log(error);
      // }
      // props.setData(data);
      // props.setStep(2);
    }
  };

  const isNumberListValid = (numberList) => {
    let status = true;
    numberList.map((element, i) => {
      if (!isPhoneNumberValid(element)) {
        status = false;
      }
    });
    return status;
  };

  const getSurveyLink = () => {
    if (props.data?.channel === "WEB") {
      let id: any = props.data?.surveyId;
      id = id?.replaceAll("/", "%2F");
      return `${SURVEY_URL}${id}?live=true`;
    }
    if (props.data?.channel === "USSD") {
      return props.data?.shortCode;
    }
    return "";
  };

  const getTotalPricing = () => {
    let addedCosts = unitPrice;
    if (props.data?.sendFollowUpMessage) {
      addedCosts += smsPrice;
    }

    return addedCosts * (targetProfiles || profiles) + rewardBudget;
  };

  // const copyToClipboard = (value: string) => {
  //   navigator.clipboard
  //     .writeText(value)
  //     .then(() => {
  //       setShowCopyTooltip(true);
  //       setTimeout(() => {
  //         setShowCopyTooltip(false);
  //       }, 4000);
  //     })
  //     .catch((error) => {
  //       error.response
  //         ? Sentry.captureMessage("Error", error.response)
  //         : Sentry.captureMessage("Error", error);
  //       console.error("Failed to copy:", error);
  //     });
  // };

  // const goBack = (event) => {
  //   const data = {
  //     ...props.data,
  //     budget,
  //     profiles,
  //     targetProfiles,
  //     totalPrice: getTotalPricing(),
  //     selectedFile,
  //     audienceType,
  //     manualType,
  //     controlNumbers: controlNumberList,
  //     surveyInvite: audienceType === "manual" || audienceType === "segment",
  //     audience: {
  //       segmentId: selectedSegment?.segment_id,
  //       phoneNumbers: mobileNumbersList,
  //     },
  //     segment: selectedSegment,
  //     senderID,
  //     isConsentReceived,
  //     invitationMessageDetails: {
  //       conversationId: "",
  //       channel: "SMS",
  //       content: {
  //         type: "TEXT",
  //         text: message,
  //       },
  //     },
  //   };
  //   props.setData(data);
  //   props.setStep(2);
  // };

  const getNumberOfValidphones = () => {
    let count = 0;
    mobileNumbersList.map((element, i) => {
      if (isPhoneNumberValid(element)) {
        count++;
      }
    });
    return count;
  };

  const onAudienceTypeChange = (type) => {
    if (type === "segment") {
      setAudienceDescription(
        "Select respondents from already existing segments or filter and send your survey to over 2 million Terragon Audiences from below."
      );
      setAudienceImg(ChartAudience);
      setManualType("audience");
      if (selectedSegment) {
        setProfiles(selectedSegment?.audience_size);
        setBudget(selectedSegment?.audience_size);
      } else {
        setProfiles(0);
        setBudget(0);
      }
    }

    if (type === "general") {
      setAudienceDescription(
        "Use a survey link that can be easily shared with individuals within your network, allowing you to conveniently collect and analyze their responses."
      );
      setAudienceImg(Peopleshadow);
      setManualType("general");
      //setProfiles(targetProfiles);
      // setBudget(targetProfiles);
    }

    if (type === "manual") {
      setAudienceDescription(
        "Prefer to upload your own audience for this survey? Simply upload a fresh batch of customer profiles to gather responses."
      );
      setAudienceImg(FileUploadAudience);
      setManualType("Upload a file");
      let manualProfiles = getNumberOfValidphones();
      setProfiles(manualProfiles);
      setBudget(manualProfiles);
    }
    setAudienceType(type);
  };

  const onSegmentSelectionChange = (segment) => {
    setManualType("audience");
    // setSelectedSegment(segment);
    // setProfiles(segment.total_profiles);
    // setBudget(segment.total_profiles * unitPrice);
    // setShowSegmentSelector(false);

    let selectedProfiles = segment.audience_size ? segment.audience_size : 0;

    setSelectedSegment(segment);
    setProfiles(selectedProfiles);
    setBudget(selectedProfiles);
    //setProfilesAdded(selectedProfiles);
    setSelectedFile(null);
    setShowSegmentSelector(false);
  };

  const segmentSelectorOnChange = () => {
    let segmentSelction = showSegmentSelector;
    setShowSegmentSelector(!segmentSelction);
  };

  const onSegmentListScroll = () => {
    if (segmentListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        segmentListInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (lastUpdatedSegmentListLength !== 0) {
          getSegmentList(lastUpdatedSegmentListLength + 1, 20);
        }
      }
    }
  };

  const handleSubscriptionModalClick = (status) => {
    if (status) props.navigate("/pricing");
    else setSubscriptionRedirectModalStatus(false);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const dm = decimals < 0 ? 0 : decimals;

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <div className="p-10">
      <div className="text-[#667085] text-sm font-semibold mb-2">
        STEP 1 OF 4
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-6 items-center">
          <div className="bg-[#FFF4DD] h-[61px] w-[61px] rounded-[50%] flex items-center justify-center">
            <img src={People} alt="" />
          </div>
          <div>
            <div className="text-[#475467] font-semibold text-base">
              Audience details
            </div>
            <div className="text-[#667085] text-sm">Who are you targeting?</div>
          </div>
        </div>
        <div className="flex gap-4">
          <button
            className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
            onClick={() => props.navigate("/engagements/surveys")}
            // onClick={() => props.setStep(1)}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={(event) => continueToNext(event, false)}
            disabled={loading}
            className="py-3 px-5 w-24 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-blue-75 flex justify-center items-center"
          >
            {submit ? (
              <Spinner className={"w-4 h-4"} />
            ) : (
              <div className="flex gap-3 items-center">
                {"Next  "} <img src={ArrowRight} alt="" />
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="mt-9 flex justify-between">
        <div className="grow">
          <div>
            <div>
              <div className="text-[#667085] text-sm">
                Select your preferred option
              </div>
              <div className="flex gap-3">
                <label className="mt-3 border flex rounded-md py-3 px-5 justify-between w-[50%] cursor-pointer">
                  <div>General public</div>
                  <input
                    className="accent-gray-700"
                    type="radio"
                    onClick={() => {
                      onAudienceTypeChange("general");
                    }}
                    checked={audienceType === "general"}
                  />
                </label>
                <label className="mt-3 border flex rounded-md py-3 px-5 justify-between w-[50%] cursor-pointer">
                  <div>Add from audience</div>
                  <input
                    className="accent-gray-700"
                    type="radio"
                    onClick={() => {
                      onAudienceTypeChange("segment");
                    }}
                    checked={audienceType === "segment"}
                  />
                </label>
                <label className="mt-3 border flex rounded-md py-3 px-5 justify-between w-[50%] cursor-pointer">
                  <div>Manual upload</div>
                  <input
                    className="accent-gray-700"
                    type="radio"
                    onClick={() => {
                      onAudienceTypeChange("manual");
                    }}
                    checked={audienceType === "manual"}
                  />
                </label>
              </div>
              {showAudienceDescription && (
                <div className="mt-2 flex justify-between items-center p-3 border border-green-150 rounded-md text-[#4B5563] text-xs leading-4">
                  <div className="flex items-center gap-4">
                    <div>
                      <img
                        className="h-[14px] w-[17px]"
                        src={audienceImg}
                        alt=""
                      />
                    </div>
                    <div>{audienceDescription}</div>
                  </div>
                  <div
                    onClick={() => setShowAudienceDescription(false)}
                    className="cursor-pointer"
                  >
                    <img src={Close} alt="" />
                  </div>
                </div>
              )}
              <div className="mt-6">
                {audienceType === "manual" && (
                  <div>
                    <div className="my-8">
                      <div className="text-gray-70 font-semibold text-sm mb-1.5">
                        Manual upload types
                      </div>
                      <div className="relative w-full">
                        <button
                          name="surveyChannel"
                          className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-200 rounded-md focus:outline-none ${
                            manualType === "" && "text-gray-400"
                          } ${manualTypeError && "border-red-500"}`}
                          type="button"
                          onClick={() => {
                            setShowManualOptions(!showManualOptions);
                            setManualTypeError(false);
                          }}
                          disabled={loading}
                        >
                          {manualType || "Select your manual upload option"}
                          <img src={Chevron} alt="" />
                        </button>

                        {showManualOptions && (
                          <div
                            ref={manualOptions}
                            className="absolute z-10 bg-white rounded-lg shadow w-full"
                          >
                            {["Add numbers manually", "Upload a file"].map(
                              (option, index) => (
                                <button
                                  key={index}
                                  className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                                  role="menuitem"
                                  onClick={() => {
                                    setManualType(option);
                                    setShowManualOptions(false);
                                  }}
                                >
                                  {option}
                                </button>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      {manualType == "Upload a file" && (
                        <div>
                          <div className="mt-4">
                            For a lot of profiles. Upload a file with their
                            numbers.{" "}
                            <span>
                              <a
                                href="https://mcassets.blob.core.windows.net/cdn/AudienceAssets/Audience_Sample_Template.csv"
                                download="Sample_Template.csv"
                                className="text-blue-75 font-semibold cursor-pointer"
                              >
                                Download sample file
                              </a>
                            </span>
                          </div>

                          <div className="relative">
                            {fileUploadSpinnerStatus && (
                              <div className="absolute top-0 left-0 z-10 flex justify-center h-full w-full bg-[#ffffffb8] border rounded">
                                <Spinner className="w-8 h-8" />
                              </div>
                            )}
                            {selectedFile?.name ? (
                              <div
                                className={`mt-3 py-3 bg-gray-25 border border-dashed border-gray-200 rounded-lg cursor-pointer relative ${
                                  fileValidation
                                    ? "border-red-500"
                                    : "border-gray-200"
                                }`}
                              >
                                <div className="flex justify-between items-center px-4">
                                  <div className="flex gap-4 items-center px-2">
                                    <img src={SelectedFile} alt="" />
                                    <div className="text-gray-70">
                                      <div className="text-base font-semibold">
                                        {selectedFile?.name}
                                      </div>
                                      <div>
                                        {formatBytes(selectedFile?.size)}
                                      </div>
                                    </div>
                                  </div>
                                  <X
                                    className="text-gray-600 cursor-pointer"
                                    size="16"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      unselectFile();
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={`mt-3 py-6 bg-gray-25 flex items-center justify-center border border-dashed border-gray-200 rounded-lg cursor-pointer relative ${
                                  fileValidation
                                    ? "border-red-500"
                                    : "border-gray-200"
                                }`}
                              >
                                <div className="flex flex-col text-center items-center justify-center">
                                  <img src={File} alt="" />
                                  <div className="my-1">
                                    <span className="text-blue-75 font-semibold cursor-pointer">
                                      Choose a file to upload
                                    </span>
                                  </div>
                                  <div className="text-gray-500 text-sm">
                                    or drag and drop it here
                                  </div>
                                  <p
                                    className={`${
                                      fileSizeValidator
                                        ? "text-red-500"
                                        : "text-gray-500"
                                    } text-xs mt-1`}
                                  >
                                    {fileSizeValidator
                                      ? "File exceeds the 50 MB upload limit. Please upload a smaller CSV or XLS file."
                                      : "Only CSV, XLS supported. Max file size: 50 MB"}
                                  </p>
                                </div>
                                <input
                                  className="absolute w-full h-full cursor-pointer opacity-0"
                                  type="file"
                                  accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  id="importFile"
                                  onChange={onFileSelected}
                                  required
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {manualType == "Add numbers manually" && (
                        <div>
                          <div className="mt-4">
                            Use ‘Enter’ to separate phone numbers. Do not add
                            country code or ‘0’ before the number.
                          </div>
                          <div>
                            <div
                              className={
                                numberValidation
                                  ? "border border-[#D92D20] rounded text-[#475467]"
                                  : "border border-[#E4E7EC] rounded text-[#475467]"
                              }
                              style={{
                                marginTop: "8px",
                                width: "100%",
                                display: "inline-block",
                              }}
                            >
                              <div style={{ float: "left" }}>
                                {mobileNumbersList.map((element, i) => {
                                  return (
                                    <span
                                      className={
                                        isPhoneNumberValid(element)
                                          ? "border border-[#D8E0E7] inline-block rounded-md py-2 px-3 text-gray-700 text-sm mt-2 ml-2"
                                          : "border border-[#D92D20] inline-block rounded-md py-2 px-3 text-gray-700 text-sm mt-2 ml-2"
                                      }
                                      key={i}
                                    >
                                      {element}{" "}
                                      <span
                                        className="text-[#687992] font-semibold cursor-pointer ml-2"
                                        onClick={() =>
                                          popItemFromMobileList(element)
                                        }
                                      >
                                        x
                                      </span>
                                    </span>
                                  );
                                })}
                              </div>
                              <div>
                                <input
                                  placeholder="Enter your number here."
                                  value={mobileNumber}
                                  onChange={($event) =>
                                    onInputMobileNumberChange($event)
                                  }
                                  className="h-[34px] outline-0 border-0 m-2"
                                  style={{ width: "-webkit-fill-available" }}
                                  type="number"
                                  onKeyDown={($event) => onInputChange($event)}
                                />
                              </div>
                            </div>
                            {numberValidation ? (
                              <div className="text-[#E53E51] text-sm mt-2">
                                {numberListError}
                              </div>
                            ) : null}
                          </div>
                          <div className="mt-6 flex items-center gap-2 text-gray-700 text-sm">
                            <input
                              id="consent"
                              className={
                                checkValidation
                                  ? "cursor-pointer outline-2 outline-[#EF4444] outline-offset-[-1px]"
                                  : "cursor-pointer accent-gray-700"
                              }
                              type="checkbox"
                              checked={isConsentReceived}
                              onChange={consentRecievedOnChange}
                            />
                            <label htmlFor="consent" className="cursor-pointer">
                              Numbers uploaded have given consent to receive
                              promotional messages from this business.
                            </label>
                          </div>
                          {checkValidation ? (
                            <div className="text-sm text-[#EF4444]">
                              This field must be checked to continue.
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {audienceType === "segment" && (
                  <div>
                    <span className="text-sm text-[#475467] font-semibold">
                      Segments
                    </span>
                    <div className="relative mt-1">
                      <button
                        className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                          !selectedSegment && "text-gray-400"
                        }`}
                        onClick={() => {
                          segmentSelectorOnChange();
                        }}
                      >
                        <span>
                          {manualType === "customAudience"
                            ? "Select Custom attributes"
                            : segmentList.length
                            ? selectedSegment?.audience_name ||
                              "Select Audience"
                            : "No Audience available!"}
                        </span>
                        <img src={DropDownArrow} alt="DropDownArrow" />
                      </button>

                      {showSegmentSelector && (
                        <div
                          className="absolute z-10 bg-white rounded-lg shadow w-full max-h-[160px] overflow-auto"
                          onScroll={onSegmentListScroll}
                          ref={segmentListInnerRef}
                        >
                          <div
                            className="py-3 px-4 border-b cursor-pointer flex justify-between items-center hover:bg-[#F9FAFB]"
                            onClick={() => {
                              setManualType("customAudience");
                              setShowSegmentSelector(false);
                            }}
                          >
                            <div className="flex gap-2 items-center">
                              <span className="text-sm leading-6 font-semibold text-gray-700">
                                {"+ Select custom attributes"}
                              </span>
                            </div>
                          </div>
                          <div className="text-xs px-4 py-4 sticky top-0 bg-white">
                            {[
                              { label: "All", value: "" },
                              { label: "Your audience", value: "csv" },
                              { label: "Terragon audience", value: "terragon" },
                            ].map((item, index) => (
                              <span
                                className={`mr-3 px-2 py-1 rounded cursor-pointer ${
                                  audienceSource === item.value
                                    ? "text-gray-700 bg-slate-200"
                                    : "text-gray-500"
                                }`}
                                key={index}
                                onClick={() => {
                                  setSegmentList([]);
                                  setAudienceSource(item.value);
                                }}
                              >
                                {item.label}
                              </span>
                            ))}
                          </div>
                          {segmentList.length === 0 ? (
                            <div className="py-3 flex justify-center">
                              {" "}
                              <Spinner className={"w-4 h-4"} />
                            </div>
                          ) : (
                            segmentList.map((ele, i) => {
                              return (
                                <div
                                  className="py-3 px-4 cursor-pointer flex justify-between items-center hover:bg-[#F9FAFB]"
                                  key={i}
                                  onClick={() => {
                                    tierId === 0 &&
                                    ele.audience_source === "terragon"
                                      ? setSubscriptionRedirectModalStatus(true)
                                      : onSegmentSelectionChange(ele);
                                  }}
                                >
                                  <div className="flex gap-2 items-center">
                                    <span className="text-sm text-gray-700">
                                      {ele.audience_name}
                                    </span>
                                    {tierId === 0 &&
                                      ele.audience_source === "terragon" && (
                                        <span className="bg-blue-50 text-blue-800 font-semibold text-xs rounded-full flex items-center px-3 py-1">
                                          Upgrade plan to use
                                        </span>
                                      )}
                                  </div>
                                  <span className="text-sm text-[#98A2B3]">
                                    {ele.audience_size ? ele.audience_size : 0}
                                  </span>
                                </div>
                              );
                            })
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* {audienceType !== "general" && (
                <>
                  <div className="mt-6">
                    <div className="pt-3">
                      <div className="text-gray-70 font-semibold text-sm mb-4">
                        Add survey invite
                      </div>
                      <div className="text-gray-70 font-semibold text-sm mb-2">
                        Sender ID
                      </div>
                      <div className="relative w-full">
                        <button
                          className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border border-gray-300 rounded-md focus:outline-none ${
                            senderID === "" && "text-gray-400"
                          } ${senderIdError && "border-red-500"}`}
                          type="button"
                          onClick={() => setShowSenderIDMenu(!showSenderIDMenu)}
                        >
                          {senderIDs.length
                            ? senderID || "Select your sender ID"
                            : "No enabled Sender ID available!"}
                          <Chevron />
                        </button>

                        {showSenderIDMenu && (
                          <div
                            ref={senderIDMenu}
                            className="absolute z-10 bg-white rounded-lg shadow w-full"
                          >
                            {senderIDs?.map((senderID, index) => (
                              <button
                                key={index}
                                className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                                role="menuitem"
                                onClick={() => {
                                  setSenderID(senderID?.name);
                                  setSenderIdError(false);
                                  setShowSenderIDMenu(false);
                                }}
                              >
                                {senderID?.name}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>

                      <div className="pt-3">
                        <div className="text-gray-70 font-semibold text-sm my-2">
                          Message
                        </div>
                        <div
                          className={`border border-gray-300 rounded-md  ${
                            messageError && "border-red-500"
                          }`}
                        >
                          <textarea
                            name="message"
                            placeholder="Enter message here"
                            value={message}
                            maxLength={160}
                            className={`w-full h-24 px-3 py-3 mt-1 focus:outline-none ${
                              !message && "border-red-500"
                            }`}
                            onChange={(event) => {
                              setMessage(event.target.value);
                              setMessageError(false);
                            }}
                          />
                          <hr className="border-gray-300" />
                          <div>
                            <div className="px-4 py-2 cursor-pointer">
                              <UserOutline />
                            </div>
                            <div className="text-gray-400 bg-[#F9FAFB] px-4 py-3 rounded-b-md">
                              {props.businessName
                                ? `Courtesy: ${props.businessName}`
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`text-gray-70 font-normal text-sm pt-2 ${
                            message.length === 160 && "text-red-500"
                          }`}
                        >
                          {message.length + "/160 characters"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-10">
                    <div className="text-sm leading-6 text-[#475467] font-semibold mb-1">
                      Control numbers
                    </div>
                    <div className="text-sm leading-6 font-normal">
                      Track when your survey has been delivered to your audience
                    </div>
                    <div>
                      <div
                        className={
                          controlNumberValidation.error
                            ? "border border-[#D92D20] rounded text-[#475467]"
                            : "border border-[#E4E7EC] rounded text-[#475467]"
                        }
                        style={{
                          marginTop: "8px",
                          width: "100%",
                          display: "inline-block",
                        }}
                      >
                        <div style={{ float: "left" }}>
                          {controlNumberList.map((element, i) => {
                            return (
                              <span
                                className={
                                  isPhoneNumberValid(element)
                                    ? "border border-[#D8E0E7] inline-block rounded-md py-2 px-3 text-gray-700 text-sm mt-2 ml-2"
                                    : "border border-[#D92D20] inline-block rounded-md py-2 px-3 text-gray-700 text-sm mt-2 ml-2"
                                }
                                key={i}
                              >
                                {element}{" "}
                                <span
                                  className="text-[#687992] font-semibold cursor-pointer ml-2"
                                  onClick={() => removeControlNumber(element)}
                                >
                                  ✕
                                </span>
                              </span>
                            );
                          })}
                        </div>
                        <div>
                          <input
                            placeholder="Enter phone numbers."
                            value={controlNumber}
                            onChange={($event) => onControlNumberChange($event)}
                            className="h-[34px] outline-0 border-0 m-2"
                            style={{ width: "-webkit-fill-available" }}
                            type="number"
                            onKeyDown={($event) =>
                              onControlNumberInputChange($event)
                            }
                          />
                        </div>
                      </div>
                      {controlNumberValidation.error ? (
                        <div className="text-[#E53E51] text-sm mt-2">
                          {controlNumberValidation.message}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              )} */}
            </div>
          </div>

          {/* {audienceType === "general" && (
            <>
              <div className="mt-10">
                <div className="text-gray-70 font-semibold text-sm mb-1.5">
                  {props.data.channel === "USSD"
                    ? "Survey USSD"
                    : "Survey Link"}
                </div>

                <div className="relative flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-4 bg-gray-10 border border-gray-200 rounded-md focus:outline-none">
                  <input
                    type="text"
                    name="surveyLink"
                    placeholder="Survey link"
                    className="w-full pr-3 text-gray-400 bg-gray-10 focus:outline-none"
                    value={getSurveyLink()}
                    readOnly
                  />
                  <div
                    className="border-l h-12 py-3.5 pl-4 font-semibold text-gray-70 border-gray-200 cursor-pointer"
                    onClick={() => copyToClipboard(getSurveyLink())}
                  >
                    Copy
                  </div>
                  {showCopyTooltip && (
                    <div className="absolute w-60 p-3 z-10 mt-24 right-0 bg-gray-200 text-gray-700 text-xs rounded-lg shadow-lg">
                      {props.data.channel === "USSD"
                        ? "Survey USSD copied to clipboard"
                        : "Survey link copied to clipboard"}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="mt-10">
            <div className="text-gray-70 font-semibold text-sm mb-1.5">
              Set your budget
            </div>
            <div className="text-gray-500">
              Enter the number of profiles you want to reach.
            </div>

            <div
              className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-4 border border-gray-200 rounded-md focus:outline-none ${
                budgetError && "border-red-500"
              }`}
            >
              <input
                type="number"
                name="targetEstimate"
                placeholder="Enter number"
                className="w-full pr-3 focus:outline-none"
                onChange={(event) => targetProfileOnChange(event)}
                value={targetProfiles || profiles || ""}
              />
            </div>
          </div> */}
          {manualType === "customAudience" && (
            <div className="border mt-6">
              <AudienceApp
                screen={"campaign"}
                setTargetProfiles={(value) => {
                  setProfiles(value);
                }}
                customAudiencePayload={customAudiencePayload}
                setCustomAudiencePayload={setCustomAudiencePayload}
              />
            </div>
          )}

          <div className="mt-10">
            <div className="text-gray-70 font-semibold text-sm mb-1.5">
              Set response limit
            </div>
            <div className="text-gray-500">
              Enter an approximate number of responses you would like from this
              survey.
            </div>
            {showErrors && (
              <div className="text-red-500 text-sm">
                {budget <= 0
                  ? "Please provide a valid number of profiles"
                  : budget > profiles
                  ? "Please set a budget less than or equal to number of profiles added"
                  : null}
              </div>
            )}
            <div
              className={`flex w-3/4 justify-between items-center px-4 py-3 text-center h-12 mt-4 border border-gray-200 rounded-md focus:outline-none`}
            >
              <input
                type="number"
                name="targetEstimate"
                placeholder="Enter number"
                className="w-full pr-3 focus:outline-none"
                value={budget}
                onChange={(e) => {
                  setBudget(+e.target.value);
                  if (manualType === "general") {
                    setProfiles(+e.target.value);
                  }
                }}
                // onChange={(event) => targetProfileOnChange(event)}
                //value={targetProfiles || profiles || ""}
              />
              <div>Responses</div>
            </div>
          </div>

          <div className="flex items-center py-3 w-fit">
            <input
              id="multipleSubmissions"
              name="multipleSubmissions"
              type="checkbox"
              checked={!formData.multipleSubmissions}
              className="w-4 h-4 border border-gray-300 rounded cursor-pointer accent-blue-75"
              disabled={loading}
              onChange={(e) => {
                handleChangeFormData(!e.target?.checked, "multipleSubmissions");
              }}
            />
            <label
              htmlFor="multipleSubmissions"
              className="ml-2 text-gray-700 cursor-pointer"
            >
              Prevent multiple submission from the same participant
            </label>
          </div>
        </div>

        <div className="w-[35%] shrink-0">
          <div className="mt-3 ml-6 h-144 border border-gray-30 rounded-lg">
            <div className="py-6 px-8">
              <div className="mb-4 text-gray-700 text-lg font-semibold">
                Audience
              </div>
              <div className="text-gray-700 text-xl font-semibold flex gap-1">
                {formatNumbers(audienceType === "general" ? budget : profiles)}
                <div className="text-sm text-gray-500 mt-1 ml-1 font-normal">
                  {audienceType === "general"
                    ? profiles > 1
                      ? "profiles"
                      : "profile"
                    : profiles > 1
                    ? "profiles matched"
                    : "profile matched"}
                </div>
              </div>
              <>
                <div className="text-gray-500 mt-4 text-sm">
                  {targetText[audienceType]}
                </div>
              </>

              <hr className="my-5" />
              <div className="text-gray-700 text-lg font-semibold">
                Survey cost
              </div>

              <div className="flex items-start justify-between">
                <div className="text-gray-700 font-semibold  mt-4">
                  <div className="mb-2 text-sm leading-6">Survey responses</div>
                  <div className="flex gap-1">
                    <CurrencySymbol value={formatNumbers(unitPrice)} />
                    <span className="text-gray-500 font-normal">
                      / response
                    </span>
                  </div>
                </div>
                <div className="text-gray-700 font-semibold flex gap-1 mt-4">
                  {budget}
                </div>
              </div>

              {props.data?.rewardOption && (
                <>
                  <hr className="my-5" />
                  <div className="text-gray-700 text-lg font-semibold">
                    Reward
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-gray-700 font-semibold flex gap-1 mt-4">
                      <CurrencySymbol value={formatNumbers(rewardPerProfile)} />{" "}
                      <div className="text-gray-500 font-normal">
                        reward / profile
                      </div>
                    </div>
                    <div className="text-gray-700 font-semibold flex gap-1 mt-4">
                      <span className="text-gray-500 font-normal">
                        capped at
                      </span>
                      <CurrencySymbol value={formatNumbers(rewardBudget)} />
                    </div>
                  </div>
                </>
              )}

              {props.data?.sendFollowUpMessage && (
                <div className="flex items-center justify-between">
                  <div className="text-gray-700 font-semibold flex gap-1 mt-4">
                    <CurrencySymbol value={formatNumbers(smsPrice)} />
                    <div className="text-gray-500 font-normal">
                      sms / profile
                    </div>
                  </div>
                  <div className="text-gray-700 font-semibold flex gap-1 mt-4">
                    <CurrencySymbol
                      value={formatNumbers(
                        smsPrice * (targetProfiles || profiles)
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex mt-[60px] justify-between">
        <button
          onClick={(event) => continueToNext(event, true)}
          disabled={loading}
          className="text-gray-70 font-semibold text-sm px-6 py-3 border border-white hover:border-gray-50 rounded-lg"
        >
          Save as draft
        </button>
        <div
          className="w-[40%] flex justify-end gap-4"
          style={{ width: "40%" }}
        >
          <button
            onClick={(event) => continueToNext(event, false)}
            disabled={loading}
            className="py-3 px-24 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75 disabled:bg-gray-400 disabled:text-slate-500 disabled:border-slate-200"
          >
            Continue
          </button>
        </div>
      </div> */}
      {subscriptionRedirectModalStatus && (
        <SubscriptionRedirectModal
          handleClick={(status) => handleSubscriptionModalClick(status)}
        />
      )}
    </div>
  );
};

export default SurveyStepThree;
