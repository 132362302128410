import { useEffect, useState } from "react";
import { ChevronBottom } from "../../../../../assets/icons/icons";

const FormSurveySummary = ({ question }) => {
  const [finalList, setFinalList] = useState([]);

  useEffect(() => {
    let list = [];
    question.responseCounts.map((element, i) => {
      let ob: any = {};
      Object.keys(element).map((key, j) => {
        let entries = [];
        Object.keys(element[key]).map((final, k) => {
          entries.push(final);
        });
        if (key !== "totalCount") {
          ob.name = key;
          ob.totalCount = element.totalCount;
          ob.entries = entries;
          ob.status = false;
        }
      });
      list.push(ob);
    });
    setFinalList(list);
  }, []);

  const updateStatus = (index) => {
    let list = [...finalList];
    list[index].status = !list[index].status;
    setFinalList(list);
  };

  return (
    <div className="mt-5 h-[316px] overflow-hidden">
      <div className="h-full overflow-hidden hover:overflow-auto">
        {finalList.map((element, index) => {
          return (
            <div key={index} className="mt-3">
              <div
                className="flex justify-between h-[36px] items-center cursor-pointer"
                onClick={() => {
                  updateStatus(index);
                }}
              >
                <div className="text-sm text-gray-700 font-semibold">
                  {element.name}
                </div>
                <div className="flex gap-4 items-center">
                  <div className="bg-[#F2F4F7] text-xs text-[#30466A] rounded-full py-1 px-3 select-none">
                    {element.totalCount} response
                  </div>
                  <div className={element.status ? "rotate-180" : "rotate-0"}>
                    <img src={ChevronBottom} alt="" />
                  </div>
                </div>
              </div>
              <div>
                {element.status ? (
                  <div>
                    {element.entries.map((entry, j) => {
                      return (
                        <div
                          key={j}
                          className="mt-3 bg-[#F8F9FA] rounded p-2 text-sm text-[#475467]"
                        >
                          {entry}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
              <div className="border-b mt-4"></div>
            </div>
          );
        })}
      </div>
    </div>
    // <div className="mt-5">
    //   {question.responseCounts.map((element, i) =>
    //     Object.keys(element).map((key, j) => {
    //       return (
    //         <div>
    //           <div>
    //             <div>{key}</div>
    //             <div>
    //               <ChevronBack />
    //             </div>
    //           </div>
    //           {Object.keys(element[key]).map((final, k) => {
    //             return (
    //               <div
    //                 key={k}
    //                 className="mt-3 bg-[#F8F9FA] rounded p-2 text-xs text-[#475467]"
    //               >
    //                 {final}
    //               </div>
    //             );
    //           })}
    //         </div>
    //       );
    //     })
    //   )}
    // </div>
  );
};

export default FormSurveySummary;
