import { useEffect, useState } from "react";
import { getSurveySummary } from "../../../../../common/services/survey-services";
import ReactECharts from "echarts-for-react";
import { ChevronBottom } from "../../../../../assets/icons/icons";
import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/en_GB";
import * as Sentry from "@sentry/react";

const { RangePicker } = DatePicker;

const SurveyResponseTime = (props: any) => {
  const { id, respondentsType, surveyDuration, dispatch, setShowCommonLoader } =
    props;
  const [option, setOption] = useState({});
  const [startDate, setStartDate] = useState(surveyDuration.startDate);
  const [endDate, setEndDate] = useState(surveyDuration.endDate);

  useEffect(() => {
    getData();
  }, [startDate, endDate, respondentsType]);

  const getData = () => {
    let xData = [];
    let yData = [];
    try {
      dispatch(setShowCommonLoader(true));

      getSurveySummary(
        "response-time",
        id.replaceAll("~", "%2F"),
        "",
        startDate.toString(),
        endDate.toString(),
        "",
        "",
        getCompleteFlag(),
        getQuestionIndex(),
        Intl.DateTimeFormat().resolvedOptions().timeZone.replace("/", "%2F")
      )
        .then((response) => {
          if (!response.error) {
            let data = response.response;
            data.sort(function (a, b) {
              return a.hourOfDay - b.hourOfDay;
            });
            data.map((ele, i) => {
              xData.push(`${ele.hourOfDay}`);
              yData.push(ele.count);
            });
            setChartData(xData, yData);
            dispatch(setShowCommonLoader(false));
          } else {
            Sentry.captureMessage("Error", response);
            dispatch(setShowCommonLoader(false));
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      console.log(error);
      dispatch(setShowCommonLoader(false));
    }
  };

  const getCompleteFlag = () => {
    switch (respondentsType) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return "";
    }
  };

  const getQuestionIndex = () => {
    switch (respondentsType) {
      case "":
        return "";
      case "true":
        return "";
      case "false":
        return "";
      default:
        return respondentsType;
    }
  };

  const setChartData = (xData, yData) => {
    const o = {
      tooltip: {
        trigger: "item",
        backgroundColor: "#13163E",
        borderColor: "#13163E",
        textStyle: { color: "#FFFFFF", fontSize: 12, lineHeight: 16 },
        formatter: (params, ticket, callback) => {
          let value = Number(params.name);
          if (value > 12) {
            return `${value - 12} pm <br /> ${params.value}`;
          }
          if (value < 12 && value != 0) {
            return `${value} am <br /> ${params.value}`;
          }
          if (value === 0) {
            return `12 am <br /> ${params.value}`;
          }
          if (value === 12) {
            return `12 pm <br /> ${params.value}`;
          }
        },
      },
      xAxis: {
        type: "category",
        data: xData,
        boundaryGap: false,
        axisLabel: {
          formatter: (index, value) => {
            if (value > 12) {
              return `${value - 12} pm`;
            }
            if (value < 12 && value != 0) {
              return `${value} am`;
            }
            if (value === 0) {
              return "12 am";
            }
            if (value === 12) {
              return "12 pm";
            }
          },
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: yData,
          type: "line",
          color: ["#05a6ce"],
        },
      ],
    };
    setOption(o);
  };

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setStartDate(sDate.getTime());
    setEndDate(eDate.getTime());
  };

  return (
    <div className="border rounded p-6 mt-6 w-[calc(50%-4px)]">
      <div className="flex justify-between">
        <div className="text-sm text-gray-700 font-semibold">Response time</div>
        <div className="h-[40px] w-[100px] py-3 px-5 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative">
          <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-3 justify-center pointer-events-none">
            <span className="cursor-pointer">Date</span>{" "}
            <img src={ChevronBottom} alt="" />
          </div>
          <div>
            <ConfigProvider
              theme={{
                token: {
                  colorIcon: "#98A2B3",
                  colorBorder: "#D0D5DD",
                  colorPrimary: "#008138",
                  colorPrimaryHover: "#008138",
                  colorText: "#344054",
                  colorTextHeading: "#344054",
                  colorTextDisabled: "#D0D5DD",
                  colorTextLightSolid: "var(--main-color)",
                  colorTextPlaceholder: "#98A2B3",
                  controlItemBgActive: "#DAEEE1",
                  controlOutline: "#FFFFFF",
                  colorBgTextActive: "FFFFFF",
                  colorTextLabel: "#FFFFFF",
                },
              }}
            >
              <RangePicker
                className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                onChange={onRangeSelectionChange}
                locale={locale}
                showNow={false}
                allowClear={false}
                cellRender={(current, info) => {
                  if (info.type !== "date") return info.originNode;
                  const style: React.CSSProperties = {};
                  style.borderRadius = "50%";
                  style.width = "30px";
                  style.height = "30px";
                  style.lineHeight = "30px";
                  return (
                    <div className="ant-picker-cell-inner" style={style}>
                      {current.date()}
                    </div>
                  );
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      {option && <ReactECharts style={{ height: "350px" }} option={option} />}
    </div>
  );
};

export default SurveyResponseTime;
