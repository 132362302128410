import { useEffect, useState } from "react";
import { openDropdown } from "../../../common/utils/UserInterfaceUtils";
import { postStepOne } from "../../../common/services/message-services";
import SelectTemplate from "../../../modals/templates/select-template";
import { uploadMedia } from "../../../modals/templates/api/media";
import {
  BgImagePlaceholder,
  CalendarIcon,
  DisplayPhoneNumber,
  ImagePlaceholder,
  LinkIcon,
  Phone,
  PhoneNumberId,
  Whatsapp,
  CrossSmall,
} from "../../../assets/icons/icons";
import { useDropzone } from "react-dropzone";
import "./step-two.css";
import { getAccounts } from "../../../common/services/message-services";
import { getCustomerId } from "../../../common/constants/billingConstants";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { Popup } from "semantic-ui-react";
import { ConfigProvider, DatePicker, TimePicker } from "antd";
import type { DatePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/en_GB";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { getTemplatesList } from "../../../modals/templates/api/templates";
import { processTemplatesList } from "../../../modals/templates/utils/templateLists";
import * as Sentry from "@sentry/react";
import ArrowRight from "../../../../../marketing-cloud-main/src/common/assets/icons/ArrowRight";

const StepTwo = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount]: any = useState();
  const [sendPeriod, setSendPeriod] = useState(true);
  const [templateParts, setTemplateParts] = useState([]);
  const [inputPositions, setInputPositions]: any = useState([]);
  const [mediaFile, setMediaFile] = useState("");
  const [messageName, setMessageName] = useState("");
  const [userId, setUserId] = useState("");
  const [tagVisible, setTagVisible] = useState(false);
  const [messageParams, setmessageParams] = useState([]);
  const [dropDownText, setDropDownText] = useState(
    "Select your WhatsApp Business Account"
  );

  const [text, setTemplateText] = useState({
    name: "Select template message",
    text: "",
    type: "TEXT",
    category: "",
  });
  const [previewText, setPreviewText] = useState();
  const [nameValidation, setNameValidation] = useState(false);
  const [accountValidation, setAccountValidation] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [scheduleError, setScheduleError] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [campaignTimeStamp, setCampaignTimeStamp] = useState(null);
  const [templatesList, setTemplatesList] = useState([]);
  const [templatesObj, setTemplatesObj]: any = useState({});
  const [dropdownStatus, setDropdownStatus] = useState(false);

  let defaultState = {
    id: userId,
    conversationId: "",
    name: messageName,
    channel: "WHATSAPP",
    content: {
      type: "TEMPLATE",
      templateInfo: {
        name: text.name,
        language: "en_US",
        category: "",
      },
      params: messageParams,
      media: {
        type: text.type,
        url: mediaFile || "",
      },
    },
    phonenumberId: selectedAccount?.phonenumberId,
    messageType: "PUSH",
    chatChannel: "484575796253066_917909199817",
    senderName: "Terragon",
    landingPagesURL: "",
    status: "DRAFT",
    objective: "",
    businessId: getCustomerId(),
    budget: 0,
    pricing: 0,
    totalPricing: 0,
    unitPrice: 0,
    profiles: 0,
    schedule: {
      immediate: "true",
      timeline: {
        start: "",
        end: "",
      },
    },
    audience: {
      segmentId: "",
      phoneNumbers: [],
    },
    timezone: "",
  };

  const [messageBody, setMessageBody] = useState(defaultState);

  let saveAsDraft = false;
  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);

  useEffect(() => {
    if (selectedAccount?.phonenumberId) {
      fetchTemplatesListToShow(selectedAccount?.phonenumberId);
    }
  }, [selectedAccount]);

  useEffect(() => {
    let messageBodyCopy = { ...messageBody };

    if (props.data?.name !== "") {
      setMessageName(props.data.name);
      messageBodyCopy.name = props.data.name;
    }
    if (props.data?.id !== "") {
      setUserId(props.data.id);
      messageBodyCopy.id = props.data.id;
    }

    props.data.startDate
      ? setStartDate(props.data.startDate)
      : setStartDate("");

    props.data.startTime
      ? setStartTime(props.data.startTime)
      : setStartTime("");

    if (props.data.startDate && props.data.startTime) {
      setCampaignTimeStamp(
        new Date(props.data.startDate + "T" + props.data.startTime)
      );
    }

    setSendPeriod(
      props.data?.schedule
        ? props.data?.schedule?.immediate
          ? true
          : false
        : true
    );

    if (props.data?.phonenumberId !== "")
      messageBodyCopy.phonenumberId = props.data.phonenumberId;
    if (props.data?.content?.params?.length > 0)
      messageBodyCopy.content.params = props.data.content.params;
    if (props.data?.content?.templateInfo?.name)
      messageBodyCopy.content.templateInfo.name =
        props.data.content.templateInfo.name;
    if (props.data?.content?.media?.type)
      messageBodyCopy.content.media.type = props.data.content.media.type;
    if (props.data?.content?.media?.url) {
      messageBodyCopy.content.media.url = props.data.content.media.url;
      setMediaFile(props.data.content.media.url);
    }
    if (props.data?.budget) {
      messageBodyCopy.budget = props.data?.budget;
    }
    if (props.data?.profiles) {
      messageBodyCopy.profiles = props.data?.profiles;
    }
    if (props.data?.pricing) {
      messageBodyCopy.pricing = props.data?.pricing;
    }
    if (props.data?.totalPricing) {
      messageBodyCopy.totalPricing = props.data?.totalPricing;
    }
    if (props.data?.unitPrice) {
      messageBodyCopy.unitPrice = props.data?.unitPrice;
    }
    if (props.data?.audience && props.data.audience.phoneNumbers) {
      messageBodyCopy.audience = { ...props.data?.audience };
    }
    if (props.data?.content?.templateInfo?.name)
      setTemplateText({
        name: props.data?.content?.templateInfo?.name,
        type: "",
        text: "",
        category: "",
      });

    fetchAccountsAndOverview();

    messageBodyCopy = { ...props.data };
    messageBodyCopy.audience =
      props.data?.audience && props.data.audience.phoneNumbers
        ? { ...props.data?.audience }
        : { segmentId: "", phoneNumbers: [] };

    setMessageBody(messageBodyCopy);
    const importTE = async () => {
      await import("tw-elements");
    };
    importTE();
  }, [props.data]);

  useEffect(() => {
    if (props?.data?.wabaId != "" && messageName) {
      let matchingIndex = -1;
      accounts.forEach((account, index) => {
        if (account.wabaId === props?.data?.wabaId) {
          matchingIndex = index;
        }
      });

      if (matchingIndex >= 0) {
        setDropDownText(accounts[matchingIndex]?.wabaName);
        setSelectedAccount({ ...accounts[matchingIndex] });
        setAccountValidation(false);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (props.data?.content?.templateInfo?.name && templatesList.length > 0) {
      let matchingTemplate = null;

      templatesList.forEach((template, index) => {
        if (template.name === props.data?.content?.templateInfo?.name) {
          matchingTemplate = template;
        }
      });

      if (matchingTemplate) {
        setTemplateText({ ...matchingTemplate });
        setBodyError(false);
        splitString(matchingTemplate.text);
      }
    }
  }, [templatesList.length]);

  var pusher = [];
  var files = "image/png, image/jpeg";
  const filesAccepted = (type: string) => {
    if (type === "IMAGE") {
      files = "image/png, image/jpeg";
    } else if (type === "VIDEO") {
      files = "video/mp4";
    } else if (type === "DOCUMENT") {
      files = "text/csv";
    }
  };

  const onStartDateChange = (
    value: DatePickerProps["value"],
    dateString: string
  ) => {
    setStartDate(dateString);
  };

  const onStartTimeChange = (time: Dayjs, timeString: string) => {
    setStartTime(time.format("HH:mm:ss").split(" ")[0]);
  };

  const disabledDates = (current) => {
    return current && current < dayjs().endOf("day").subtract(1, "day");
  };

  const onStartDateSave = () => {
    if (!startDate) {
      setScheduleError("Please pick a date and time");
      return;
    }
    if (!startTime) {
      setScheduleError("Please pick a date and time");
      return;
    }

    const campaignStartDate = new Date(startDate + "T" + startTime);
    if (campaignStartDate.getTime() < new Date().getTime()) {
      setScheduleError("Please pick a date and time");
      return;
    }
    setCampaignTimeStamp(campaignStartDate);
    setShowCalendar(false);
    setScheduleError("");
  };

  const continueToNext = () => {
    if (messageName === "") {
      setNameValidation(true);
      return;
    }

    if (!selectedAccount) {
      setAccountValidation(true);
      return;
    }

    if (!sendPeriod && !campaignTimeStamp) {
      setScheduleError("Please pick a date and time");
      return;
    }

    if (text.text === "") {
      setBodyError(true);
      return;
    }

    submitDraft();
  };

  const fetchAccountsAndOverview = () => {
    // try {
    //   getAccountsAndOverview()
    //     .then((res) => {
    //       const { error, response = [] } = res;
    //       setAccounts(response.data);
    //     })
    //     .catch((err) => {

    //     });
    // } catch (err) {

    // }

    try {
      getAccounts()
        .then((res) => {
          const { error, response = [] } = res;
          if (!error) {
            setAccounts(response);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const fetchTemplatesListToShow = (phonenumberId) => {
    try {
      setLoading(true);
      getTemplatesList(phonenumberId)
        .then((res) => {
          const { error, response = [] } = res;
          if (!error || error === "false") {
            const filteredTemplatesObj = processTemplatesList(
              response.templates
            );
            setTemplatesObj(filteredTemplatesObj);
            setTemplatesList(filteredTemplatesObj["ALL"]);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
          setLoading(false);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
    }
  };

  const handleDrop = (acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      if (files.includes(file.type)) {
        try {
          uploadMedia(file, text.type)
            .then((res) => {
              const { error, response = [] } = res;
              if (!error || error === "false") {
                Notify({
                  type: "success",
                  heading: "Media Upload Status",
                  message: `Media Uploaded Successfully`,
                });
                setMediaFile(response.url);
              } else {
                error.response
                  ? Sentry.captureMessage("Error", error.response)
                  : Sentry.captureMessage("Error", error);
                console.log(error);
              }
            })
            .catch((err) => {
              err.response
                ? Sentry.captureMessage("Error", err.response)
                : Sentry.captureMessage("Error", err);
              console.log(err);
            });
        } catch (err) {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        }
      } else {
        alert("Please upload correct file");
      }
    });
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      files: [],
    },
    onDrop: handleDrop,
  });

  const getAccount = (account) => {
    setSelectedAccount(account);
    setAccountValidation(false);
    setDropDownText(account.wabaName);
    setTemplateText({
      name: "",
      type: "",
      text: "",
      category: "",
    });
    setTemplateParts([]);
    templateEdit("");
  };

  const splitString = (templateText = "") => {
    // setTemplateParts([]);
    const selectionText = templateText;
    let updatedTemplateParts: any = [templateParts];
    const updatedPositions: any = [...inputPositions];
    if (updatedTemplateParts[0].length > 0) {
      updatedTemplateParts = updatedTemplateParts.slice(1);
    }

    const pattern = /{{([1-9]\d*)}}/g; // Use regular expression to match intervals in curly braces with comma separated numbers
    var updatedIntervals: any = selectionText.match(pattern);

    let start = 0;
    updatedIntervals?.forEach((interval: any, index: any) => {
      const interval_start = selectionText.indexOf(interval, start);
      const interval_end = interval_start + interval.length;
      const nums = interval.substring(1, interval.length - 1).split(",");
      updatedTemplateParts.push(selectionText.substring(start, interval_start));
      nums.forEach((num: any) => {
        updatedPositions.push(updatedTemplateParts.length);
        updatedTemplateParts.push(`{${num}}`);
      });
      start = interval_end;
    });
    updatedTemplateParts.push(selectionText.substring(start));
    setPreviewText(updatedTemplateParts);
    setTemplateParts(updatedTemplateParts);
    setInputPositions(updatedPositions);
  };

  const handleInputChange = (index: number, event: any) => {
    let values: any = [...templateParts];
    values[index] = event.target.value;
    setTemplateParts(values);
  };

  const handleChange = (event) => {
    setMessageName(event.target.value);
    setMessageBody({ ...messageBody, name: event.target.value });
    setNameValidation(false);
  };

  const setTheText = (e) => {
    setTemplateText({ name: "", type: "", text: "", category: "" });
    setTemplateText((prevState) => {
      return { name: "", type: "", text: "", category: "" };
    });

    setTemplateText(e);
  };

  const templateEdit = (text = "", i = 0) => {
    if (!inputPositions.includes(i)) {
      return <span key={i}>{text}</span>;
    } else {
      var replaced = text.replace(/{{|}}/g, "");
      pusher.push(replaced);
      return (
        <input
          type="text"
          className="min-w-[40px]"
          onChange={(event) => handleInputChange(i, event)}
          key={i}
          contentEditable={true}
          value={text}
        />
      );
    }
  };

  const submitDraft = () => {
    const msgBodyCopy = { ...messageBody };
    let schedule;
    if (sendPeriod) {
      schedule = {
        immediate: true,
        timeline: {
          start: "",
          end: "",
        },
      };
    } else {
      schedule = {
        immediate: false,
        timeline: {
          start: campaignTimeStamp.getTime(),
        },
      };
    }
    if (startDate && !sendPeriod) msgBodyCopy["startDate"] = startDate;
    if (startTime && !sendPeriod) msgBodyCopy["startTime"] = startTime;
    msgBodyCopy.content.params = pusher;
    msgBodyCopy.schedule = schedule;
    msgBodyCopy.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    msgBodyCopy.id = props.data?.id;
    msgBodyCopy.phonenumberId = selectedAccount?.phonenumberId;
    msgBodyCopy.content = {
      type: "TEMPLATE",
      templateInfo: {
        name: text.name,
        language: "en_US",
        category: "",
      },
      params: pusher,
      media: {
        type: text.type || "text",
        url: mediaFile || "",
      },
    };
    try {
      setLoading(true);
      postStepOne(msgBodyCopy, props.data?.id)
        .then((res) => {
          setLoading(false);
          const { error, response = [] } = res;
          if (res.error === false) {
            const messageBodyCopy = res.response;
            messageBodyCopy["content"]["text"] = text.text;
            messageBodyCopy.content.templateInfo.category = text.category;
            setMessageBody(messageBodyCopy);
            const data: any = messageBodyCopy;
            props.setData(data);
            saveAsDraft ? props.navigate("/engagements") : props.setStep(3);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          setLoading(false);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      setLoading(false);
    }
  };

  return (
    <div className="py-8 px-10">
      <div className="text-gray-500 font-semibold text-sm leading-6">
        STEP 2 OF 3
      </div>
      <div className="flex justify-between w-full mt-3">
        <div className="flex-wrap justify-start items-start">
          <div className="flex gap-3 items-center">
            <div className="h-12 w-12 flex justify-center items-center flex-shrink-0 bg-gray-100 rounded-full">
              <img src={Whatsapp} alt="" />
            </div>
            <div className="text-left">
              <span className="text-gray-70 font-semibold text-base">
                Message details
              </span>
              <p className=" text-gray-500 font-normal text-sm whitespace-no-wrap">
                Let’s help create your first campaign.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2 items-center">
          <button
            onClick={(event) => {
              props.setStep(1);
            }}
            className="border border-button-border rounded-md py-2 px-5 text-slate-600 font-semibold text-sm leading-6"
          >
            Back
          </button>
          <button
            disabled={loading}
            onClick={(event) => continueToNext()}
            className="border gap-2 bg-blue-75 flex items-center disabled:bg-blue-75 rounded-md py-2 px-5 font-semibold text-sm text-white hover:text-white hover:bg-green-75 leading-6"
          >
            <div>Next</div>
            <ArrowRight />
          </button>
        </div>
      </div>
      <div className="mt-8 flex gap-20">
        <div className="w-[65%]">
          <div className="flex items-center gap-8">
            <div className=" w-[50%]">
              <div className="text-gray-70 font-semibold text-sm leading-6">
                {" "}
                Message name{" "}
              </div>
              <input
                type="text"
                id="message"
                name="message"
                value={messageName}
                onChange={handleChange}
                className={`${
                  nameValidation
                    ? "border-red-400 rounded"
                    : "border-gray-40 rounded"
                } border w-full py-4 px-4 focus:outline-none h-[40px] text-sm leading-5 mt-1 rounded`}
                placeholder="Enter the name of your message"
              ></input>

              {nameValidation ? (
                <div className="text-red-400 py-2">
                  This field must be filled to continue.
                </div>
              ) : null}
            </div>
            <div className="w-[50%]">
              <div className="text-gray-70 font-semibold text-sm leading-6">
                {" "}
                WhatsApp Business Account{" "}
              </div>
              <div
                className={`${
                  accountValidation
                    ? "border-red-400 rounded"
                    : "border-gray-40 rounder"
                } border w-full py-2 focus:outline-none inline-flex flex-wrap h-[40px] text-sm leading-5 px-2 relative mt-1 rounded`}
              >
                <button
                  className="w-full active:bg-gray-50 flex justify-between items-center  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setDropdownStatus((status) => !status)}
                >
                  <span className="text-gray-400 text-sm leading-5 font-normal">
                    {dropDownText}
                  </span>
                  <svg
                    className="justify-end items-end fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                  </svg>
                </button>
                {dropdownStatus && (
                  <div className="absolute top-[42px] left-0 rounded bg-white shadow-md w-full">
                    {accounts &&
                      accounts.length > 0 &&
                      accounts.map((account, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              setDropdownStatus(false);
                            }}
                          >
                            <AccountDropdown accountItem={account} />
                          </div>
                        );
                      })}
                    {(!accounts || accounts.length === 0) && (
                      <div className="h-[40px] flex items-center justify-center text-sm leading-6 text-gray-400 shadow-md w-full cursor-default">
                        No accounts found
                      </div>
                    )}
                  </div>
                )}
              </div>
              {accountValidation ? (
                <div className="text-red-400 py-2">
                  Select an account to continue.
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-6">
            <div className="flex justify-between">
              <span className="text-gray-70 font-semibold text-sm leading-6">
                {" "}
                Message body{" "}
              </span>
              <a
                className="text-[#039855] text-sm font-semibold leading-6"
                href={"/settings?tab=Engagement"}
              >
                {" "}
                + Add new template{" "}
              </a>
            </div>
            <div className="mt-1">
              <div
                className={
                  bodyError
                    ? "border w-full border-red-400 rounded-b-none flex justify-between"
                    : "border w-full border- gray-40 rounded-b-none flex justify-between"
                }
              >
                <span className="border-r text-[#98A2B3] font-normal text-sm border- gray-40 py-4 px-6 w-[75%]">
                  {text.name}
                </span>
                <span
                  onClick={() => {
                    selectedAccount
                      ? setShowModal(true)
                      : setAccountValidation(true);
                  }}
                  className=" text-gray-800 font-normal text-sm py-4 px-6 w-[25%] cursor-pointer"
                >
                  {" "}
                  Choose template{" "}
                </span>
                {showModal && (
                  <SelectTemplate
                    channelId={selectedAccount?.phonenumberId}
                    showModal={showModal}
                    setShowModal={() => {
                      setShowModal(false);
                    }}
                    setText={(e: any) => {
                      setTheText(e);
                      setBodyError(false);
                      splitString(e.text);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    templatesList={templatesList}
                    setTemplatesList={setTemplatesList}
                    templatesObj={templatesObj}
                    setTemplatesObj={setTemplatesObj}
                  />
                )}
              </div>
              <div className="border w-full border- gray-40 rounded-t-none rounded-b-rounded h-[201px] bg-white">
                <div className="w-full h-full focus:outline-none px-4 py-4 bg-white">
                  {templateParts && templateParts.length
                    ? templateParts.map((text, i) => {
                        return templateEdit(text, i);
                      })
                    : null}
                </div>
              </div>
              {filesAccepted(text.type)}
              {text.type && text.type !== "TEXT" && (
                <div
                  {...getRootProps({
                    className: "dropzone",
                  })}
                  className="dropzone border w-full border- gray-40 rounded h-[150px] bg-white"
                >
                  <div
                    className={`rounded-md  w-[100px] h-[60px] mx-4 my-5 ${
                      !mediaFile ? "bg- gray-40" : ""
                    }`}
                  >
                    <input {...getInputProps()} />
                    {mediaFile ? (
                      <div className="image-container h-[100px]">
                        {text.type === "IMAGE" && (
                          <img
                            src={mediaFile}
                            className="h-full"
                            alt="Preview"
                          />
                        )}
                        {text.type === "VIDEO" && <video src={mediaFile} />}
                        {text.type === "DOCUMENT" && (
                          <div>
                            {acceptedFiles &&
                              acceptedFiles.length &&
                              acceptedFiles.map((file) => {
                                return <div>{file.name}</div>;
                              })}
                          </div>
                        )}

                        <span
                          onClick={() => {
                            setMediaFile("");
                          }}
                        >
                          <img src={CrossSmall} alt="" />
                        </span>
                      </div>
                    ) : (
                      <img src={ImagePlaceholder} alt="" />
                    )}
                  </div>
                </div>
              )}
              {/* { text.type === 'IMAGE' &&
                    (
                      <div className="border w-full border- gray-40 rounded h-[64px] bg-white flex">
                      <div className="flex items-center justify-center px-3">
                        <button onClick={() => setShowTemplateEdit(true)}  className="min-w-[110px] h-[32px] border border-[#D8E0E7] rounded-lg text-xs font-semibold text-[#667085] text-center py-1 px-2">
                        Take survey
                        </button>
                      </div>
                      { showTemplateEdit &&
                      (
                        <TemplateEditButton />
                      )}
                      <div onClick={() => setShowTemplateEdit(true)} className="flex items-center cursor-pointer justify-center px-3">
                        <Edit />
                      </div>

                      <div onClick={() => setTagVisible(!tagVisible)} className="flex items-center justify-center px-3">
                        <Visibility />
                      </div>

                    </div>
                  )} */}
              {bodyError ? (
                <div className="text-red-400 py-2">
                  Choose a template to continue.
                </div>
              ) : null}

              <div className="w-full mt-8">
                <p className="text-gray-70 font-semibold text-sm leading-6">
                  {" "}
                  Schedule{" "}
                </p>
                <div className="flex gap-6">
                  <div className="w-[50%]">
                    <div className="border w-full border- gray-40 rounded  focus:ring-2 focus:ring-gray-400 focus:outline-none focus:border-gray-400 relative inline-flex flex-wrap h-[40px]">
                      <button
                        className="px-4 w-full active:bg-gray-50 py-2 flex justify-between items-center  ease-linear transition-all duration-150 text-sm"
                        type="button"
                        onClick={() => openDropdown(event, "schedule")}
                      >
                        <span className="text-gray-400 text-sm font-normal">
                          {sendPeriod ? "Send Now" : "Send Later"}
                        </span>
                        <svg
                          className="justify-end items-end fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                        </svg>
                      </button>
                      <div onClick={() => openDropdown(event, "schedule")}>
                        <button
                          className="hidden bg-white  text-base z-50 w-full float-left py-2 list-none text-left rounded shadow-lg mt-1"
                          id="schedule"
                        >
                          <div
                            onClick={() => setSendPeriod(true)}
                            className="px-4 py-2 w-full"
                          >
                            <span className="flex">
                              <span className=" font-medium text-sm text-gray-700 py-1 px-2">
                                Send Now{" "}
                              </span>
                            </span>
                          </div>
                          <div
                            onClick={() => setSendPeriod(false)}
                            className="px-4 py-2 w-full"
                          >
                            <span className="flex">
                              <span className=" font-medium text-sm text-gray-700 py-1 px-2">
                                Send Later{" "}
                              </span>
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  {sendPeriod === false && (
                    <ConfigProvider
                      theme={{
                        token: {
                          colorIcon: "#98A2B3",
                          colorBorder: "#D0D5DD",
                          colorPrimary: "#008138",
                          colorPrimaryHover: "#008138",
                          colorText: "#344054",
                          colorTextHeading: "#344054",
                          colorTextDisabled: "#D0D5DD",
                          colorTextLightSolid: "var(--main-color)",
                          colorTextPlaceholder: "#98A2B3",
                          controlItemBgActive: "#DAEEE1",
                          controlOutline: "#FFFFFF",
                        },
                      }}
                    >
                      <div className="w-[50%]">
                        <div
                          onClick={() => {
                            if (!showCalendar) {
                              setShowCalendar(true);
                            }
                          }}
                        >
                          <DatePicker
                            className={`date-picker-fontisize w-full h-[40px] px-4 border-gray-40 text-sm rounded ${
                              scheduleError && "border-red-500"
                            }`}
                            disabled={loading}
                            value={
                              startDate
                                ? dayjs(startDate, "YYYY-MM-DD")
                                : undefined
                            }
                            defaultValue={
                              startDate
                                ? dayjs(startDate, "YYYY-MM-DD")
                                : undefined
                            }
                            format="YYYY-MM-DD"
                            onChange={onStartDateChange}
                            disabledDate={disabledDates}
                            locale={locale}
                            open={showCalendar}
                            showNow={false}
                            showToday={false}
                            suffixIcon={<img src={CalendarIcon} />}
                            allowClear={false}
                            popupStyle={{ paddingBottom: "64px" }}
                            cellRender={(current) => {
                              const style: React.CSSProperties = {};
                              style.borderRadius = "50%";
                              style.width = "30px";
                              style.height = "30px";
                              style.lineHeight = "30px";
                              style.fontSize = "14px";
                              return (
                                <div
                                  className="ant-picker-cell-inner text-sm"
                                  style={style}
                                >
                                  {current.date()}
                                </div>
                              );
                            }}
                            renderExtraFooter={() => {
                              return (
                                <>
                                  <div className="flex justify-between items-end px-5">
                                    <input
                                      type="text"
                                      placeholder="Pick a start date"
                                      value={
                                        startDate &&
                                        dayjs(startDate).format("MMM D, YYYY")
                                      }
                                      className={`w-36 h-12 px-2 py-3 mt-3 text-sm border border- gray-40 rounded-md focus:outline-none `}
                                      onChange={() => {}}
                                    />
                                    <TimePicker
                                      className={`h-12`}
                                      defaultValue={
                                        startTime
                                          ? dayjs(startTime, "HH:mm:ss")
                                          : undefined
                                      }
                                      format="h:mm A"
                                      onChange={onStartTimeChange}
                                      use12Hours
                                      size="small"
                                      showNow={false}
                                      placement="bottomRight"
                                      inputReadOnly={true}
                                    />
                                  </div>
                                  <div className="pt-4 mt-80 bg-white rounded-b-lg drop-shadow-lg">
                                    <hr className="border- gray-40" />
                                    <div className="flex gap-4 p-4 rounded-b-lg">
                                      <button
                                        className="py-3 px-10 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                                        onClick={() => setShowCalendar(false)}
                                      >
                                        Cancel
                                      </button>

                                      <button
                                        className="py-3 px-14 text-white text-sm font-semibold border rounded-lg bg-blue-75 hover:bg-green-75"
                                        onClick={() => {
                                          onStartDateSave();
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div>
                        {scheduleError && (
                          <div className="text-red-500 text-sm mt-2">
                            {scheduleError}
                          </div>
                        )}
                      </div>
                    </ConfigProvider>
                  )}
                </div>
              </div>
            </div>
            <p className="text-[#475467] font-normal text-sm py-2">
              {" "}
              Message is sent immediately after review{" "}
            </p>
          </div>
        </div>
        <div className="w-[35%] border border-summary-box-border rounded bg-summary-box-bg py-6 px-8 h-[500px] overflow-hidden">
          <div className="text-gray-700 text-base font-semibold leading-6">
            Preview
          </div>
          <div className="border-b border-summary-box-border mt-2"></div>
          <div className="relative">
            <div className="absolute top-[40px] right-0 left-0 px-10 pt-28 overflow-y-scroll max-h-[420px] no-scrollbar z-10">
              <div className="flex w-full items-center justify-center">
                <span className="text-[7px] text-[#414350] italic font-bold px-3 py-0 mb-3 bg-[#F6F6F6] rounded-full">
                  Today
                </span>
              </div>
              {text.type === "IMAGE" && (
                <div className="">
                  <div className="rounded-md bg- gray-40 w-[179px] h-[139px]">
                    {mediaFile ? (
                      <div className="w-[179px] h-[139px]">
                        <img className="h-full" src={mediaFile} alt="Preview" />
                      </div>
                    ) : (
                      <img src={BgImagePlaceholder} alt="" />
                    )}
                  </div>
                </div>
              )}
              <div className="rounded-md bg-[#F6F6F6] items-left w-[184px]">
                <p className="px-2 py-2 text-black font-normal text-[10px]">
                  {previewText}
                </p>
                <span className="flex justify-end items-end text-[7px] font-medium text- gray-40 px-2">
                  {" "}
                  7:41pm{" "}
                </span>
              </div>
              {tagVisible && (
                <div className="border border- gray-40 rounded-md bg-[#F6F6F6] items-center justify-center w-[184px] flex">
                  <div className="flex p-2">
                    <img src={LinkIcon} alt="" />{" "}
                    <span className="px-1 text-[#007AFF] font-medium text-[10.2px] mb-2">
                      {" "}
                      Take survey{" "}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="absolute w-full flex justify-center top-[42px]">
              <img src={Phone} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function AccountDropdown({ accountItem }) {
    return (
      <div onClick={() => getAccount(accountItem)}>
        <div className="px-3 py-2 w-full cursor-pointer">
          <span className="flex items-center gap-2">
            <img src={Whatsapp} alt="" />
            <span className="font-medium text-sm text-gray-700">
              {" "}
              {accountItem?.wabaName}{" "}
            </span>
          </span>
          <div className="flex items-center justify-between mt-2">
            <Popup
              content={<div>Phone number</div>}
              position="right center"
              trigger={
                <div className="flex items-center gap-1 text-sm text-[#667085]">
                  <img src={DisplayPhoneNumber} alt="" />
                  {accountItem.displayPhonenumber}
                </div>
              }
              inverted
            />
            <Popup
              content={<div>Phone number Id</div>}
              position="right center"
              trigger={
                <div className="flex items-center gap-1 text-sm text-[#667085]">
                  <img src={PhoneNumberId} alt="" /> {accountItem.phonenumberId}
                </div>
              }
              inverted
            />
          </div>
        </div>
      </div>
    );
  }
};

export default StepTwo;
