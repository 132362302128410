import { useCallback, useEffect, useState } from "react";
import { Search } from "react-feather";
import { debounce } from "../../../../../engagements/src/common/utils/debounce";
import Icons from "./icons";
import {
  getDlr,
  getMessageDetails,
} from "../../../../../engagements/src/common/services/message-services";
import { useDispatch } from "react-redux";
import { setShowCommonLoader } from "../../../redux-loader/loader-slice/loaderSlice";
import Dropdown from "../../settingsComponents/Engagements/whatsappTemplateTab/dropdown-component/dropdownComponent";
import PaginationComp from "../../../../../engagements/src/common/components/pagination";

const MessageReport = (props) => {
  let { dateRange = {}, selectedType } = props;
  const [audienceList, setAudienceList] = useState({
    data: [],
    totalDlrs: 0,
    audienceSource: "",
  });
  const [phoneNumberId, setPhoneNumberId] = useState("");
  const [status, setStatus] = useState("");
  const [resetPagination, setResetPagination] = useState(false);
  const [totalListLength, setTotalListLength] = useState(0);

  const dispatch = useDispatch();

  const phoneNumberIdOnChange = (value) => {
    setPhoneNumberId(value);
  };

  const handlePhoneNumberChange = useCallback(
    debounce(phoneNumberIdOnChange),
    []
  );

  useEffect(() => {
    setResetPagination(true);
    getReport(0, 10);
  }, [dateRange, status, phoneNumberId, selectedType]);

  const getReport = (from, size) => {
    const params = {
      from: from,
      pageSize: size,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      status,
      phoneNumberId,
      channel: `sms-${selectedType?.toLowerCase()}`,
    };
    dispatch(setShowCommonLoader(true));
    try {
      getDlr(params)
        .then((res: any) => {
          const response = res.data.response;
          if (!res.error) {
            setTotalListLength(response?.totalDlrs);
            setAudienceList(response);
            dispatch(setShowCommonLoader(false));
          } else {
            // Sentry.captureMessage("Error", res);
          }
        })
        .catch((err) => {
          //   err.response
          //     ? Sentry.captureMessage("Error", err.response)
          //     : Sentry.captureMessage("Error", err);
          //   console.log(err);
          //   dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      //   err.response
      //     ? Sentry.captureMessage("Error", err.response)
      //     : Sentry.captureMessage("Error", err);
      //   console.log(err);
      //   dispatch(setShowCommonLoader(false));
    }
  };

  let statusOptions = [
    "Delivered",
    "Read",
    "Undelivered",
    "Sent",
    "DND",
    "Unsent",
  ].map((el) => {
    return {
      label: (
        <div className="flex gap-3 items-center">
          <input
            type="checkbox"
            checked={status === el.toLowerCase()}
            className="w-4 h-4 accent-blue-75 bg-gray-100 border-gray-300 rounded cursor-pointer"
          ></input>
          <div>{el}</div>
        </div>
      ),
      value: el.toLowerCase(),
    };
  });

  let icon;

  const colorCodes = (status) => {
    switch (status?.toLowerCase()) {
      case "read":
        icon = <Icons type={"eye"} />;
        return "bg-green-5 text-primary-green";
      case "opt-out":
        icon = <Icons type={"optout"} />;
        return "bg-error-50 text-error-700";
      case "sent":
        icon = <Icons type={"oneTick"} />;
        return "bg-green-60 text-green-710";
      case "delivered":
        icon = <Icons type={"tick"} />;
        return "bg-gray-711 text-gray-710";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };
  const dateTimeFormatter = (timestamp) => {
    if (!timestamp) return "";
    if (timestamp.toString().length === 10) {
      timestamp *= 1000;
    }
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedDate = `${date.getFullYear()}-${
      month < 10 ? "0" + month : month
    }-${day < 10 ? "0" + day : day}, ${
      hours > 12
        ? hours - 12 < 10
          ? "0" + (hours - 12)
          : hours - 12
        : hours < 10
        ? "0" + hours
        : hours
    }:${minutes < 10 ? "0" + minutes : minutes} ${hours > 11 ? "PM" : "AM"}`;
    //console.log(date.toLocaleString("en-US", options), formattedDate);
    return formattedDate;
  };

  return (
    <div className="mt-10">
      <div className="border-b border-[ #EBEFF3] ">
        <div className="text-sm font-semibold leading-6">Message log</div>
      </div>
      <div className="pt-7 flex justify-between mr-8">
        {audienceList?.audienceSource === "csv" ? (
          <div>
            <div className="flex border border-gray-50 rounded-lg ">
              <div className="w-10 h-10 text-gray-400">
                <Search className="text-xl m-3" size="16" />
              </div>
              <input
                type="text"
                placeholder="Search MSISDNs"
                className="w-full h-10 pt-0.5 pr-28 mr-2 focus:border-none font-normal focus:outline-none "
                onChange={(event) =>
                  handlePhoneNumberChange(event.target.value)
                }
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <Dropdown
          options={statusOptions}
          placeholder={"Status"}
          value={status}
          handleChange={(value) => setStatus(value)}
          className={`w-[130px] rounded-full px-5 py-3 text-sm font-semibold text-gray-700 bg-gray-10`}
        />
      </div>
      <div className="mt-10 relative">
        <div className="min-w-full">
          <div className="flex justify-evenly  border-b border-gray-100">
            {["MSISDN", "Sent time", "Delivery time"].map((item, index) => (
              <div
                key={index}
                className="w-full flex justify-start text-sm text-gray-710 leading-6 font-semibold py-4 px-2"
              >
                {item}
              </div>
            ))}
            <div className="w-full flex justify-center text-sm text-gray-710 leading-6 font-semibold py-4 px-2">
              Status
            </div>
          </div>
          {audienceList?.data.length !== 0 && (
            <div>
              {audienceList?.data?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-evenly  border-b border-gray-100 text-slate-600"
                  >
                    <div
                      className={`${
                        audienceList?.audienceSource === "csv" ? "" : "blur-sm"
                      } text-sm leading-6 w-full flex justify-start items-center py-5 px-2`}
                    >
                      {audienceList?.audienceSource === "csv"
                        ? `${item.msisdn}`
                        : "**************"}
                    </div>
                    <div className="text-sm leading-6 w-full flex justify-start items-center py-5 px-2">
                      <div>{dateTimeFormatter(item.sentTime)}</div>
                    </div>
                    <div className="text-sm leading-6 w-full flex justify-start items-center py-5 px-2">
                      <div>
                        {item.delivertTime
                          ? dateTimeFormatter(item.delivertTime)
                          : "-"}
                      </div>
                    </div>
                    <div className="w-full flex items-center justify-center py-5 px-2">
                      <div
                        className={`${colorCodes(
                          item.status
                        )} font-medium text-xs leading-5 py-0.5 px-2 rounded-2xl flex items-center justify-center gap-1`}
                      >
                        <div>{icon}</div>
                        <div className="lowercase first-letter:capitalize">
                          {item.status ? item.status : "Pending"}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="mt-8">
                <PaginationComp
                  onPageChange={(from, size) => {
                    if (resetPagination) setResetPagination(false);
                    getReport(from, size);
                  }}
                  reset={resetPagination}
                  totalCount={totalListLength}
                />
              </div>
            </div>
          )}
        </div>
        {audienceList?.data.length === 0 && (
          <div className="w-full py-10">
            <div className="w-full bg-gray-25 text-sm text-gray-90 p-6 border border-gray-50 rounded-lg flex items-center justify-center">
              <div>No Data available</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageReport;
