import { useEffect, useState } from "react";
import SurveyList from "../survey-list";
import { getSurveyList } from "../../common/services/survey-services";
import * as Sentry from "@sentry/react";
import { pageLoadEvent } from "../../../../marketing-cloud-container/src/ga-events";
import { MESSAGE } from "../../../../marketing-cloud-main/src/common/constants/AccessControlMessage";
import { SurveyMix } from "../../assets/icons/icons";

const EngagementsSurveys = (props: any) => {
  const [surveyList, setSurveyList] = useState([]);
  const [totalSurveyListLength, setTotalSurveyListLength] = useState(0);
  const [filter, setFilter] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchSurveys, setSearchSurveys] = useState("");
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [channel, setChannel] = useState("");
  const [audience, setAudience] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { dispatch, setShowCommonLoader, hasSuperAdminAccess, tenantContext } =
    props;

  useEffect(() => {
    setFrom(Number(size * pageNumber));
    const params = {
      startDate,
      endDate,
      from,
      size,
      channel,
      audience,
      status,
      searchSurveys,
    };
    fetchSurveyList(params);
    pageLoadEvent("engagements-surveys_page");
  }, [
    startDate,
    endDate,
    size,
    searchSurveys,
    channel,
    audience,
    status,
    from,
    pageNumber,
  ]);

  const fetchSurveyList = (params = {}) => {
    dispatch(setShowCommonLoader(true));

    try {
      getSurveyList(params)
        .then((res) => {
          const { error, response } = res;
          if (!error) {
            setSurveyList(response);
            setTotalSurveyListLength(res.total);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
          dispatch(setShowCommonLoader(false));
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      dispatch(setShowCommonLoader(false));
      console.log(err);
    }
  };

  const openTgSurvey = () => {
    window.open("https://terragongroup.com/terragon-survey/", "_blank");
  };

  const navigateToSurvey = () => {
    props.navigate("/engagements/create-survey");
  };

  return (
    <>
      {!filter && pageNumber === 0 && surveyList?.length === 0 ? (
        <div className="mt-48 px-16 ">
          <div className="grid grid-cols-2 gap-6 justify-between grid-flow-row grid-">
            <div className="py-12 px-20 leading-10">
              <span className="w-[411px] text-gray-800 font-semibold text-[36px] m-0  ">
                Create your first survey.
              </span>
              <p className=" max-w-[300px] text-gray-800 font-normal text-sm py-4 ">
                Gain valuable insights by collecting feedback from your
                customers.
              </p>
              <div className="flex flex-row gap-4">
                <a>
                  <div className="group">
                    <button
                      onClick={navigateToSurvey}
                      className={`py-3 px-6 text-white text-sm font-semibold border rounded-lg bg-blue-75  ${
                        tenantContext.productList["create_survey_policy"] &&
                        "hover:bg-green-75"
                      } relative`}
                      disabled={
                        !tenantContext.productList["create_survey_policy"]
                      }
                    >
                      Create survey
                      {!tenantContext.productList["create_survey_policy"] && (
                        <div className="absolute hidden group-hover:flex bg-black p-3 text-xs rounded-lg w-[300px] right-[-306px] top-[-12px] z-20">
                          {MESSAGE}
                        </div>
                      )}
                    </button>
                  </div>
                </a>
                <button
                  className="py-3 px-6 text-gray-90 text-sm font-semibold border rounded-lg bg-white border-gray-50 hover:border-gray-500"
                  onClick={openTgSurvey}
                >
                  Learn more
                </button>
              </div>
            </div>
            <img src={SurveyMix} alt="" />
          </div>
        </div>
      ) : (
        <div>
          <SurveyList
            navigateToSurvey={navigateToSurvey}
            surveyList={surveyList}
            totalSurveyListLength={totalSurveyListLength}
            navigate={props.navigate}
            setFilter={setFilter}
            filter={filter}
            setSearchSurveys={setSearchSurveys}
            searchSurveys={searchSurveys}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            size={size}
            setSize={setSize}
            setFrom={setFrom}
            channel={channel}
            setChannel={setChannel}
            audience={audience}
            setAudience={setAudience}
            status={status}
            setStatus={setStatus}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            hasSuperAdminAccess={hasSuperAdminAccess}
            tenantContext={tenantContext}
          />
        </div>
      )}
    </>
  );
};

export default EngagementsSurveys;
