import { useEffect, useMemo, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import ReactECharts from "echarts-for-react";
import { Search } from "react-feather";
import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/en_GB";
import {
  getEmailDetails,
  getEmailDlrs,
  getEmailStats,
  getEmailSummary,
} from "../../common/services/email-services";
import CurrencySymbol from "../../../../marketing-cloud-main/src/common/components/currency-symbol/currency-symbol";
import { Notify } from "../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import {
  colorCode,
  formatNumbers,
} from "../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import ChartEmptyView from "../../common/components/chart-empty-view/chart-empty-view";
import PaginationComp from "../../common/components/pagination";
import {
  Bounced,
  Cash,
  Clock,
  DownArrow,
  EmailInsight,
  Eye,
  Fisend,
  InfoCircle,
  Message,
  OneTick,
  Profiles,
  Tick,
} from "../../assets/icons/icons";

const EmailReports = ({ dispatch, setShowCommonLoader }) => {
  const [emailDetails, setEmailDetails] = useState(null);
  const [emailStats, setEmailStats] = useState(null);
  const [engagementSummary, setEngagementSummary] = useState(null);
  const [options, setOptions] = useState(null);
  const [emailDlrs, setEmailDlrs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [resetPagination, setResetPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [dateRange, setDateRange] = useState(null);
  const [statusFilter, setStatusFilter] = useState("Status");
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [size, setSize] = useState(10);
  const statusMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      statusMenu.current &&
      showStatusMenu &&
      !statusMenu.current.contains(e.target)
    ) {
      setShowStatusMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  useEffect(() => {
    fetchEmailDetails();
    fetchEmailSummary();
    fetchEmailStatistics();
    fetchEmailDlrs();
  }, []);

  useEffect(() => {
    setResetPagination(true);
  }, [searchValue]);

  const { RangePicker } = DatePicker;

  const fetchEmailDetails = () => {
    let path = location.pathname.split("/");
    let id = path[path.length - 1];
    dispatch(setShowCommonLoader(true));

    try {
      getEmailDetails(id)
        .then((res) => {
          const { error, response = {} } = res;
          if (!error || error === false) {
            setEmailDetails(response);
            dispatch(setShowCommonLoader(false));
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
            Notify({
              type: "error",
              heading: "Failed to retrieve email details.",
              message: error,
            });
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          Notify({
            type: "error",
            heading: "Failed to retrieve email details.",
            message: "Failed to retrieve email details.",
          });
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      Notify({
        type: "error",
        heading: "Failed to retrieve email details.",
        message: "Failed to retrieve email details.",
      });
      dispatch(setShowCommonLoader(false));
    }
  };

  const fetchEmailStatistics = () => {
    let path = location.pathname.split("/");
    let id = path[path.length - 1];
    dispatch(setShowCommonLoader(true));

    try {
      getEmailStats(id)
        .then((res) => {
          const { error, response = {} } = res;
          if (!error || error === false) {
            setEmailStats(response);
            updateGraphValues(response);
            dispatch(setShowCommonLoader(false));
          } else {
            Sentry.captureMessage("Error", res);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          Notify({
            type: "error",
            heading: "Failed to retrieve email statistics.",
            message: "Failed to retrieve email statistics.",
          });
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      Notify({
        type: "error",
        heading: "Failed to retrieve email statistics.",
        message: "Failed to retrieve email statistics.",
      });
      dispatch(setShowCommonLoader(false));
    }
  };

  const fetchEmailSummary = () => {
    let path = location.pathname.split("/");
    let id = path[path.length - 1];
    dispatch(setShowCommonLoader(true));

    try {
      getEmailSummary(id)
        .then((res) => {
          const { error, response = {} } = res;
          if (!error || error === false) {
            setEngagementSummary(response);
            dispatch(setShowCommonLoader(false));
          } else {
            Sentry.captureMessage("Error", res);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          Notify({
            type: "error",
            heading: "Failed to retrieve email summary.",
            message: "Failed to retrieve email summary.",
          });
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      Notify({
        type: "error",
        heading: "Failed to retrieve email summary.",
        message: "Failed to retrieve email summary.",
      });
      dispatch(setShowCommonLoader(false));
    }
  };

  const fetchEmailDlrs = () => {
    let path = location.pathname.split("/");
    let id = path[path.length - 1];
    dispatch(setShowCommonLoader(true));

    try {
      getEmailDlrs(id)
        .then((res) => {
          const { error, response = {} } = res;
          if (!error || error === false) {
            setEmailDlrs(response?.data);
            setFilteredData(response?.data);
            dispatch(setShowCommonLoader(false));
          } else {
            Sentry.captureMessage("Error", res);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
          Notify({
            type: "error",
            heading: "Failed to retrieve email DLRs.",
            message: "Failed to retrieve email DLRs.",
          });
          dispatch(setShowCommonLoader(false));
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
      console.log(err);
      Notify({
        type: "error",
        heading: "Failed to retrieve email DLRs.",
        message: "Failed to retrieve email DLRs.",
      });
      dispatch(setShowCommonLoader(false));
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = currentPage * size;
    const lastPageIndex = firstPageIndex + size;
    return filteredData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, size, emailDlrs, filteredData]);

  const updateGraphValues = (analytics) => {
    let columns = ["Delivered", "Opened", "Clicks", "Bounces"];
    let seriesData = [
      {
        value: analytics?.delivery || 0,
        itemStyle: { color: "#FCB89B", borderRadius: [10, 10, 0, 0] },
      },

      {
        value: analytics?.open || 0,
        itemStyle: { color: "#FCD98D", borderRadius: [10, 10, 0, 0] },
      },
      {
        value: analytics?.clicks || 0,
        itemStyle: { color: "#A3C6FB", borderRadius: [10, 10, 0, 0] },
      },
      {
        value: analytics?.bounce || 0,
        itemStyle: { color: "#EF8B8E", borderRadius: [10, 10, 0, 0] },
      },
    ];

    const options = {
      grid: {
        height: "80%",
        bottom: "15%",
        width: "90%",
        left: "40px",
      },
      xAxis: {
        type: "category",
        data: columns,
        axisTick: false,
      },
      yAxis: {
        type: "value",
        splitNumber: 4,
      },
      series: [
        {
          data: seriesData,
          type: "bar",

          smooth: 0.6,
          backgroundStyle: {
            borderRadius: 10,
          },
        },
      ],
    };
    setOptions(options);
  };

  const filterDlrs = (type: string) => {
    if (type === "all") {
      setFilteredData(emailDlrs);
    } else if (type === "sent") {
      setFilteredData(
        emailDlrs?.filter((dlr) => dlr?.status?.toLowerCase() === "sent")
      );
    } else if (type === "delivered") {
      setFilteredData(
        emailDlrs?.filter((dlr) => dlr?.status?.toLowerCase() === "delivered")
      );
    } else if (type === "bounced") {
      setFilteredData(
        emailDlrs?.filter((dlr) => dlr?.status?.toLowerCase() === "bounced")
      );
    }
    setStatusFilter(type);
    setShowStatusMenu(false);
    setDateRange(null);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setFilteredData(
      emailDlrs?.filter((dlr) =>
        dlr.email?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);

    const startDate = sDate.getTime();
    const endDate = eDate.getTime();

    const filteredDlrs = emailDlrs?.filter((dlr) => {
      const sentTime = new Date(dlr.sentTime).getTime();
      return sentTime >= startDate && sentTime <= endDate;
    });
    setFilteredData(filteredDlrs || []);
  };

  const dateTimeFormatter = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    const options = {
      month: "short" as const,
      day: "2-digit" as const,
      year: "numeric" as const,
      hour: "numeric" as const,
      minute: "2-digit" as const,
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return "00:00";
    const date = new Date(timestamp);
    const options = {
      hour: "2-digit" as const,
      minute: "2-digit" as const,
      hour12: false,
    };
    return date.toLocaleTimeString("en-US", options);
  };

  const colorCodes = (status) => {
    switch (status?.toLowerCase()) {
      case "read":
        return (
          <div className="flex py-0.5 px-2 gap-1.5 font-semibold text-xs leading-5 bg-green-5 text-primary-green rounded-2xl items-center">
            <img src={Eye} alt="" />
            <div className="first-letter:capitalize">
              {status?.toLowerCase()}
            </div>
          </div>
        );
      case "bounce":
      case "bounced":
        return (
          <div className="flex py-0.5 px-2 gap-1.5 font-semibold text-xs leading-5 bg-error-50 text-error-700 rounded-2xl items-center">
            <img src={Bounced} alt="" />
            <div className="first-letter:capitalize">
              {status?.toLowerCase()}
            </div>
          </div>
        );
      case "sent":
        return (
          <div className="flex py-0.5 px-2 gap-1.5 font-semibold text-xs leading-5 bg-green-60 text-green-710 rounded-2xl items-center">
            <img src={OneTick} alt="" />
            <div className="first-letter:capitalize">
              {status?.toLowerCase()}
            </div>
          </div>
        );
      case "delivered":
        return (
          <div className="flex py-0.5 px-2 gap-1.5 font-semibold text-xs leading-5 bg-gray-711 text-gray-710 rounded-2xl items-center">
            <img src={Tick} alt="" />
            <div className="first-letter:capitalize">
              {status?.toLowerCase()}
            </div>
          </div>
        );
      default:
        return (
          <div className="flex py-0.5 px-2 gap-1.5 font-semibold text-xs leading-5 bg-gray-100 text-gray-700 rounded-2xl items-center">
            <img src={Tick} alt="" />
            <div className="first-letter:capitalize">
              {status?.toLowerCase()}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="mx-10">
      <div className="mt-6">
        <div className="flex justify-between">
          <div className="flex justify-center items-center ">
            <div>
              <img src={EmailInsight} alt="" />
            </div>
            <div className="flex ">
              <div className=" ml-2.5 text-xl font-semibold text-gray-700">
                {emailDetails?.name || ""}
              </div>
            </div>
            <div>
              <div
                className={`${colorCode(
                  emailDetails?.status
                )} ml-3 rounded-2xl py-0.5 px-2 text-xs lowercase first-letter:capitalize`}
              >
                {emailDetails?.status}
              </div>
            </div>
          </div>
          <div>
            <button className="inline-flex justify-center w-full rounded-md border px-5 py-3 cursor-not-allowed">
              <span className="mr-2 leading-4 text-sm font-semibold text-gray-700">
                Download report
              </span>
              <div className="py-1">
                <img src={DownArrow} alt="" />
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <div className="flex gap-9">
          <div className="w-36 text-base font-semibold text-gray-70">
            Message Details
          </div>
          <div className="grow h-1 border-t mt-2.5 border-[#EBEFF3] "></div>
        </div>
        <div className="py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Message} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Messages channel
                  </span>
                  <p className="text-gray-600 font-normal pt-1 text-sm whitespace-no-wrap lowercase first-letter:uppercase">
                    {emailDetails?.channel}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Profiles} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Objective
                  </span>
                  <p className="text-gray-600 pt-1 font-normal text-sm whitespace-no-wrap">
                    {emailDetails?.objective || "---"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Profiles} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Audience
                  </span>
                  <p className="text-gray-600 pt-1 font-normal text-sm whitespace-no-wrap">
                    {emailDetails?.audience?.segmentId ? "Segment" : "Custom"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Profiles} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Sender name
                  </span>
                  <p className="text-gray-600 font-normal pt-1 text-sm whitespace-no-wrap lowercase first-letter:uppercase">
                    {emailDetails?.senderName}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Clock} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Email subject
                  </span>
                  <p className="text-gray-600 font-normal pt-1 text-sm whitespace-no-wrap lowercase first-letter:uppercase">
                    {emailDetails?.subject}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Fisend} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Sender email
                  </span>
                  <p className="text-gray-600 font-normal pt-1 text-sm whitespace-no-wrap lowercase">
                    {emailDetails?.senderID}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Profiles} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Profiles
                  </span>
                  <p className="text-gray-600 font-normal pt-1 text-sm whitespace-no-wrap lowercase first-letter:uppercase">
                    {formatNumbers(emailDetails?.profiles)}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Cash} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Spend
                  </span>
                  <p className="text-gray-600 font-normal pt-1 text-sm whitespace-no-wrap lowercase first-letter:uppercase">
                    <CurrencySymbol value={emailDetails?.spend} />
                  </p>
                </div>
              </div>
            </div>

            <div className="flex rounded-xl">
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center flex-shrink-0 w-10 bg-gray-100 rounded-full h-10">
                  <img src={Clock} alt="" />
                </div>
                <div className="ml-3">
                  <span className="text-gray-700 font-semibold text-base leading-6 whitespace-no-wrap">
                    Message timeline
                  </span>
                  <p className="text-gray-600 font-normal pt-1 text-sm whitespace-no-wrap">
                    {emailDetails?.schedule?.immediate
                      ? dateTimeFormatter(
                          emailDetails?.schedule?.timeline?.start
                        )
                      : `${dateTimeFormatter(
                          emailDetails?.schedule?.timeline?.start
                        )} - ${dateTimeFormatter(
                          emailDetails?.schedule?.timeline?.end
                        )}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <div className="flex gap-9">
          <div className="w-36 text-base font-semibold text-gray-70">
            Statistics
          </div>
          <div className="grow h-1 border-t mt-2.5 border-[#EBEFF3] "></div>
        </div>
        <div className="pt-6 flex gap-2">
          <div className="text-lg leading-6 font-semibold text-gray-800">
            {formatNumbers(emailStats?.sent)}
          </div>
          <div className="text-[14px] leading-6 text-gray-70">
            sent messages
          </div>
        </div>
        <div className="pt-8 pb-2">
          <div className="flex w-full">
            <div className="flex-1 py-2.5 px-3.5">
              <div className="border-r border-[#98A2B3]">
                <div className="flex gap-1 items-center">
                  <div
                    style={{
                      backgroundColor: "#FCB89B",
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      marginTop: "3px",
                    }}
                  ></div>
                  <div className="text-[12px] leading-[18px] text-gray-70">
                    Delivered
                  </div>
                </div>
                <div className="text-xl leading-7  font-semibold text-gray-700 pt-1">
                  {formatNumbers(emailStats?.delivery)}
                </div>
              </div>
            </div>
            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center border-r border-[#98A2B3]">
                <div>
                  <div className="flex gap-1 items-center">
                    <div
                      style={{
                        backgroundColor: "#FCD98D",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        marginTop: "3px",
                      }}
                    ></div>
                    <div className="text-[12px] leading-[18px] text-gray-70">
                      Opened
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(emailStats?.open)}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center border-r border-[#98A2B3]">
                <div>
                  <div className="flex gap-1 items-center">
                    <div
                      style={{
                        backgroundColor: "#A3C6FB",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        marginTop: "3px",
                      }}
                    ></div>
                    <div className="text-[12px] leading-[18px] text-gray-70">
                      Clicks
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(emailStats?.clicks)}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center">
                <div>
                  <div className="flex gap-1 items-center">
                    <div
                      style={{
                        backgroundColor: "#EF8B8E",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        marginTop: "3px",
                      }}
                    ></div>
                    <div className="text-[12px] leading-[18px] text-gray-70">
                      Bounces
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(emailStats?.bounce)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {emailStats?.clicks === 0 &&
        emailStats?.delivery === 0 &&
        emailStats?.open === 0 &&
        emailStats?.bounce === 0 ? (
          <div className="pb-2">
            <ChartEmptyView
              items={["Delivered", "Opened", "Clicks", "Bounces"]}
            />
          </div>
        ) : (
          options && <ReactECharts option={options} />
        )}
      </div>

      <div className="mt-6">
        <div className="flex gap-9">
          <div className="w-52 text-base font-semibold text-gray-70">
            Engagement Summary
          </div>
          <div className="grow h-1 border-t mt-2.5 border-[#EBEFF3] "></div>
        </div>
        <div className="pt-10 pb-4">
          <div className="flex w-full">
            <div className="flex-1 py-2.5 px-3.5">
              <div className="border-r border-[#98A2B3]">
                <div className="flex gap-2 items-center text-[12px] leading-[18px] text-gray-70">
                  Delivery rate
                  <div className="relative mt-1.5 group">
                    <button
                      className="inline-flex items-center text-sm font-semibold text-center text-gray-70"
                      type="button"
                    >
                      <img src={InfoCircle} alt="" />
                    </button>

                    {/* Delivery rate tooltip */}
                    <div className="absolute hidden group-hover:flex">
                      <div className="w-[320px] p-3 z-10 ml-7 -mt-8 bg-gray-900 text-white text-sm leading-6 rounded-lg shadow-lg">
                        <p>
                          This is the percentage of sent messages that
                          successfully reached your target audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-xl leading-7  font-semibold text-gray-700 pt-1">
                  {formatNumbers(engagementSummary?.deliveryRate) || 0}%
                </div>
              </div>
            </div>
            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center border-r border-[#98A2B3]">
                <div>
                  <div className="flex gap-2 items-center text-[12px] leading-[18px] text-gray-70">
                    Open rate
                    <div className="relative mt-1.5 group">
                      <button
                        className="inline-flex items-center text-sm font-semibold text-center text-gray-70"
                        type="button"
                      >
                        <img src={InfoCircle} alt="" />
                      </button>

                      {/* Open rate tooltip */}
                      <div className="absolute hidden group-hover:flex">
                        <div className="w-[320px] p-3 z-10 ml-7 -mt-8 bg-gray-900 text-white text-sm leading-6 rounded-lg shadow-lg">
                          <p>
                            This is the percentage of recipients who opened your
                            email campaign.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(engagementSummary?.openRate) || 0}%
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center border-r border-[#98A2B3]">
                <div>
                  <div className="flex gap-2 items-center text-[12px] leading-[18px] text-gray-70">
                    Bounce rate
                    <div className="relative mt-1.5 group">
                      <button
                        className="inline-flex items-center text-sm font-semibold text-center text-gray-70"
                        type="button"
                      >
                        <img src={InfoCircle} alt="" />
                      </button>

                      {/* Bounce rate tooltip */}
                      <div className="absolute hidden group-hover:flex">
                        <div className="w-[320px] p-3 z-10 ml-7 -mt-8 bg-gray-900 text-white text-sm leading-6 rounded-lg shadow-lg">
                          <p>
                            This is the percentage of emails in your campaign
                            that didn't reach the recipient's inbox. Minimize
                            bounces by removing invalid addresses.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(engagementSummary?.bounceRate) || 0}%
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center border-r border-[#98A2B3]">
                <div>
                  <div className="flex gap-2 items-center text-[12px] leading-[18px] text-gray-70">
                    Unsubscribes
                    <div className="relative mt-1.5 group">
                      <button
                        className="inline-flex items-center text-sm font-semibold text-center text-gray-70"
                        type="button"
                      >
                        <img src={InfoCircle} alt="" />
                      </button>

                      {/* Unsubscribes tooltip */}
                      <div className="absolute hidden group-hover:flex">
                        <div className="w-[320px] p-3 z-10 ml-7 -mt-8 bg-gray-900 text-white text-sm leading-6 rounded-lg shadow-lg">
                          <p>
                            This refers to the number of recipients who have
                            opted out or chosen to stop receiving emails from a
                            particular sender or campaign.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {formatNumbers(engagementSummary?.unsubscribe)}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 py-2.5 px-3.5">
              <div className="flex justify-center">
                <div>
                  <div className="text-[12px] leading-[18px] text-gray-70">
                    Max. clicks recorded between
                  </div>
                  <div className="text-xl leading-7 font-semibold text-gray-700 pt-1">
                    {`${formatTime(
                      engagementSummary?.maxClickTimeslot?.[0]
                    )} - ${formatTime(
                      engagementSummary?.maxClickTimeslot?.[1]
                    )}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <div className="flex gap-9">
          <div className="w-36 text-base font-semibold text-gray-70">
            Email Log
          </div>
          <div className="grow h-1 border-t mt-2.5 border-[#EBEFF3] "></div>
        </div>

        <div className="pt-7 flex justify-between items-center">
          <div>
            <div className="flex border border-gray-50 rounded-lg ">
              <div className="w-10 h-10 text-gray-400">
                <Search className="text-xl m-3" size="16" />
              </div>
              <input
                type="text"
                placeholder="Search Email"
                className="w-full h-10 pt-0.5 pr-28 mr-2 focus:border-none font-normal focus:outline-none "
                onChange={(event) => handleSearch(event.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-start gap-2.5">
            <div className="relative">
              <button
                className="h-[40px] inline-flex items-center px-5 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 capitalize"
                type="button"
                onClick={() => setShowStatusMenu(!showStatusMenu)}
              >
                {statusFilter} <img src={DownArrow} alt="" />
              </button>

              {/* Status dropdown menu */}
              {showStatusMenu && (
                <>
                  <div
                    ref={statusMenu}
                    className="absolute z-10 mt-1 p-0.5 bg-white rounded-lg shadow"
                  >
                    <div>
                      <button
                        className="w-[96px] py-4 text-gray-700 hover:bg-gray-10"
                        role="menuitem"
                        onClick={() => filterDlrs("all")}
                      >
                        <div className="font-semibold">All</div>
                      </button>
                    </div>

                    <div>
                      <button
                        className="w-[96px] py-4 text-gray-700 hover:bg-gray-10"
                        role="menuitem"
                        onClick={() => filterDlrs("sent")}
                      >
                        <div className="font-semibold">Sent</div>
                      </button>
                    </div>

                    <div>
                      <button
                        className="w-[96px] py-4 text-gray-700 hover:bg-gray-10"
                        role="menuitem"
                        onClick={() => filterDlrs("delivered")}
                      >
                        <div className="font-semibold">Delivered</div>
                      </button>
                    </div>

                    <div>
                      <button
                        className="w-[96px] py-4 text-gray-700 hover:bg-gray-10"
                        role="menuitem"
                        onClick={() => filterDlrs("bounced")}
                      >
                        <div className="font-semibold">Bounced</div>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="h-[40px] w-[80px] py-3 px-5 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative">
              <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-2 justify-center pointer-events-none">
                <span className="cursor-pointer">Date</span>
                <img src={DownArrow} alt="" />
              </div>
              <div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorIcon: "#98A2B3",
                      colorBorder: "#D0D5DD",
                      colorPrimary: "#008138",
                      colorPrimaryHover: "#008138",
                      colorText: "#344054",
                      colorTextHeading: "#344054",
                      colorTextDisabled: "#D0D5DD",
                      colorTextLightSolid: "var(--main-color)",
                      colorTextPlaceholder: "#98A2B3",
                      controlItemBgActive: "#DAEEE1",
                      controlOutline: "#FFFFFF",
                      colorBgTextActive: "FFFFFF",
                      colorTextLabel: "#FFFFFF",
                    },
                  }}
                >
                  <RangePicker
                    className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                    value={dateRange}
                    onChange={onRangeSelectionChange}
                    locale={locale}
                    showNow={false}
                    allowClear={false}
                    cellRender={(current, info) => {
                      if (info.type !== "date") return info.originNode;
                      const style: React.CSSProperties = {};
                      style.borderRadius = "50%";
                      style.width = "30px";
                      style.height = "30px";
                      style.lineHeight = "30px";
                      return (
                        <div className="ant-picker-cell-inner" style={style}>
                          {current.date()}
                        </div>
                      );
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 relative">
          <div>
            <table className="min-w-full">
              <thead>
                <tr>
                  {["Email ID", "Sent time", "Delivery time", "Status"].map(
                    (item, index) => (
                      <th
                        key={index}
                        className="py-3 text-sm border-b border-gray-100 text-left text-gray-700"
                      >
                        {item}
                      </th>
                    )
                  )}
                </tr>
              </thead>

              {filteredData?.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <div className="w-full p-24">
                        <div className="flex flex-col w-full bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                          <div className="mt-2">No data available.</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {currentTableData?.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-100 text-gray-70 cursor-pointer hover:bg-gray-25"
                    >
                      <td className="py-6">
                        <div className="flex items-center gap-2">
                          <div>{item?.email}</div>
                        </div>
                      </td>
                      <td>{dateTimeFormatter(item?.sentTime) || "---"}</td>
                      <td>{dateTimeFormatter(item?.deliveredTime) || "---"}</td>
                      <td className="w-full flex items-center py-6">
                        <div>{colorCodes(item.status)}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          {filteredData.length > 0 && (
            <div className="pt-6 flex gap-5 text-sm items-center">
              <PaginationComp
                onPageChange={(from: number, size: number) => {
                  if (resetPagination) setResetPagination(false);
                  setSize(size);
                  setCurrentPage(Math.round(from / size));
                }}
                reset={resetPagination}
                totalCount={filteredData.length}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailReports;
