import { useEffect, useRef, useState } from "react";
import { usePagination } from "./usePagination";
import { Chevron, LeftArrow, RightArrow } from "../../../assets/icons/icons";

const PaginationComp = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  reset,
  //pageSize = 10,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openSizeBar, setOpenSizeBar] = useState(false);
  const sizeBar = useRef(null);

  const handleSetPageSize = (e) => {
    let newPageSize = e.target.value;
    setPageSize(Number(newPageSize));
    setCurrentPage(1);
    //onPageChange((currentPage - 1) * newPageSize, newPageSize);
    onPageChange(0, newPageSize);
    setOpenSizeBar(false);
  };

  const closeOpenMenus = (e: any) => {
    if (sizeBar.current && !sizeBar.current?.contains(e.target)) {
      setOpenSizeBar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  useEffect(() => {
    if (reset) {
      setPageSize(10);
      setCurrentPage(1);
    }
  }, [reset]);

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    let newPage = currentPage + 1;
    setCurrentPage(newPage);
    onPageChange((newPage - 1) * pageSize, pageSize);
  };

  const onPrevious = () => {
    let newPage = currentPage - 1;
    setCurrentPage(newPage);
    onPageChange((newPage - 1) * pageSize, pageSize);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="flex gap-5 items-center">
      <div className="flex gap-1 justify-center items-center">
        <div className="py-2.5 font-medium text-sm text-gray-800">
          Items per page
        </div>
        <div className="relative ">
          <button
            onClick={() => setOpenSizeBar(!openSizeBar)}
            className="flex text-sm px-3 py-3 gap-2 font-medium leading-5 justify-center items-center bg-white border border-gray-40 rounded"
          >
            {pageSize}
            <div className={openSizeBar ? "rotate-180" : "rotate-0"}>
              <img src={Chevron} alt="" />
            </div>
          </button>
          {openSizeBar && (
            <div
              ref={sizeBar}
              className="absolute bottom-10 text-gray-70 text-center mt-2 z-10 bg-white rounded shadow border border-gray-40"
            >
              <ul className="p-2 text-sm text-gray-700 z-10 shadow-xl ">
                {[5, 10, 15, 20, 25, 30, 50, 100].map((el, index) => (
                  <li
                    key={index}
                    value={el}
                    id={`${el}`}
                    onClick={handleSetPageSize}
                    className={`px-3 py-2 rounded w-full hover:bg-gray-100 text-base font-medium text-gray-700 cursor-pointer ${
                      pageSize === el && "bg-gray-100"
                    }`}
                  >
                    {el}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <ul className="flex text-sm font-semibold text-gray-700">
        {/* Left navigation arrow */}
        <button
          disabled={currentPage === 1}
          className="px-4 py-3 border border-border-gray-40 cursor-pointer rounded-l-md flex gap-3 items-center disabled:bg-white disabled:text-gray-400"
          onClick={onPrevious}
        >
          <img src={LeftArrow} alt="" />
          <div>Previous</div>
        </button>
        {paginationRange.map((pageNumber, index) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === "DOTS") {
            return (
              <li
                key={index}
                className="px-4 py-3 border border-border-gray-40 cursor-pointer"
              >
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={index}
              className={`px-4 py-3 border border-border-gray-40 cursor-pointer ${
                currentPage === pageNumber && "bg-gray-100 text-gray-800"
              }`}
              onClick={() => {
                setCurrentPage(pageNumber);
                onPageChange((pageNumber - 1) * pageSize, pageSize);
              }}
            >
              {pageNumber}
            </li>
          );
        })}
        {/*  Right Navigation arrow */}
        <button
          disabled={currentPage === Math.ceil(totalCount / pageSize)}
          className="px-4 py-3 border border-gray-40 cursor-pointer rounded-r-md flex gap-3 items-center disabled:bg-white disabled:text-gray-400"
          onClick={onNext}
        >
          <div>Next</div>
          <img src={RightArrow} alt="" />
        </button>
      </ul>
    </div>
  );
};

export default PaginationComp;
