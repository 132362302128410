import { ChevronBottom, Magnifier } from "../../../../assets/icons/icons";
import { getSurveySummary } from "../../../../common/services/survey-services";
import { useCallback, useEffect, useState } from "react";
import { ConfigProvider, DatePicker } from "antd";
const { RangePicker } = DatePicker;
import locale from "antd/es/date-picker/locale/en_GB";
import PaginationCommon from "../../../../common/components/pagination-common";
import * as Sentry from "@sentry/react";

const SurveyIndividual = (
  { id, dispatch, setShowCommonLoader },
  props: any
) => {
  const [phoneNumberId, setPhoneNumberId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);
    setStartDate(sDate.getTime().toString());
    setEndDate(eDate.getTime().toString());
  };

  useEffect(() => {
    const params = { from, size, phoneNumberId, endDate, startDate };
    getIndividualSurveyList(params);
  }, [from, size, phoneNumberId, endDate, startDate]);

  const getIndividualSurveyList = (params = {}) => {
    dispatch(setShowCommonLoader(true));

    try {
      getSurveySummary(
        "web-individual-responses",
        id.replaceAll("~", "%2F"),
        phoneNumberId,
        startDate,
        endDate,
        String(from),
        String(size)
      )
        .then((res) => {
          if (!res.error) {
            setQuestions(
              res?.response?.responseData ? res.response.responseData : []
            );
            setTotalLength(
              res?.response?.totalCount ? res.response.totalCount : 0
            );
            dispatch(setShowCommonLoader(false));
          } else {
            Sentry.captureMessage("Error", res);
            dispatch(setShowCommonLoader(false));
            console.log(res.error);
          }
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          dispatch(setShowCommonLoader(false));
          console.log(err);
        });
    } catch (error) {
      error.response
        ? Sentry.captureMessage("Error", error.response)
        : Sentry.captureMessage("Error", error);
      dispatch(setShowCommonLoader(false));
      console.log(error);
    }
  };

  const phoneNumberIdOnChange = (value) => {
    setPhoneNumberId(value);
    //getIndividualSurveyList();
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimizedFn = useCallback(debounce(phoneNumberIdOnChange), []);

  return (
    <div className="mt-6">
      <div className="flex justify-between">
        <div className="border h-[40px] w-1/4 rounded p-2 flex gap-3 items-center">
          <img src={Magnifier} alt="" />
          <input
            type="text"
            className="outline-none border-none text-[#475467] placeholder:text-[#98A2B3] text-sm"
            placeholder="Search MSISDN"
            onChange={(event) => optimizedFn(event.target.value)}
          />
        </div>
        <div className="h-[40px] w-[100px] py-3 px-5 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative">
          <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-3 justify-center pointer-events-none">
            <span className="cursor-pointer">Date</span>{" "}
            <img src={ChevronBottom} alt="" />
          </div>
          <div>
            {/* <RangePicker className="invisible" size={"middle"} /> */}
            <ConfigProvider
              theme={{
                token: {
                  colorIcon: "#98A2B3",
                  colorBorder: "#D0D5DD",
                  colorPrimary: "#008138",
                  colorPrimaryHover: "#008138",
                  colorText: "#344054",
                  colorTextHeading: "#344054",
                  colorTextDisabled: "#D0D5DD",
                  colorTextLightSolid: "var(--main-color)",
                  colorTextPlaceholder: "#98A2B3",
                  controlItemBgActive: "#DAEEE1",
                  controlOutline: "#FFFFFF",
                  colorBgTextActive: "FFFFFF",
                  colorTextLabel: "#FFFFFF",
                },
              }}
            >
              <RangePicker
                className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                onChange={onRangeSelectionChange}
                locale={locale}
                showNow={false}
                allowClear={false}
                cellRender={(current, info) => {
                  if (info.type !== "date") return info.originNode;
                  const style: React.CSSProperties = {};
                  style.borderRadius = "50%";
                  style.width = "30px";
                  style.height = "30px";
                  style.lineHeight = "30px";
                  return (
                    <div className="ant-picker-cell-inner" style={style}>
                      {current.date()}
                    </div>
                  );
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="relative">
          <div className="border-b">
            <div className="flex items-center text-gray-700 text-sm font-semibold py-4 px-2">
              <span className="w-[40%]">Date</span>
              <span className="w-[40%]">MSISDN</span>
              <span className="w-[20%]">Duration</span>
            </div>
          </div>
          {totalLength === 0 ? (
            <div className="w-full z-10 p-24 absolute">
              <div className="flex flex-col w-full bg-gray-25 text-gray-90 text-center items-center p-6 border border-gray-200 rounded-lg">
                <div className="mt-2">No Data to Show.</div>
              </div>
            </div>
          ) : (
            <>
              {questions &&
                questions.map((item, index) => {
                  return (
                    <div className="border-b" key={index}>
                      <div className="flex items-center text-[#475467] text-sm py-6 px-2">
                        <span className="w-[40%]">
                          {item.date.split("T")[0]}
                        </span>
                        <span className="w-[40%] blur-sm">**************</span>
                        <span className="w-[20%]">{item.totalDuration}</span>
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
      {pageNumber === 0 && totalLength === 0 ? null : (
        <div className="flex my-10">
          <PaginationCommon
            setPageNum={setPageNumber}
            totalListLength={totalLength}
            pageNum={pageNumber}
            setPageSize={setSize}
            setFrom={setFrom}
            pageSize={size}
            ListLength={totalLength}
          />
        </div>
      )}
    </div>
  );
};

export default SurveyIndividual;
