import { useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/en_GB";
import DownArrow from "../../common/assets/icons/DownArrow-lite";
import NotificationIcon from "../../common/assets/icons/NotificationIcon";
import WhatsappIcon from "../../common/assets/icons/WhatsappIcon";
import {
  markNotificationAsRead,
  markAllNotificationsAsRead,
} from "../../redux-loader/loader-slice/loaderSlice";
import PaginationComp from "../../../../engagements/src/common/components/pagination";

const { RangePicker } = DatePicker;

const NotificationList = (props) => {
  const {
    setStartDate,
    setEndDate,
    notificationList,
    setSize,
    size,
    setPageNumber,
    pageNumber,
    setFrom,
  } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const [dateRange, setDateRange] = useState(null);
  const [filteredData, setFilteredData] = useState(notificationList);
  const [notificationFilter, setNotificationFilter] = useState("all");
  const [showTypeMenu, setShowTypeMenu] = useState(false);
  const typeMenu = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      typeMenu.current &&
      showTypeMenu &&
      !typeMenu.current.contains(e.target)
    ) {
      setShowTypeMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const currentTableData = useMemo(() => {
    const firstPageIndex = currentPage * size;
    const lastPageIndex = firstPageIndex + size;
    return filteredData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, size, notificationList, filteredData]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dateTimeFormatter = (timestamp) => {
    const date = new Date(timestamp);
    const mediumTime = new Intl.DateTimeFormat("en", {
      timeStyle: "medium",
      dateStyle: "medium",
    });
    return mediumTime.format(date);
  };

  const onRangeSelectionChange = (event) => {
    let sDate = new Date(event[0].$d);
    let eDate = new Date(event[1].$d);
    sDate.setHours(0, 0, 0, 0);
    eDate.setHours(0, 0, 0, 0);

    const startDate = sDate.getTime();
    const endDate = eDate.getTime();

    const filteredNotifications = notificationList.filter((notification) => {
      const dateCreated = new Date(notification.dateCreated).getTime();
      return dateCreated >= startDate && dateCreated <= endDate;
    });
    setFilteredData(filteredNotifications);

    setStartDate(sDate.getTime().toString());
    setEndDate(eDate.getTime().toString());
  };

  const filterNotifications = (type: string) => {
    if (type === "all") {
      setFilteredData(notificationList);
    } else if (type === "unread") {
      setFilteredData(
        notificationList.filter(
          (notification) => notification?.isRead === false
        )
      );
    } else if (type === "read") {
      setFilteredData(
        notificationList.filter((notification) => notification?.isRead === true)
      );
    }
    setNotificationFilter(type);
    setShowTypeMenu(false);
    setDateRange(null);
  };

  const getImage = (notification) => {
    const { type } = notification;
    return type === "whatsapp" ? <WhatsappIcon /> : <NotificationIcon />;
  };

  const handleClick = (id, type = "", campaignType = "", index: Number) => {
    if (type === "whatsapp") {
      navigate(`/conversations`);
    }

    if (id) {
      if (campaignType?.toLowerCase() === "message") {
        navigate(`/engagements/${id}`);
      } else if (campaignType?.toLowerCase() === "survey") {
        navigate(`/engagements/surveys/${id}`);
      } else if (campaignType?.toLowerCase() === "reward") {
        navigate(`/engagements/rewards/${id}`);
      } else if (campaignType?.toLowerCase() === "email") {
        navigate(`/engagement-review/${id}`);
      }
    }
    dispatch(markNotificationAsRead({ index }));
  };

  const markAllRead = () => {
    dispatch(markAllNotificationsAsRead());
  };

  return (
    <>
      <div className="px-10 py-4">
        <div className="flex justify-between items-center py-2.5">
          <div className="flex justify-start gap-2.5">
            <div>
              <button
                className="relative h-[40px] inline-flex items-center px-5 py-3 text-[14px] leading-4 font-semibold text-center text-gray-70 bg-gray-10 rounded-full hover:bg-gray-100 capitalize"
                type="button"
                onClick={() => setShowTypeMenu(!showTypeMenu)}
              >
                {notificationFilter} <DownArrow />
              </button>

              {/* Type dropdown menu */}
              {showTypeMenu && (
                <>
                  <div
                    ref={typeMenu}
                    className="absolute z-10 mt-1 p-0.5 bg-white rounded-lg shadow"
                  >
                    <div className="group">
                      <button
                        className="w-[72px] py-4 text-gray-700 hover:bg-gray-10"
                        role="menuitem"
                        onClick={() => filterNotifications("all")}
                      >
                        <div className="font-semibold">All</div>
                      </button>
                    </div>

                    <div className="group">
                      <button
                        className="w-[72px] py-4 text-gray-700 hover:bg-gray-10"
                        role="menuitem"
                        onClick={() => filterNotifications("read")}
                      >
                        <div className="font-semibold">Read</div>
                      </button>
                    </div>

                    <div className="group">
                      <button
                        className="w-[72px] py-4 text-gray-700 hover:bg-gray-10"
                        role="menuitem"
                        onClick={() => filterNotifications("unread")}
                      >
                        <div className="font-semibold">Unread</div>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="h-[40px] w-[80px] py-3 px-5 bg-[#F6F9FC] text-[#475467] font-semibold flex rounded-full items-center cursor-pointer relative">
              <div className="absolute left-0 top-0 h-[40px] w-[100px] bg-[#F6F9FC] rounded-full z-10 flex items-center cursor-pointer gap-2 justify-center pointer-events-none">
                <span className="cursor-pointer">Date</span>
                <DownArrow />
              </div>
              <div>
                {/* <RangePicker className="invisible" size={"middle"} /> */}
                <ConfigProvider
                  theme={{
                    token: {
                      colorIcon: "#98A2B3",
                      colorBorder: "#D0D5DD",
                      colorPrimary: "#008138",
                      colorPrimaryHover: "#008138",
                      colorText: "#344054",
                      colorTextHeading: "#344054",
                      colorTextDisabled: "#D0D5DD",
                      colorTextLightSolid: "var(--main-color)",
                      colorTextPlaceholder: "#98A2B3",
                      controlItemBgActive: "#DAEEE1",
                      controlOutline: "#FFFFFF",
                      colorBgTextActive: "FFFFFF",
                      colorTextLabel: "#FFFFFF",
                    },
                  }}
                >
                  <RangePicker
                    className="outline-none border-none bg-[#F6F9FC] text-[#F6F9FC] shadow-none focus:{outline-none border-none bg-[#F6F9FC] shadow-none text-[#F6F9FC]} placeholder:{text-[#F6F9FC]}"
                    value={dateRange}
                    onChange={onRangeSelectionChange}
                    locale={locale}
                    showNow={false}
                    allowClear={false}
                    cellRender={(current, info) => {
                      if (info.type !== "date") return info.originNode;
                      const style: React.CSSProperties = {};
                      style.borderRadius = "50%";
                      style.width = "30px";
                      style.height = "30px";
                      style.lineHeight = "30px";
                      return (
                        <div className="ant-picker-cell-inner" style={style}>
                          {current.date()}
                        </div>
                      );
                    }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
          <div className="flex align-center">
            <button
              className="text-blue-75 hover:text-primary-green text-sm font-semibold"
              onClick={markAllRead}
            >
              Mark all as read
            </button>
          </div>
        </div>

        <div>
          <table className="min-w-full mt-2">
            <thead>
              <tr>
                {["Title", "Date ", ""].map((item, index) => (
                  <th
                    key={index}
                    className={
                      " py-3 border-b border-gray-100 text-left -text text-gray-700"
                    }
                  >
                    <div
                      className={` ${index === 0 ? "w-44" : ""} ${
                        index === 1 ? "w-28" : ""
                      }`}
                    >
                      {item}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentTableData?.map((notification, index) => {
                const {
                  title,
                  text,
                  type,
                  campaignType,
                  dateCreated,
                  id,
                  isRead,
                } = notification;
                return (
                  <tr
                    key={index}
                    className={`border-b border-gray-100 ${
                      isRead ? "text-gray-70" : "text-black"
                    } cursor-pointer`}
                    onClick={() => {
                      handleClick(id, type, campaignType, index);
                    }}
                  >
                    <td className="py-4 text-base leading-6">
                      <div className="flex gap-4 justify-start items-center">
                        <div>{getImage(notification)}</div>
                        <div className="px-2">
                          {title && (
                            <div
                              className={`mb-1.5 capitalize ${
                                isRead ? "font-medium" : "font-semibold"
                              }`}
                            >
                              {title}
                            </div>
                          )}
                          <div
                            className={`text-sm line-clamp-2 ${
                              !isRead && "font-medium"
                            }`}
                          >
                            {text}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className={`w-[20%] text-sm ${
                        !isRead && "font-semibold"
                      }`}
                    >
                      {dateTimeFormatter(dateCreated)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {pageNumber === 0 && notificationList?.length === 0 ? null : (
          <div className="pt-6 flex gap-5 text-sm items-center">
            <PaginationComp
              onPageChange={(from: number, size: number) => {
                setCurrentPage(Math.round(from / size));
              }}
              reset={false}
              totalCount={filteredData?.length}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationList;
