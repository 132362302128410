import { NotificationIcon } from "../../assets/icons/icons";

const NotificationModal = ({ data }) => {
  let type = data.type;
  if (!data.show) {
    return null;
  } else {
    return (
      <div
        className={`absolute top-[80px] right-[30px] p-5 rounded-[8px] z-20 ${
          type === "success"
            ? "border-green-500 bg-emerald-50"
            : "border-red-600 bg-red-50"
        } shadow-xl border-[1px]`}
      >
        <div className="flex jusify-between gap-5 items-start ">
          <img src={NotificationIcon} alt="" />
          <div className="max-w-[256px]">
            <span
              className={`block mb-3 ${
                type === "success" ? "text-green-800" : "text-red-900"
              } text-base font-semibold`}
            >
              {data.heading}
            </span>

            <span
              className={`${
                type === "success" ? "text-green-700" : "text-red-800"
              } `}
            >
              {data.message}
            </span>
          </div>
          <span className="font-bold cursor-pointer" onClick={data.close}>
            ✕
          </span>
        </div>
      </div>
    );
  }
};

export default NotificationModal;
