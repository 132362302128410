import { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import NotificationModal from "../../../modals/notification/NotificationModal";
import { updateReward } from "../../../common/services/reward-services";
import { useContext } from "react";
import { TenantContext } from "../rewards-parent/index";
import { isNumberValid } from "../../../common/utils/mobileNumberValidation";
import { getCurrenySymbol } from "../../../common/utils/currenySymbol";
import {
  Chevron,
  DropDownArrow,
  InfoCircle,
  PeopleGroup,
  SelectedFile,
  File,
} from "../../../assets/icons/icons";
import {
  getAudienceById,
  getSegments,
  postAudienceFromCampaign,
  uploadSegmentCSV,
} from "../../../../../marketing-cloud-main/src/common/apis/audience";
import { getAccountStat } from "../../../../../marketing-cloud-main/src/common/apis/billing";
import { Notify } from "../../../../../marketing-cloud-main/src/common/components/Notify/Notify";
import { isMobileNumberDuplicate } from "../../../common/utils/mobileNumberValidation";
import { formatNumbers } from "../../../../../marketing-cloud-main/src/common/utils/UserInterfaceUtils";
import Spinner from "../../../../../marketing-cloud-main/src/common/components/spinner/Spinner";
import { SubscriptionRedirectModal } from "../../../common/components/subscription-redirect-modal/Subscription-redirect-modal";
import * as Sentry from "@sentry/react";
import ArrowRight from "../../../../../marketing-cloud-main/src/common/assets/icons/ArrowRight";

const RewardsStepOne = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [rewardExists, setRewardExists] = useState(false);
  const [manualType, setManualType] = useState("Add numbers manually");
  const [uploadType, setUploadType] = useState(false);
  const [recurrentReward, setRecurrentReward] = useState(false);
  const [showManualOptions, setShowManualOptions] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [segmentId, setSegmentId] = useState("");
  const [segmentError, setSegmentError] = useState(false);
  const [budgetError, setBudgetError] = useState(false);
  const [fileValidation, setFileValidation] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumbersList, setMobileNumbersList] = useState([]);
  const [numberValidation, setNumberValidation] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [numberListError, setNumberListError] = useState("");
  const [manualTypeError, setManualTypeError] = useState(false);
  const [isConsentReceived, setIsConsentReceived] = useState(false);
  const [profiles, setProfiles] = useState(0);
  const [targetProfiles, setTargetProfiles] = useState(0);
  const [profilesAdded, setProfilesAdded] = useState(0);
  const [budgetEstimate, setBudgetEstimate] = useState(null);
  const [rewardPerProfile, setRewardPerProfile] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [showTooltip, setShowTooltip] = useState(null);
  const [showSegmentSelector, setShowSegmentSelector] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  const tenantContext: any = useContext(TenantContext);
  let country = props.country;
  const currency = props.currency;
  const currencySymbol = getCurrenySymbol(country, currency);
  const [lastUpdatedSegmentListLength, setLastUpdatedSegmentListLength] =
    useState(-1);
  const segmentListInnerRef = useRef(null);
  const [fileUploadSpinnerStatus, setFileUploadSpinnerStatus] = useState(false);
  const [tierId, setTierId] = useState(null);
  const [subscriptionRedirectModalStatus, setSubscriptionRedirectModalStatus] =
    useState(false);
  const [fileSizeValidator, setFileSizeValidator] = useState(false);

  useEffect(() => {
    getSegmentList(0, 20);
    getAccountStat()
      .then((response) => {
        let tierId = JSON.parse(response?.subscriptions[0])[0]?.tier_id;
        setTierId(tierId);
      })
      .catch((error) => {
        setTierId(0);
        console.log(error);
      });

    if (props.data) {
      if (props.data.id) {
        setRewardExists(true);
      }
      if (props.data.audience) {
        if (props.data.audience.phoneNumbers) {
          setMobileNumbersList(props.data.audience.phoneNumbers);
        }
        if (props.data.audience.segmentId) {
          setSegmentId(props.data.audience.segmentId);
          if (!props.data.fileName) {
            getAudienceDetails(props.data.audience.segmentId);
          }
        }
      }
      if (props.data.uploadType) {
        setUploadType(props.data.uploadType);
      }
      if (props.data.manualType) {
        setManualType(props.data.manualType);
      }
      if (props.data.selectedFile) {
        setSelectedFile(props.data.selectedFile);
      }
      if (props.data.fileName) {
        setFileName(props.data.fileName);
      }
      if (props.data.profiles) {
        setProfiles(props.data.profiles);
        setProfilesAdded(props.data.profiles);
      }
      if (props.data.targetProfiles) {
        setTargetProfiles(props.data.targetProfiles);
      }
      if (props.data.consent) {
        setIsConsentReceived(props.data.consent);
      }
      if (props.data.budgetEstimate) {
        setBudgetEstimate(props.data.budgetEstimate);
      }
    }
  }, []);

  useEffect(() => {
    if (props.data.id) {
      setRewardExists(true);
    }

    if (props.data) {
      if (props.data.id) {
        setRewardExists(true);
      }
      if (props.data.audience) {
        if (props.data.audience.phoneNumbers) {
          setMobileNumbersList(props.data.audience.phoneNumbers);
        }
        if (props.data.audience.segmentId) {
          setSegmentId(props.data.audience.segmentId);
          if (!props.data.fileName) {
            getAudienceDetails(props.data.audience.segmentId);
          }
        }
      }
      // if (props.data.segment) {
      //   onSegmentSlectionChange(props.data.segment);
      // }
      if (props.data.recurrentReward) {
        setRecurrentReward(props.data.recurrentReward);
      }
      if (props.data.uploadType) {
        setUploadType(props.data.uploadType);
      }
      if (props.data.manualType) {
        setManualType(props.data.manualType);
      }
      if (props.data.selectedFile) {
        setSelectedFile(props.data.selectedFile);
      }
      if (props.data.fileName) {
        setFileName(props.data.fileName);
      }
      if (props.data.profiles) {
        setProfiles(props.data.profiles);
        setProfilesAdded(props.data.profiles);
      }
      if (props.data.targetProfiles) {
        setTargetProfiles(props.data.targetProfiles);
      }
      if (props.data.unitPrice) {
        setUnitPrice(props.data.unitPrice);
      }
      if (props.data.rewardPerProfile) {
        setRewardPerProfile(props.data.rewardPerProfile);
      }
      if (props.data.consent) {
        setIsConsentReceived(props.data.consent);
      }
      if (props.data.budgetEstimate) {
        setBudgetEstimate(props.data.budgetEstimate);
      }
    }
  }, [props.data]);

  useEffect(() => {
    manualTypeOnChange();
  }, [manualType]);

  const manualOptions = useRef(null);
  const tooltip = useRef(null);

  const closeOpenMenus = (e) => {
    if (
      segmentListInnerRef.current &&
      showSegmentSelector &&
      !segmentListInnerRef.current.contains(e.target)
    ) {
      setShowSegmentSelector(false);
    }

    if (
      manualOptions.current &&
      showManualOptions &&
      !manualOptions.current.contains(e.target)
    ) {
      setShowManualOptions(false);
    }

    if (tooltip.current && showTooltip && !tooltip.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const getAudienceDetails = (id: string) => {
    try {
      getAudienceById(id)
        .then((response) => {
          const { error, data = [] } = response;
          if (error === false) {
            const segment = data[0];
            onSegmentSelectionChange(segment);
          } else {
            error.response
              ? Sentry.captureMessage("Error", error.response)
              : Sentry.captureMessage("Error", error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {}
  };

  const manualTypeOnChange = () => {
    if (manualType === "Upload a file") {
      if (selectedFile) {
        processCsv(selectedFile);
      }
    } else {
      const profiles = getNumberOfValidphones();
      setProfiles(profiles);
      setProfilesAdded(profiles);
    }
    setShowManualOptions(false);
  };

  const onInputChange = (event) => {
    if (event.code == "Enter") {
      if (isMobileNumberDuplicate(mobileNumber, mobileNumbersList)) {
        Notify({
          type: "error",
          heading: "Duplicate Mobile Number",
          message: `${mobileNumber} is already added. Add a different number.`,
        });
      } else {
        setNumberListError("");
        setNumberValidation(false);
        mobileNumbersList.push(mobileNumber);
        setMobileNumber("");
        let profiles = getNumberOfValidphones();
        setProfiles(profiles);
        setProfilesAdded(profiles);
        if (numberValidation) {
          if (isNumberListValid()) setNumberValidation(false);
        }
      }
    }
  };

  const onInputMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const popItemFromMobileList = (item) => {
    let list = [...mobileNumbersList];
    let index = list.lastIndexOf(item);
    if (index > -1) {
      list.splice(index, 1);
      mobileNumbersList.splice(index, 1);
    }
    setMobileNumbersList(list);
    let profiles: any = getNumberOfValidphones();
    setProfiles(profiles);
    setProfilesAdded(profiles);
  };

  const isPhoneNumberValid = (number: any) => {
    if (
      number[0] === "0" ||
      !isNumberValid(tenantContext.country, number.length)
    ) {
      return false;
    }
    return true;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const dm = decimals < 0 ? 0 : decimals;

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const processCsv = (file) => {
    setProfiles(file.count);
    setProfilesAdded(file.count);
  };

  const onFileSelected = (event) => {
    setFileSizeValidator(false);
    if (event.target.files && event.target.files[0]) {
      if (
        parseFloat(
          (event.target.files[0].size / Math.pow(1000, 2)).toFixed(2)
        ) > 50
      ) {
        setFileSizeValidator(true);
        return;
      }
      setFileName("Uploading...");
      setFileUploadSpinnerStatus(true);
      const file = event.target.files[0];
      setFileValidation(false);

      try {
        uploadSegmentCSV(file)
          .then((res) => {
            const { data, response = {} } = res;
            if (data && !data?.error) {
              const {
                total_profiles,
                duplicate,
                uploaded_profiles,
                invalid_profiles,
              } = data.data;
              const breakdownMessage = `Uploaded: ${uploaded_profiles}, Duplicate: ${duplicate}, Invalid: ${invalid_profiles}, Total: ${total_profiles}`;
              postAudienceFromCampaign({
                segment: data.data.segment,
                name: file.name.split(".")[0],
                size: data.data.uploaded_profiles,
                description: "Campaign",
              })
                .then((audience: any) => {
                  setFileUploadSpinnerStatus(false);
                  setFileName(file.name);
                  setSegmentId(audience.data.audience_id);
                  let f = {
                    name: file.name,
                    size: file.size,
                    count: uploaded_profiles,
                  };
                  setSelectedFile(f);
                  processCsv(f);
                  Notify({
                    type: "success",
                    heading: "Import successful",
                    message: `${breakdownMessage}`,
                  });
                })
                .catch((err) => {
                  err.response
                    ? Sentry.captureMessage("Error", err.response)
                    : Sentry.captureMessage("Error", err);
                  setFileUploadSpinnerStatus(false);
                  unselectFile();
                  console.log(err);
                });
            } else {
              Sentry.captureMessage("Error", res);
              setFileUploadSpinnerStatus(false);
              unselectFile();
              handleNotificationData({
                show: true,
                type: "error",
                heading: "Failed to upload file.",
                message: res?.message,
              });
            }
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            setFileUploadSpinnerStatus(false);
            unselectFile();
            console.log("File upload error", err);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        setFileUploadSpinnerStatus(false);
        unselectFile();
        console.log("File upload error", err);
      }
    } else {
      setFileUploadSpinnerStatus(false);
      unselectFile();
    }
  };

  const unselectFile = () => {
    setFileName("");
    setProfiles(0);
    setProfilesAdded(0);
    setSelectedFile(null);
  };

  const resetAudience = () => {
    setProfiles(0);
    setProfilesAdded(0);
    setMobileNumbersList([]);
    setMobileNumber("");
    setNumberValidation(false);
    setNumberListError("");
    setSelectedSegment(null);
    setSegmentError(false);
    setBudgetError(false);
    setManualTypeError(false);
    setCheckValidation(false);
    setSelectedFile(null);
    setFileValidation(false);
    setFileName("");
    setManualType("Add numbers manually");
  };

  const isNumberListValid = () => {
    let status = true;
    mobileNumbersList.map((element, i) => {
      if (!isPhoneNumberValid(element)) {
        status = false;
      }
    });
    return status;
  };

  const getNumberOfValidphones = () => {
    let count = 0;
    mobileNumbersList.map((element, i) => {
      if (isPhoneNumberValid(element)) {
        count++;
      }
    });
    return count;
  };

  const consentRecievedOnChange = (event) => {
    if (event.target.checked) setCheckValidation(false);
    setIsConsentReceived(event.target.checked);
  };

  const handleNotificationClose = () => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      show: false,
    }));
  };

  const [notificationData, setNotificationData] = useState({
    show: false,
    close: handleNotificationClose,
    type: "",
    message: "",
    heading: "",
  });

  const handleNotificationData = (data: any) => {
    setNotificationData((notificationData) => ({
      ...notificationData,
      ...data,
    }));
    setTimeout(() => {
      handleNotificationClose();
    }, 4000);
  };

  const getRewardData = () => {
    let audience: any;
    if (uploadType) {
      if (manualType == "Upload a file") {
        audience = {
          phoneNumbers: [],
          segmentId: segmentId,
        };
      } else {
        audience = {
          phoneNumbers: mobileNumbersList,
          segmentId: "",
        };
      }
    } else {
      audience = {
        segmentId: selectedSegment?.audience_id,
        phoneNumbers: [],
      };
    }
    const data = {
      segment: selectedSegment,
      recurrentReward,
      profiles: Number(targetProfiles || profilesAdded),
      budget: Number(targetProfiles || profilesAdded),
      targetProfiles: Number(targetProfiles),
      consent: isConsentReceived,
      uploadType,
      manualType,
      selectedFile,
      fileName,
      audience,
      budgetEstimate,
    };

    const campaignData = {
      ...props.data,
      ...data,
    };

    return campaignData;
  };

  const validateForm = () => {
    if (uploadType) {
      if (manualType === "") {
        setManualTypeError(true);
        return;
      }

      if (manualType === "Upload a file") {
        if (!selectedFile) {
          setFileValidation(true);
          return;
        }
      } else {
        if (!isNumberListValid()) {
          setNumberValidation(true);
          setNumberListError(
            "One or more of the inputted number is in a wrong format. Please ensure the phone number doesn’t include 0 or country code eg. 8066603063."
          );
        }

        if (mobileNumbersList.length === 0) {
          setNumberValidation(true);
          setNumberListError("Phone number list cannot be empty");
        }

        if (!isConsentReceived) {
          setCheckValidation(true);
        }

        if (
          !isNumberListValid() ||
          mobileNumbersList.length === 0 ||
          !isConsentReceived
        ) {
          return false;
        }
      }
    } else {
      if (targetProfiles <= 0 || targetProfiles > profilesAdded) {
        return;
      }
      if (!selectedSegment) {
        setSegmentError(true);
        return;
      }
      // if (!budgetEstimate) {
      //   setBudgetError(true);
      //   return;
      // }
    }

    return true;
  };

  const saveDraft = () => {
    handleNotificationData({
      show: true,
      type: "success",
      heading: "Saved successfully.",
      message: "Reward details saved as draft.",
    });

    setTimeout(() => {
      props.navigate("/engagements/rewards");
    }, 4000);
  };

  const handleSubmit = (event, saveAsDraft) => {
    setShowErrors(true);
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const rewardData = getRewardData();

    if (!rewardExists) {
      props.setData(rewardData);
      props.setStep(2);
    } else {
      try {
        setLoading(true);
        updateReward(rewardData, props.data?.id?.replaceAll("/", "%2F"))
          .then((res) => {
            const { error, response = {} } = res;
            if (error === false) {
              if (saveAsDraft) {
                saveDraft();
              } else {
                props.setData(rewardData);
                props.setStep(2);
              }
            } else {
              error.response
                ? Sentry.captureMessage("Error", error.response)
                : Sentry.captureMessage("Error", error);
              handleNotificationData({
                show: true,
                type: "error",
                heading: "Failed to save reward details.",
                message: error,
              });
            }

            setLoading(false);
          })
          .catch((err) => {
            err.response
              ? Sentry.captureMessage("Error", err.response)
              : Sentry.captureMessage("Error", err);
            setLoading(false);
            console.error(err);
          });
      } catch (err) {
        err.response
          ? Sentry.captureMessage("Error", err.response)
          : Sentry.captureMessage("Error", err);
        console.error(err);
        return;
      }
    }
  };

  const segmentSelectorOnChange = () => {
    let segmentSelection = showSegmentSelector;
    setShowSegmentSelector(!segmentSelection);
  };

  const onSegmentSelectionChange = (segment) => {
    setSegmentError(false);
    setSelectedSegment(segment);
    setProfiles(segment.audience_size);
    setProfilesAdded(segment.audience_size);
    setShowSegmentSelector(false);
  };

  const budgetOnChange = (event) => {
    if (event.target.value === "") {
      setBudgetEstimate(0);
      setTargetProfiles(0);
    } else {
      let value =
        event.target.value[0] == 0
          ? event.target.value.substring(1)
          : event.target.value;
      setBudgetEstimate(Number(value));
      setTargetProfiles(Math.floor(value / rewardPerProfile));
      setBudgetError(false);
    }
  };

  const targetProfileOnChange = (event) => {
    if (event.target.value === "") {
      setTargetProfiles(0);
      setBudgetEstimate(0);
    } else {
      let value =
        event.target.value[0] == 0
          ? event.target.value.substring(1)
          : event.target.value;
      setTargetProfiles(value);
      setBudgetEstimate(value);
      setBudgetError(false);
    }
  };

  const getAudienceBudget = () => {
    if (!uploadType) {
      return formatNumbers(Math.floor(targetProfiles) || profilesAdded);
    } else {
      return profilesAdded;
    }
  };

  const refreshBudget = () => {
    setBudgetEstimate(0);
    setTargetProfiles(0);
    setBudgetError(false);
  };

  const getSegmentList = (from, pageSize) => {
    try {
      getSegments({ from, pageSize })
        .then((response) => {
          let list: any[] = [];
          response.data.map((element) => {
            if (element.audience_id) {
              list.push(element);
            }
          });
          //if (lastUpdatedSegmentListLength === -1) setSelectedSegment(list[0]);
          let previousList = [...segmentList];
          let newList = previousList.concat(list);
          setSegmentList(newList);
          setLastUpdatedSegmentListLength(list.length);
        })
        .catch((err) => {
          err.response
            ? Sentry.captureMessage("Error", err.response)
            : Sentry.captureMessage("Error", err);
          console.log(err);
        });
    } catch (err) {
      err.response
        ? Sentry.captureMessage("Error", err.response)
        : Sentry.captureMessage("Error", err);
    }
  };

  const onSegmentListScroll = () => {
    if (segmentListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        segmentListInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (lastUpdatedSegmentListLength !== 0) {
          getSegmentList(lastUpdatedSegmentListLength + 1, 20);
        }
      }
    }
  };

  const handleSubscriptionModalClick = (status) => {
    if (status) props.navigate("/pricing");
    else setSubscriptionRedirectModalStatus(false);
  };

  return (
    <div
      className="py-12 px-12"
      onClick={() => {
        if (showSegmentSelector) {
          setShowSegmentSelector(false);
        }
      }}
    >
      <div className="text-gray-500 font-semibold text-sm">STEP 1 OF 3</div>
      <div className="flex justify-between items-center w-full">
        <div className="flex-wrap justify-start items-start">
          <div className="flex rounded-xl py-4">
            <div className="flex gap-6">
              <div className="flex justify-center items-center flex-shrink-0">
                <img src={PeopleGroup} alt="" />
              </div>
              <div className="text-left py-2">
                <div className="text-gray-70 font-semibold text-base">
                  Recipients
                </div>
                <div className="text-gray-500 mt-1 font-normal text-sm whitespace-no-wrap">
                  Who are you targeting?
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ml-auto">
          <div className="flex gap-4">
            <button
              className="py-3 px-5 w-[88px] text-gray-90 text-sm font-semibold border rounded-[6px] bg-white border-gray-50 hover:border-gray-500"
              onClick={() => props.navigate("/engagements/rewards")}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              onClick={(event) => {
                handleSubmit(event, false);
              }}
              disabled={loading}
              className="flex items-center justify-center gap-2 py-3 px-5 w-[99px] text-white text-sm font-semibold border rounded-[6px] bg-blue-75 hover:bg-green-75 disabled:bg-gray-400 disabled:text-slate-500 disabled:border-slate-200"
            >
              <div>Next</div>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-between">
        <div className="w-[60%]">
          <div className="flex justify-between gap-4">
            <div
              className={`mt-3 border flex rounded-md py-3 px-5 justify-between w-1/2 cursor-pointer hover:border-gray-300 ${
                !uploadType && "border-gray-700"
              }`}
              onClick={() => {
                setUploadType(false);
                resetAudience();
              }}
            >
              <div>Add from Audience</div>
              <input
                className="accent-gray-700 cursor-pointer"
                type="radio"
                checked={!uploadType}
                onChange={() => {
                  setUploadType(false);
                  resetAudience();
                }}
              />
            </div>
            <div
              className={`mt-3 border flex rounded-md py-3 px-5 justify-between w-1/2 cursor-pointer hover:border-gray-300 ${
                uploadType && "border-gray-700"
              }`}
              onClick={() => {
                setUploadType(true);
                resetAudience();
              }}
            >
              <div>Manual upload</div>
              <input
                className="accent-gray-700 cursor-pointer"
                type="radio"
                checked={uploadType}
                onChange={() => {
                  setUploadType(true);
                  resetAudience();
                }}
              />
            </div>
          </div>

          {!uploadType && (
            <>
              <div className="mt-8">
                <div className="mt-6">
                  <span className="text-sm text-gray-70 font-semibold">
                    Audience
                  </span>
                  <div className="relative mt-1">
                    <div
                      className={`border rounded h-[40px] px-3 py-2 cursor-pointer hover:border-gray-300 ${
                        segmentError && "border-red-500"
                      }`}
                      onClick={() => {
                        segmentSelectorOnChange();
                      }}
                    >
                      <div className="flex justify-between items-center mt-0.5">
                        <span>
                          {segmentList.length
                            ? selectedSegment?.audience_name ||
                              "Select Audience"
                            : "No Audience available!"}
                        </span>
                        <img src={DropDownArrow} alt="" />
                      </div>
                    </div>
                    {showSegmentSelector && (
                      <div
                        className="absolute w-full bg-white top-12 drop-shadow-md rounded max-h-[160px] overflow-auto"
                        onScroll={onSegmentListScroll}
                        ref={segmentListInnerRef}
                      >
                        {segmentList.map((ele, i) => {
                          return (
                            <div
                              className="py-3 px-4 cursor-pointer flex justify-between items-center hover:bg-[#F9FAFB]"
                              key={i}
                              onClick={() => {
                                tierId === 0 &&
                                ele.audience_source === "terragon"
                                  ? setSubscriptionRedirectModalStatus(true)
                                  : onSegmentSelectionChange(ele);
                              }}
                            >
                              <div className="flex gap-2 items-center">
                                <span className="text-sm text-gray-700">
                                  {ele.audience_name}
                                </span>
                                {tierId === 0 &&
                                  ele.audience_source === "terragon" && (
                                    <span className="bg-blue-50 text-blue-800 font-semibold text-xs rounded-full flex items-center px-3 py-1">
                                      Upgrade plan to use
                                    </span>
                                  )}
                              </div>
                              <span className="text-sm text-gray-400">
                                {ele.audience_size ? ele.audience_size : 0}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-10">
                  <div className="text-gray-70 font-semibold text-sm mb-1.5">
                    Set your budget
                  </div>
                  <div className="text-gray-500">
                    Enter the number of profiles you want to reach.
                  </div>
                  {showErrors && (
                    <div className="text-red-500 text-sm">
                      {targetProfiles <= 0
                        ? "Please provide a valid number of profiles"
                        : targetProfiles > profilesAdded
                        ? "Please set a budget less than or equal to number of profiles added"
                        : null}
                    </div>
                  )}
                  <div className="mt-5 flex justify-between gap-4 items-center">
                    <div className="w-full">
                      <div className="flex justify-between group">
                        <div className="w-full border group-hover:border-gray-300 h-[40px] flex border-t-1 border-l-1 p-1 rounded-l-lg">
                          <input
                            className="w-full pr-3 outline-0 border-0 pl-3"
                            value={targetProfiles || ""}
                            onChange={(event) => targetProfileOnChange(event)}
                            type="number"
                            placeholder="Enter number"
                          />
                        </div>
                        <div className="border border-l-0 group-hover:border-gray-300 py-0 px-4 flex justify-center items-center rounded-r-lg">
                          Profiles
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {uploadType && (
            <>
              <div className="mt-8">
                <div className="text-gray-70 font-semibold text-sm mb-1.5">
                  Manual upload types
                </div>
                <div className="relative w-full">
                  <button
                    name="manualType"
                    className={`flex w-full justify-between items-center px-4 py-3 text-center h-12 mt-1 border hover:border-gray-300 rounded-md focus:outline-none ${
                      manualType === "" && "text-gray-400"
                    } ${manualTypeError && "border-red-500"}`}
                    type="button"
                    onClick={() => {
                      setShowManualOptions(!showManualOptions);
                      setManualTypeError(false);
                    }}
                    disabled={loading}
                  >
                    {manualType || "Select your manual upload option"}
                    <img src={Chevron} alt="" />
                  </button>

                  {showManualOptions && (
                    <div
                      ref={manualOptions}
                      className="absolute z-10 bg-white rounded-lg shadow w-full"
                    >
                      {["Add numbers manually", "Upload a file"].map(
                        (option, index) => (
                          <button
                            key={index}
                            className="block p-4 text-gray-700 hover:bg-gray-10 w-full text-left"
                            role="menuitem"
                            onClick={() => {
                              setManualType(option);
                            }}
                          >
                            {option}
                          </button>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <div>
                  {manualType == "Add numbers manually" && (
                    <>
                      <div>
                        <div>
                          Use ‘Enter’ to separate phone numbers. Do not add
                          country code or ‘0’ before the number.
                        </div>
                        <div>
                          <div
                            className={
                              numberValidation
                                ? "border border-red-500 hover:border-gray-300 rounded-lg text-gray-70"
                                : "border hover:border-gray-300 rounded-lg text-gray-70"
                            }
                            style={{
                              marginTop: "8px",
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div style={{ float: "left" }}>
                              {mobileNumbersList.map((element, index) => {
                                return (
                                  <span
                                    className={`border inline-block rounded-lg py-2 px-3 text-gray-700 text-sm mt-2 ml-2 ${
                                      isPhoneNumberValid(element)
                                        ? "border-gray-50"
                                        : "border-red-500"
                                    }`}
                                    key={index}
                                  >
                                    {element}
                                    <X
                                      className="text-gray-600 inline-block ml-2 -mt-1 cursor-pointer rounded-full hover:bg-gray-30 hover:p-0.5"
                                      size={14}
                                      onClick={() =>
                                        popItemFromMobileList(element)
                                      }
                                    />
                                  </span>
                                );
                              })}
                            </div>
                            <div>
                              <input
                                placeholder="Enter your number here."
                                value={mobileNumber}
                                onChange={($event) =>
                                  onInputMobileNumberChange($event)
                                }
                                className="h-[30px] outline-0 border-0 m-2"
                                style={{ width: "-webkit-fill-available" }}
                                type="number"
                                onKeyDown={($event) => onInputChange($event)}
                              />
                            </div>
                          </div>
                          {numberValidation ? (
                            <div className="text-red-500 text-sm mt-2">
                              {numberListError}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="flex items-center py-3 mt-3">
                        <input
                          id="consent"
                          type="checkbox"
                          checked={isConsentReceived}
                          className={`w-4 h-4 rounded cursor-pointer accent-blue-75`}
                          onChange={consentRecievedOnChange}
                        />
                        <label
                          htmlFor="consent"
                          className={`ml-2 text-gray-700 cursor-pointer`}
                        >
                          Numbers uploaded have given consent to receive
                          promotional messages from this business.
                        </label>
                      </div>
                      {checkValidation ? (
                        <div className="text-sm text-[#EF4444]">
                          This field must be checked to continue.
                        </div>
                      ) : null}
                    </>
                  )}

                  {manualType == "Upload a file" && (
                    <div>
                      <div className="mt-1">
                        For a lot of profiles. Upload a file with their numbers.{" "}
                        <span>
                          <a
                            href="https://mcassets.blob.core.windows.net/cdn/AudienceAssets/Audience_Sample_Template.csv"
                            download="Sample_Template.csv"
                            className="text-blue-75 font-semibold cursor-pointer"
                          >
                            Download sample file
                          </a>
                        </span>
                      </div>

                      <div className="relative">
                        {fileUploadSpinnerStatus && (
                          <div className="absolute top-0 left-0 z-10 flex justify-center h-full w-full bg-[#ffffffb8] border rounded">
                            <Spinner className="w-8 h-8" />
                          </div>
                        )}
                        {fileName ? (
                          <div
                            className={`mt-3 py-3 bg-gray-25 border border-dashed rounded-lg cursor-pointer relative ${
                              fileValidation
                                ? "border-red-500"
                                : "hover:border-gray-300"
                            }`}
                          >
                            <div className="flex justify-between items-center px-4">
                              <div className="flex gap-4 items-center px-2">
                                <img src={SelectedFile} alt="" />
                                <div className="text-gray-70">
                                  <div className="text-base font-semibold">
                                    {selectedFile?.name}
                                  </div>
                                  <div>{formatBytes(selectedFile?.size)}</div>
                                </div>
                              </div>
                              <X
                                className="text-gray-600 cursor-pointer"
                                size="16"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  unselectFile();
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`mt-3 py-6 bg-gray-25 flex items-center justify-center border border-dashed hover:border-gray-300 rounded-lg cursor-pointer relative ${
                              fileValidation && "border-red-500"
                            }`}
                          >
                            <div className="flex flex-col text-center items-center justify-center">
                              <img src={File} alt="" />
                              <div className="my-1">
                                <span className="text-blue-75 font-semibold cursor-pointer">
                                  Choose a file to upload
                                </span>
                              </div>
                              <div className="text-gray-500 text-sm">
                                or drag and drop it here
                              </div>
                              <p
                                className={`${
                                  fileSizeValidator
                                    ? "text-red-500"
                                    : "text-gray-500"
                                } text-xs mt-1`}
                              >
                                {fileSizeValidator
                                  ? "File exceeds the 50 MB upload limit. Please upload a smaller CSV or XLS file."
                                  : "Only CSV, XLS supported. Max file size: 5x0 MB"}
                              </p>
                            </div>
                            <input
                              className="absolute w-full h-full cursor-pointer opacity-0"
                              type="file"
                              accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              id="importFile"
                              onChange={onFileSelected}
                              required
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-[39%]">
          <div className="mt-3 ml-6 h-144 bg-gray-80 border border-gray-30 rounded-lg">
            <div className="pt-5 pb-3 px-6 text-gray-700 text-lg font-semibold">
              Audience
            </div>
            <hr className="mx-5 border-gray-50" />

            <div className="p-6">
              <div className="text-gray-700 text-xl font-semibold flex gap-1">
                {formatNumbers(profilesAdded)}
                <div className="text-sm text-gray-500 mt-1 ml-1 font-normal">
                  {profilesAdded > 1 ? "profiles added" : "profile added"}
                </div>
              </div>
              <>
                {uploadType ? (
                  <div className="text-gray-500 mt-4">
                    Based on the numbers inputted, this is your potential target
                    audience.
                  </div>
                ) : (
                  <div className="text-gray-500 mt-4">
                    Based on your audience selection, this is your potential
                    target audience.
                  </div>
                )}
                <div className="flex items-center justify-between mt-4">
                  <div className="flex gap-2">
                    <div className="text-gray-70 mt-6 font-semibold">
                      Audience budget
                    </div>
                    <div className="relative mt-4 group">
                      <button
                        className="inline-flex items-center py-[10px] text-sm font-semibold text-center text-gray-70"
                        type="button"
                      >
                        <img src={InfoCircle} alt="" />
                      </button>

                      {/* Target audience tooltip */}
                      <div
                        ref={tooltip}
                        className="absolute hidden group-hover:flex"
                      >
                        <div className="p-3 z-10 ml-7 -mt-8 bg-[#E6F0FA] text-[#143D7B] border border-[#B5D5F4] text-sm leading-6 rounded-lg shadow-lg w-[186px]">
                          <p>
                            This is the number of profiles you can target with
                            your budget amount.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-gray-700 text-xl font-semibold flex gap-1 mt-4">
                    {getAudienceBudget()}
                    <div className="text-base text-gray-500 mt-1 ml-1 font-normal">
                      {getAudienceBudget() > 1 ? "profiles" : "profile"}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      <NotificationModal data={notificationData} />
      {subscriptionRedirectModalStatus && (
        <SubscriptionRedirectModal
          handleClick={(status) => handleSubscriptionModalClick(status)}
        />
      )}
    </div>
  );
};

export default RewardsStepOne;
